import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ExpenseForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { selectExpenseById, expenseUpdated } from 'features/expenses/expensesSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import parseDate from 'utils/parseDate';

export default function EditExpenseForm(props){

    const contentDiv = useContext(ContentContext);

    console.log("Expense EDIT CONTEXT", contentDiv);

    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    console.log("PARAMS", params);
    const id = params.id

    const expense = useSelector((state) => selectExpenseById(state, id));
    console.log("Expense SELECTOR: ", expense);
    const updateStatus = useSelector((state) => state.expenses.editing.status);
    const updateError = useSelector((state) => state.expenses.editing.error);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    console.log("Expense EditForm Render");

    const updateExpense = c => {
        const payload = { id: c.id, expense: c }
        setIsSaving(true);
        dispatch(expenseUpdated(payload)).then(result => {
            if(result.type === "expenses/expenseUpdated/fulfilled"){
                
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../../");
            }
            if(result.type === "expenses/expenseUpdated/rejected"){
                ScrollToContentTop(contentDiv);
                setIsSaving(false);
            }
        });    
    }

    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>
               
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { updateError && <Alert severity="error">{updateError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {updateStatus}</Alert>
                </>
            }   
            { expense === undefined && <Alert severity="error">Expense with given ID does not exist.</Alert> }
            { expense &&
            <>
            <Alert variant="outlined" severity="success">DATE : { parseDate(expense.created_at) }</Alert>
            <Card sx={{mt:2}}>
                <CardContent>
                    <ExpenseForm 
                        editMode={true} 
                        model={expense}
                        onAction={updateExpense}
                    />                   
                </CardContent>
            </Card>

         
            </>
            }

        </Box>
        </DashboardContainer>
        </>
    )

}

