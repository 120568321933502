import * as React from 'react';

import {
  TextField,
  InputAdornment,
  SvgIcon

} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';

const SearchField = (props) => {

    const { onChange } = props;

    return (
        <TextField
            fullWidth
            onChange={(event)=> onChange(event.target.value)}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <SvgIcon
                    fontSize="small"
                    color="action"
                >
                    <SearchIcon />
                </SvgIcon>
                </InputAdornment>
            )
            }}
            placeholder="Search"
            variant="outlined"
        />
    )
}

export default SearchField;
