import Settings from './Settings';
import AuthRole from 'layouts/Dashboard/AuthRole';

const routes = [

  { path: 'settings', element: <AuthRole role="administrator" isPage><Settings /></AuthRole>},


]

  export default routes;