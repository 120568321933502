import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

export const handleEditItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  navigate("/dashboard/users/edit/" + item.id)
}

export const UserActions = (dispatch, navigate, enqueueSnackbar) => [
  {
  id:1,
  name: "Edit",
  icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
  callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
  show: true

  }
  ];