
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {IconButton, Menu, MenuItem, Typography,Box, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router';
import { selectUser }  from 'features/users/usersSlice';
import { Logout } from 'views/Dashboard/Users/components/Logout';
import InputIcon from '@mui/icons-material/Input';

const DashboardNavbarAccountMenu = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleShowProfile = () => {
        setAnchorEl(null);
        navigate("/dashboard/account/");
    }
    const handleLogout = () => { 

        console.log("Logging out...");
        
        Logout(dispatch);
    }
    return(
        <React.Fragment>
            <IconButton color="inherit" size="large" onClick={handleClick}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="user-profile"
                aria-labelledby="user-profile"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
            <MenuItem onClick={handleShowProfile}>
                <Box sx={{ 
                    display:'flex',
                    justifyContent:'space-between',
                    width:'200px'
                    }}
                    >
                    <Typography variant="p">{ user.name }'s profile</Typography>
                    
                </Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
               
                <IconButton color="inherit" size="small" onClick={handleLogout} title="Logout">
                    <InputIcon />
                    <Typography variant="pred" sx={{ml:1}}>Logout</Typography>
                </IconButton>

            </MenuItem>
            
            </Menu>
        </React.Fragment> 
    )


}

export default DashboardNavbarAccountMenu;