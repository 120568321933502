import Customers from './Customers';
import EditCustomerForm from './components/EditCustomerForm';
//import {CreateCustomerForm, EditCustomerForm} from './components';
import AuthRole from 'layouts/Dashboard/AuthRole';

const routes = [

  { path: 'customers', element: <Customers />},
  //{ path: 'customers/create', element: <CreateCustomerForm />},
  { path: 'customers/edit/:id', element: <AuthRole role="moderator" isPage><EditCustomerForm /></AuthRole>}

]

  export default routes;