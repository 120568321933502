import * as React from 'react';
import {Alert, Box,Card,CardContent, LinearProgress} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import SalesFilters from './SalesFilters';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import currency from 'utils/currency'
import parseDate from 'utils/parseDate';
import { hidden_md, hidden_xs } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { CustomerDetailsTable, AddressDetailsTable, ProductExtrasDetailsTable } from 'views/Dashboard/shared';
import { Table, TableBody, TableCell,TableRow, Typography, Button } from '@mui/material';
import { getActionByName } from 'views/Dashboard/shared/EntitiesTable/utils';
import { PaidStatus, parseProducts, parseExtras } from '../utils'
import { SaleActions } from './actions';
import { ProductsDetailsTable } from 'views/Dashboard/shared';
import DeliveryDatePicker from './DeliveryDatePicker';
import Modal from 'components/Modal';
import ExportToFile from 'views/Dashboard/shared/ExportToFile';
import DownloadCombinedPdf from 'views/Dashboard/shared/DownloadCombinedPdf';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/History';
import OrderMailForm from './OrderMailForm';
import SentEmailsDetailsTable from 'views/Dashboard/shared/SentEmailsDetailsTable';
import parseDateAndTime from 'utils/parseDateAndTime';

const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new sale' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
    {
      id: 'id',
      disablePadding: true,
      label: 'ID',
      hidden : hidden_xs,
      show: true
    },
    {
      id: 'invoice_no',
      disablePadding: false,
      label: 'Inv',
      show: true
    },
    {
      id: 'products',
      disablePadding: false,
      label: 'M&M',
      hidden : hidden_md,
      parseFunction: parseProducts,
      show: true

    },
    {
      id: 'created_at',
      disablePadding: false,
      label: 'Date',
      parseFunction: parseDateAndTime,
      show: true
    },
    {
      id: 'total',

      disablePadding: false,
      label: 'Total',
      hidden : hidden_xs,
      parseFunction: currency.format,
      show: true
    },
    
    {
      id: 'deposit',

      disablePadding: false,
      label: 'Deposit',
      hidden : hidden_md,
      parseFunction: currency.format,
      show: true

    },
    {
      id: 'deposit_paid',

      disablePadding: false,
      label: 'Deposit',
      hidden : hidden_md,
      parseFunction: PaidStatus,
      show: false

    },
    {
      id: 'balance_paid',

      disablePadding: false,
      label: 'Balance',
      hidden : hidden_md,
      parseFunction: PaidStatus,
      show: false

    },
    {
      id: 'extras',

      disablePadding: false,
      label: 'Add-ons',
      hidden : hidden_md,
      parseFunction: parseExtras,
      show: true
    },
];

const rowStyles = [
  { property: "backgroundColor", value:"#FCF1EF", column: "balance_paid", condition: (value) => value !== true },

]

const SaleDrawer = (props) => {

  const { row, actions } = props;
  const [showMail, setShowMail] = React.useState(false);
  const [showMailHistory, setShowMailHistory] = React.useState(false);

  const onPaid = (event, item, type) => {

        const action = getActionByName(actions, "Paid");
        console.log("RETURNED ACTION: ", action);
        if(action){
            action.callback(event,item,type);
        } else {
            console.log("Action not found!");
        }
        

  }
  const onDelivery = (event, item, delDate) => {

    const action = getActionByName(actions, "Delivery");
    console.log("RETURNED ACTION: ", action);
    if(action){
        action.callback(event,item,delDate);
    } else {
        console.log("Action not found!");
    }
    
  }
  const onDelivered = (event, item, delDate) => {

    const action = getActionByName(actions, "Delivered");
    console.log("RETURNED ACTION: ", action);
    if(action){
        action.callback(event,item,delDate);
    } else {
        console.log("Action not found!");
    }
    

  }
  const onNotified = (event, item) => {

    const action = getActionByName(actions, "Notified");
    console.log("RETURNED ACTION: ", action);
    if(action){
        action.callback(event,item);
    } else {
        console.log("Action not found!");
    }
    

  }
  return (
    <>
    <Box sx={{ margin: 1,display:'flex',columnGap:2,rowGap:2, flexWrap:"wrap"}}>
    <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"200px",width:{ "xs": "100%"}, backgroundColor:"#fafafa"}}>
    
    <Table size="small" aria-label="stockins" sx={{ minWidth:"250px"}}>
        <TableBody>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">INVOICE NO</Typography></TableCell>
                <TableCell>{row.invoice_no}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DATE</Typography></TableCell>
                <TableCell>{parseDate(row.created_at)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">SOLD FOR</Typography></TableCell>
                <TableCell>{currency.format(row.total)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DEPOSIT PAYMENT</Typography></TableCell>
                <TableCell>
                {row.deposit_paid ? <Typography variant="pgreen">PAID</Typography> 
                                    : (<><Typography variant="pred">NOT PAID</Typography> <Button variant="outlined" size="small" onClick={(event) => onPaid(event, row,"depositPaid")}>Paid</Button></>)
                }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">BALANCE PAYMENT</Typography></TableCell>
                <TableCell>
                {row.balance_paid ? <Typography variant="pgreen">PAID</Typography> 
                                    : (<><Typography variant="pred">NOT PAID</Typography> <Button variant="outlined" size="small" onClick={(event) => onPaid(event,row,"totalPaid")}>Paid</Button></>)
                }                       
                </TableCell>
            </TableRow>    
            <TableRow>
                <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div">ADDRESS</Typography></TableCell>
                <TableCell sx={{ borderBottom:0}}>{row.customers[0].addresses[0].address_line_1}, {row.customers[0].addresses[0].postcode}</TableCell>
            </TableRow>
    
        </TableBody>
        </Table>
        <Box
          sx={{display:'flex', justifyContent:'space-between',m:1}}
        >
          <Button 
          size={'small'}
          variant={'outlined'} 
          startIcon={<SendIcon />}
          onClick={() => setShowMail(true)}
          >
            Send
          </Button>
          <Button 
          size={'small'}
          variant={'text'} 
          startIcon={<HistoryIcon />}
          onClick={() => setShowMailHistory(!showMailHistory)}
          >
            { showMailHistory ? 'Hide Mail History' : 'Show mail history'}
          </Button>
        </Box>
        { showMailHistory &&
        <Box>
          <SentEmailsDetailsTable emails={row.sent_emails} />
        </Box>
        }
        
    </Box>
    
    <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"200px",width:{ "xs": "100%"}, backgroundColor:"#fafafa"}}>
    
        <CustomerDetailsTable customer={row.customers[0]} />

    </Box>
    
    <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"200px",width:{ "xs": "100%"}}}>
    
        <AddressDetailsTable address={row.customers[0].addresses[0]} withActions type="Billing" />
        <AddressDetailsTable address={row.deliveries[0].addresses[0]} withActions type="Delivery" />
        <DeliveryDatePicker 
          sale={row}
          onDelivery={onDelivery} 
          onDelivered={onDelivered} 
          onNotified={onNotified} 
        />
    </Box>
    </Box>

    <ProductsDetailsTable 
      products={row.products} 
      onDelete={() => {}}
      withDrawer={true}
      withActions={false}
      rowColor={"#F6FCEF"}/>

    {row?.extras && row.extras.length > 0 && <ProductExtrasDetailsTable extras={row.extras} /> }

    <Modal
      open={showMail}
      onClose={() => setShowMail(false)}
      title={'Send email'}
    >
      <OrderMailForm
        onUpdate={() => {}}
        model={row}
        submitLabel={'Send'}
      />
    </Modal>
    <br />
    
    </>
  )

}

const SalesList = (props) => {

  const { items } = props;
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showExport, setShowExport] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  if(items.length === 0) return <LinearProgress />

  return (
    <>
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <SalesFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      <Box sx={{display:'flex', columnGap:1, justifyContent:'flex-end', marginY:1}}>
        <Button 
          onClick={() => setShowDownload(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<PictureAsPdfIcon />}
        >
            Download Pdfs
        </Button>
        <Button 
          onClick={() => setShowExport(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<ImportExportIcon />}
        >
          Export view
        </Button>
      </Box>
      { filteredItems.length === 0 ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:1,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="sales"
                  items={filteredItems}
                  columns={columns}
                  rowActions={SaleActions(dispatch, navigate, enqueueSnackbar)}
                  withDrawer={true}
                  renderDrawer={<SaleDrawer />}
                  rowStyles={rowStyles}
                  selectable
                  maxSelected={200}
                  onSelectedRows={(rows) => setSelectedItems(rows)}
                
                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
      <Modal 
          open={showExport}
          onClose={() => setShowExport(false)}
          title={'Export and download'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of entries: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <ExportToFile
            list={selectedItems.length >0 ? selectedItems : filteredItems}
            columns={columns}
            submitLabel= {selectedItems.length > 0 ? 'Export selection' : 'Export view'}
            entityType={'sales'}
            exportable={{exportUrl : 'sales/export'}}
          />
        </Modal>
        <Modal 
          open={showDownload}
          onClose={() => setShowDownload(false)}
          title={'Download multiple invoices'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of invoices: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <DownloadCombinedPdf
            list={selectedItems.length > 0 ? selectedItems : filteredItems}
            entityType='sale'
          />
        </Modal>

        
    </>
  );
}

export default SalesList;