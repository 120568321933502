import { parseISO, format } from 'date-fns'

const parseDateAndTime = (dateString, where) => {

    let parsedISO = Date.parse(dateString);

    return parsedISO ? format(parsedISO, "dd MMM yyyy hh:mm a") : "No Date"
  
  }

export default parseDateAndTime;