import * as React from 'react'
import { Line } from 'react-chartjs-2';

const SalesMonthlyQuantityChart = (props) => {

    const { year } = props;
    
    const data_sales_caravans = {
        //labels: ["J", "F", "M", "A", "M", "J",'J','A','S','O','N','D'],
        labels: year.months.map( month => month.name.substr(0,3) ),
        datasets: [
        {
            label: "Monthly sales",
            data: year.months.map( month => month.no_of_sales ),
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
        },
        {
            label: "Monthly caravans",
            data: year.months.map( month => month.no_of_caravans ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "green",
        },
        ]
    };
    return (
        <Line data={data_sales_caravans} />
    );
}

export default SalesMonthlyQuantityChart