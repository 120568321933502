import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null}
}


export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {

  const response = await axios.get(ApiUrl + "roles");
  return response.data

})

export const roleAdded = createAsyncThunk('roles/roleAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "roles", payload);
  return response.data

})


export const roleDeleted = createAsyncThunk('roles/roleDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "roles/" + payload.id, payload);
  return response.data

})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {

    rolesReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH ROLES
        .addCase(fetchRoles.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchRoles.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Roles to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchRoles.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE Role
        .addCase(roleAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(roleAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Roles to the array
          state.items.push(action.payload);
        })
        .addCase(roleAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // DELETE role
        .addCase(roleDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(roleDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(roleDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })
       
  }
})

export const { rolesReload, reset } = rolesSlice.actions

export const selectRoleById = (state, roleId) => {

  let foundItem = null;
  if(state.roles.items.length > 0) {
    console.log("ROLES PRESENT");
    foundItem = state.roles.items.find(role => role.id == roleId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}

export const selectAllRoles = state => state.roles.items;
export const selectRolesCount = state => state.roles.items.length;
export const selectRolesById = (state, roleId) =>
                      state.roles.items.find(role => role.id === roleId)

export default rolesSlice.reducer;