import * as React from 'react';
import {Box,Card,CardContent, LinearProgress} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import CustomersFilters from './CustomersFilters';
import CustomerDetailsTable from 'views/Dashboard/shared/CustomerDetailsTable'
//import CustomerFeaturedPhoto from 'views/Dashboard/shared/CustomerFeaturedPhoto'
import PrintIcon from '@mui/icons-material/Print';

import { useNavigate} from 'react-router';
import currency from 'utils/currency'
import parseDate from 'utils/parseDate';
import toUpperCase from 'utils/toUpperCase';
import { hidden_md, hidden_xs, hidden_lg } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { CustomerActions } from './actions';
import { AddressDetailsTable } from 'views/Dashboard/shared';

import { trimEmail } from '../utils';
import SalesDetailsTable from 'views/Dashboard/shared/SalesDetailsTable';
const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new customer' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    
  },
  {
    id: 'name',
    disablePadding: false,
    label: 'Name',

  },
  {
    id: 'surname',
    disablePadding: false,
    label: 'Surname',

  },
  {
    id: 'company',
    disablePadding: false,
    label: 'Company',
    hidden : hidden_md
  },
  {
    id: 'mobile',
    disablePadding: false,
    label: 'Mobile',
    hidden : hidden_xs
  },
  {
    id: 'phone',
    disablePadding: false,
    label: 'Phone',
    hidden : hidden_xs
  },
  {
    id: 'email',
    disablePadding: false,
    label: 'email',
    hidden : hidden_lg,
    parseFunction: trimEmail
  },
  {
    id: 'created_at',

    disablePadding: false,
    label: 'Added on',
    hidden : hidden_md,
    parseFunction: parseDate
  }
];

const CustomerDrawer = (props) => {

  const { row } = props;
  return (
    <>
      <Box sx={{ mt: 1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
        <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
        
          <CustomerDetailsTable 
              customer={row} />

        </Box>
        <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>

          <AddressDetailsTable 
              address={row.addresses[0]} 
              type={"Billing"} />

        </Box>
      </Box>
      <Box sx={{ mt: 1, mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
        <SalesDetailsTable 
            sales={row.sales} 
            onDelete={() => {}} 
            withDrawer={true} 
            withActions={true}
            rowColor="#FCF1EF" />
      </Box>
    </>
  )

}

const CustomersList = (props) => {

  const { items, withFilters, withDrawer, withActions, onPick } = props;
  const [filteredItems, setFilteredItems] = React.useState(items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log("FILTER LIST");
    setFilteredItems(items);
  },[items]);

  if(items.length === 0) return <LinearProgress />

  return (
    <>
      { withFilters && 
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <CustomersFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      }
      { filteredItems.length === 0 ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="customers"
                  items={filteredItems}
                  columns={columns}
                  rowActions={withActions ? CustomerActions(dispatch, navigate, enqueueSnackbar) : false}
                  withDrawer={withDrawer}
                  renderDrawer={withDrawer ? <CustomerDrawer /> : <></>}
                  onPick={onPick}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
    </>
  );
}

export default CustomersList;