import * as React from 'react';
import { DistanceDetails } from '../Addresses/components/DistanceFinder';
import DistanceForm from '../Addresses/components/DistanceForm';
import { DashboardContainer } from '../components';
import { Card, CardContent, Typography, CardMedia } from '@mui/material';
import { AssetsUrl } from 'features/api_url';

const Distance = (props) => {

    const [ postcodes, setPostcodes ] = React.useState({ from : "", to: ""})
    const feature_image = AssetsUrl + "route.jpeg";
    return (
        <DashboardContainer>
           <Card>
            <CardMedia
                component="img"
                alt="green iguana"
                height="200px"
                width="100%"
                image={feature_image}
            />
           <CardContent>
                <Typography variant="pgreen" component="h4" sx={{ mt:1,mb:2}}>Enter postcodes to get route information.</Typography>
                <DistanceForm onAction={setPostcodes} />
                    { postcodes.from.length > 5 && postcodes.to.length > 5 &&
                        <DistanceDetails fromP={postcodes.from} toP={postcodes.to} />
                    }
                </CardContent>

            </Card>
 
        </DashboardContainer>
    )
}

export default Distance;