import * as React from 'react'
import { Bar} from 'react-chartjs-2';
import { Box } from '@mui/material';
const VisitorsDailyTotalChart = (props) => {

    const { days } = props;
    
    const data_total = {
        labels: days.map( day=> day.id ),
        datasets: [
          {
            label: "Daily total",
            data: days.map( day => day.total ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.5)",
            borderColor: "rgba(75,192,192,1)",
          },
    
        ]
    };

    return (

        <Bar data={data_total}/>

    );
}

export default VisitorsDailyTotalChart;