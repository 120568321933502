import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { userUpdated, setUser } from 'features/users/usersSlice';
import ScrollToContentTop from 'utils/ScrollToContentTop';
import { useContext } from 'react';
import ContentContext from 'layouts/ContentContext';
import { UserForm } from 'views/Dashboard/Users/components';
import { Card, CardContent, Divider, Typography } from '@mui/material';
import { UserRolesForm } from 'views/Dashboard/Users/components';
import AuthRole from 'layouts/Dashboard/AuthRole';
import HasPermission from 'layouts/Dashboard/HasPermission';

const AccountProfileDetails = (props) => {

  const { user } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentDiv = useContext(ContentContext);
  const [isSaving, setIsSaving] = useState(false);

  console.log("Profile EditForm Render");

  const userWithPass = { ...user, password:""}

  const updateUser = c => {
      const payload = { id: c.id, user: c };

      dispatch(userUpdated(payload)).then(result => {
          if(result.type === "users/userUpdated/fulfilled"){
              
              dispatch(setUser(result.payload));
              setIsSaving(false);
              ScrollToContentTop(contentDiv);

          }
          if(result.type === "users/userUpdated/rejected"){
              ScrollToContentTop(contentDiv);
          }
      });    
  }
  return (
    <>
    <Card {...props}>
        <CardContent>
            <Typography variant="h4">Profile details</Typography>
            <Divider sx={{marginY:2}} />
            <UserForm 
                editMode={true} 
                model={userWithPass}
                onAction={updateUser}
            />
        </CardContent>
    </Card>
     <HasPermission role="administrator">
     <Card sx={{mt:1}}>
       <CardContent>
         <Typography variant="h5" sx={{mb:1}}>User Roles</Typography>

           <UserRolesForm user={user} />

       </CardContent>
     </Card>
     </HasPermission>
     </>
  );
};

export default AccountProfileDetails;
