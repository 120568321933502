import * as React from 'react';
import {  Box,  Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parseDateAndTime from 'utils/parseDateAndTime';
import EmailIcon from '@mui/icons-material/Email';

const SentEmailsDetailsTable = (props) => {

    const { emails } = props;

    return (
            <>
                { emails && emails.length > 0 ?
                (
                    <>
                    { emails.map(email => {
                        return (
                            <Accordion key={email.id}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                >
                                <EmailIcon size={'small'} sx={{mr:1, color: (theme) => theme.palette.primary.main}}/>{parseDateAndTime(email.created_at)} - { email.mailType }
                                </AccordionSummary>
                                <AccordionDetails>
                                {email.message}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    </>

                ) : <Box sx={{p:1,border:1, borderColor:"#f2f2f2", mt:1}}><Typography variant="pred">No emails sent for this sale</Typography></Box>

            }
          
        </>
    )
}

export default SentEmailsDetailsTable;