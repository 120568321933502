import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@mui/material';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';

const TrafficByBrowser = (props) => {
  
  const theme = useTheme();
  const [deviceData, setDeviceData] = React.useState({ bot: 0, chrome:0, firefox:0, opera:0,safari:0,edge:0,app:0});

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.get(ApiUrl + 'stats/visitorsByBrowser',{ signal: abortCtrl.signal})
        .then(response => {

          if(response.data) setDeviceData(response.data);

        })
        .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING browser chart:");
      abortCtrl.abort();
    }

  },[]);

  const browserTotal = deviceData.bot + deviceData.chrome + deviceData.firefox + deviceData.opera 
                                     + deviceData.safari + deviceData.edge + deviceData.app;

  const botPercent = ((deviceData.bot/browserTotal) * 100).toFixed(1);
  const chromePercent = ((deviceData.chrome/browserTotal) * 100).toFixed(1);
  const firefoxPercent = ((deviceData.firefox/browserTotal) * 100).toFixed(1);
  const operaPercent = ((deviceData.opera/browserTotal) * 100).toFixed(1);
  const safariPercent = ((deviceData.safari/browserTotal) * 100).toFixed(1);
  const edgePercent = ((deviceData.edge/browserTotal) * 100).toFixed(1);
  const appPercent = ((deviceData.app/browserTotal) * 100).toFixed(1);

  const data = {
    datasets: [
      {
        data: [deviceData.bot, deviceData.chrome, deviceData.firefox,deviceData.opera, deviceData.safari, deviceData.edge,deviceData.app],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600],
          colors.purple[600],
          colors.brown[600],
          colors.cyan[600],
 
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Bot','Chrome','Firefox','Opera','Safari','Edge','App']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Bot',
      value: botPercent,
      color: colors.indigo[500]
    },
    {
      title: 'Chrome',
      value: chromePercent,
      color: colors.red[600]
    },
    {
      title: 'Firefox',
      value: firefoxPercent,
      color: colors.orange[600]
    },
    {
      title: 'Opera',
      value: operaPercent,
      color: colors.green[600]
    },
    {
      title: 'Safari',
      value: safariPercent,
      color: colors.purple[600]
    },
    {
      title: 'Edge',
      value: edgePercent,
      color: colors.brown[600]
    },
    {
      title: 'App',
      value: appPercent,
      color: colors.cyan[600]
    }
  ];

  return (
    <Card {...props} sx={{height:'100%'}}>
      <CardHeader title="Website traffic by browser" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >

              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h3"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByBrowser;
