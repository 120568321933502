import { CircularProgress, Box } from '@mui/material';

const CenteredCircularProgress = (props) => {

    return(
        <Box sx={{display: 'flex', justifyContent: 'center',mt:2}}>
            <CircularProgress />
        </Box>
    )
}

export default CenteredCircularProgress;