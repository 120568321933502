import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ProductForm } from '.';
import { DashboardContainer } from '../../components';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button
  } from '@mui/material';


import { ProductModel } from 'models';
import { productAdded } from 'features/products/productsSlice';

export default function CreateProductForm(props){

    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
 
    const { enqueueSnackbar } = useSnackbar();
    
    
    const addProduct = product => {

        dispatch(productAdded({...product}))
            .then(result => {

                if(result.type === "products/productAdded/fulfilled"){
                        
                    enqueueSnackbar("New product added successfully");
                    navigate("../");
                }
                else enqueueSnackbar("PRODUCT - " + result.error.message);
            });
    }

                


    console.log("EDITPARAMS:", params);

    return(
        <DashboardContainer>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>


            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            <ProductForm 
                editMode={false}
                model={ProductModel}
                onAction={addProduct}

            />


            </Box>
        </DashboardContainer>
    )

}