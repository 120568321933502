import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import currency from 'utils/currency';

import FigureBox from "../../shared/FigureBox"
import { selectAllProductsFacebookPosts } from 'features/products/productsSlice';
const ShowStats = (props) => {

    const allPosts = useSelector(selectAllProductsFacebookPosts);

    const todaysPosts = React.useMemo(
        () => allPosts.filter((post) => new Date(post.created_at).toISOString().slice(0,10) === new Date().toISOString().slice(0,10)),
        [allPosts]
    );

    const monthPosts = React.useMemo(
        () => allPosts.filter((post) => new Date(post.created_at).toISOString().slice(0,7) === new Date().toISOString().slice(0,7)),
        [allPosts]
    );
    
    return(
        <>

        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="All posts"
                        figure={allPosts.length}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Todays posts"
                        figure={todaysPosts.length}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="This month posts"
                        figure={monthPosts.length}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        avatarColor="green"
                        />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;