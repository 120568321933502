import axios from 'axios';
import * as React from 'react'
import { Typography, Box, Card, CardContent } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { ApiUrl } from 'features/api_url';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ExpensesBySupplier from './components/ExpensesBySupplier';
import ExpensesByType from './components/ExpensesByType';
import ExpensesYearList from './components/ExpensesYearList';
import YearMonthlyExpensesChart from './components/YearMonthlyExpensesChart';
const ExpensesStats = (props) => {

    const today = new Date();

    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth()+1;
    const nextMonth = thisMonth+1;
    const extraZero = (n) => n < 10 ? "0" + n : n;
    const fromDate = thisYear + "-" + extraZero(thisMonth) + "-" + "01";
    const toDate = thisYear + "-" + extraZero(nextMonth) + "-" + "01";
    return (
        <>

            <Grid 
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <ExpensesBySupplier fromDate={fromDate} toDate={toDate} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <ExpensesByType fromDate={fromDate} toDate={toDate} />
                        
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}

                >
                    <ExpensesYearList />
                        
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <YearMonthlyExpensesChart year={"2022"} />
                        
                </Grid>
            </Grid>

        </>
    );
}

export default ExpensesStats;