import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash';
const initialState = {
  items: [],
  isCheckout: false,
  isSaving: false,
  error: null
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.items.push(action.payload);
    },
    removeFromCart: (state, action) => {
      const index = state.items.findIndex(function(o){
        return o.id == action.payload.id;
      })

      state.items.splice(index,1);
          
    },
    reset: state => initialState
  },

})

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, reset } = cartSlice.actions;

export const selectAllItems = state => state.cart.items;
export const selectItemsCount = state => state.cart.items.length;
export const selectCartTotal = state => _.sumBy(state.cart.items, (o) => o.price);

export default cartSlice.reducer;