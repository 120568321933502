import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';
const VisitorDialog = (props) => {

    const { visitor, handleClose, open } = props;
    const [tab, setTab] = React.useState('user');

    const [visitsData, setVisitsData] = React.useState(null);

    const abortCtrl = new AbortController();
    let endDate = null;
    let startDate = null;
    let seconds = 0;
    let minutes = 0;
    if(visitsData && visitsData.length > 1){
        endDate = new Date(visitsData[0].created_at);
        startDate = new Date(visitsData[visitsData.length-1].created_at);
        const diff = endDate.getTime() - startDate.getTime();
        seconds = Math.floor(diff / 1000);
        if (seconds > 60 ){ 
            minutes = Math.floor(seconds/60);
            seconds = Math.floor(seconds % 60);
        }
        console.log("SECS:", seconds);

    }
  
    React.useEffect(() => {

    if(open){
  
      const getData = async () => {
        await axios.get(ApiUrl + 'stats/getVisitsForVisitor/'+ visitor.sessionId,{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) setVisitsData(response.data);


  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();

    }
  
      return () => {
        console.log("UNMOUNTING visitor dialog:");
        abortCtrl.abort();
      }
  
    },[open]);

    return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Visitors details"}
        </DialogTitle>
        <DialogContent>
                <Box sx={{display:'flex', justifyContent:'center', columnGap:1}}>
                    <Button 
                        variant={ tab === 'user' ? 'contained' : 'outlined'} 
                        size='small'
                        onClick={() => setTab('user')}
                        >
                        User Details
                    </Button>
                    <Button 
                        variant={ tab === 'visit' ? 'contained' : 'outlined'} 
                        size='small'
                        onClick={() => setTab('visit')}
                        >
                        Visit Details
                    </Button>
                </Box>
                { tab === 'user' ? (
                <Table>
                    <TableBody>
                        <TableRow><TableCell>IP</TableCell><TableCell>{visitor.ip}</TableCell></TableRow>
                        <TableRow><TableCell>Browser</TableCell><TableCell>{visitor.browserFamily}</TableCell></TableRow>
                        <TableRow><TableCell>Platform</TableCell><TableCell>{visitor.platformFamily}</TableCell></TableRow>
                        <TableRow><TableCell>Device </TableCell><TableCell>{visitor.deviceFamily}</TableCell></TableRow>
                        <TableRow><TableCell>Model </TableCell><TableCell>{visitor.deviceModel}</TableCell></TableRow>
                    </TableBody>
                </Table>
                ) : (
                    <>
                    <Box sx={{ height:'200px', overflowY:'scroll',mt:1}}>
                        <Table>
                            <TableHead>
                                <TableRow><TableCell>Pages Viewed</TableCell></TableRow>
                            </TableHead>
                            
                            <TableBody>
                                { visitsData && visitsData.map(visit => 
                                    <TableRow key={visit.id}><TableCell>{ visit.page }</TableCell></TableRow>
                                )}
                                { (!visitsData || visitsData.length == 0) && 
                                    <TableRow><TableCell>No pages recorded</TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <Typography variant={'body2'} color={'primary.main'} sx={{mt:2}}>
                        Time on website:{' '}
                        { minutes } mins {seconds} secs
                    </Typography>
                </>
                )
                }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>     
    )
}

export default VisitorDialog;