import * as React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';
import currency from 'utils/currency'
import parseDate from 'utils/parseDate';


const StockinSellerDetailsTable = (props) => {

    const { stockin } = props;

    return (
        <>
        <Table size="small" aria-label="purchases" sx={{}}>
                
            <TableBody>
                
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">SELLER NAME</Typography></TableCell>
                    <TableCell>{stockin.seller_name ? stockin.seller_name : "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">ADDRESS</Typography></TableCell>
                    <TableCell>
                    {stockin.address}<br />
                    {stockin.town}<br />
                    {stockin.postcode}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">PRICE</Typography></TableCell>
                    <TableCell>
                    {currency.format(stockin.price)} D({currency.format(stockin.diesel_total)}) R({currency.format(stockin.repairs_total)})
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">PAYMENT DETAILS</Typography></TableCell>
                    <TableCell>
                    <strong>{currency.format(stockin.deposit)}</strong> on <strong>{parseDate(stockin.deposit_paid_on, "deposit")}</strong> by {stockin.deposit_payment_method}<br />
                    <strong>{currency.format(stockin.price - stockin.deposit)}</strong> on <strong>{parseDate(stockin.balance_paid_on, "balance")}</strong> by {stockin.balance_payment_method}<br />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div">TELEPHONE</Typography></TableCell>
                    <TableCell sx={{ borderBottom:0}}>{stockin.telephone ? stockin.telephone : "Not provided"}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </>
    )
}

export default StockinSellerDetailsTable;