import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { DashboardContainer } from 'views/Dashboard/components';
import { AssetsUrl } from 'features/api_url';

const NotAuthorized = (props) => {

    const { isPage } = props;

    const feature_image = AssetsUrl + "not_authorized.png";
    const message = "You are notautorized to access this feature";
    return (
        <>
            { isPage ? (
            <>
            <DashboardContainer>
            <Card>

                <CardContent>
                    <Box sx={{display:"flex",justifyContent:"center"}}>
                    <Box
                        component="img"
                        sx={{
                        height: "300px",
                        width: "300px",
                        maxHeight: { xs: 100, sm: 200, md: 300 },
                        maxWidth: { xs: 100, sm:200, md: 300 },
                        m:"auto"
                        }}
                        alt="Not Authorized"
                        src={feature_image}
                    />
                    </Box>
                    <Typography variant="h4" component="p" sx={{textAlign:"center"}}>{ message }</Typography>
                </CardContent>
            </Card>
            </DashboardContainer>
            </>
            ) : (
                <Typography variant="pred" component="p">{ message }</Typography>
            )
            }
        </>
    )
}

export default NotAuthorized;