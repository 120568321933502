import axios from 'axios';
import * as React from 'react'
import { Typography, Box } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { ApiUrl } from 'features/api_url';
import { Grid } from '@mui/material';
import { TrafficByBrowser, TrafficByDevice, TrafficByOS, VisitorsYearList } from './components'
const VisitorStats = (props) => {

    const [period, setPeriod] = React.useState({});
  
    return (
        <>
            <Grid 
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    md={8}
                >
                    <VisitorsYearList fromDate={"2010-01-01"} toDate={"2040-01-01"}/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                >
                    <TrafficByDevice />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                >
                    <TrafficByBrowser />
                        
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                >
                    <TrafficByOS />
                        
                </Grid>
            </Grid>
        </>
    );
}

export default VisitorStats;