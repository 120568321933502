import * as React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';
import parseDate from 'utils/parseDate';
import { useMediaQuery } from '@mui/material';

const UserDetailsTable = (props) => {

    const { user } = props;

    let lastSeen = null;

    if(user.login_history){

        if(user.login_history.length > 0) lastSeen = user.login_history[user.login_history.length-1].created_at;

    }

    const matches = useMediaQuery("(max-width:400px)");

    return (
        <>
            <Table size="small" aria-label="users" sx={{}}>
            <TableBody>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DISPLAY NAME</Typography></TableCell>
                    <TableCell>{ user.name }</TableCell>
                </TableRow>
                { matches ? (
                <TableRow colSpan={2}>
                    
                    <TableCell>{ user.email }</TableCell>
                </TableRow>
                ) : (
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">EMAIL</Typography></TableCell>
                    <TableCell>{ user.email }</TableCell>
                </TableRow>
                )
                }
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">ADDED:</Typography></TableCell>
                    <TableCell>{parseDate(user.created_at)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">LAST SEEN</Typography></TableCell>
                    <TableCell>{ lastSeen ? parseDate(lastSeen) : "Unknown"}</TableCell>
                </TableRow>
             
            </TableBody>
            </Table>
        </>
    )
}

export default UserDetailsTable;