import { Line } from 'react-chartjs-2';
import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Popover,
  Typography,
  MenuItem
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const YearMonthlyExpensesChart = (props) => {
  const theme = useTheme();
  const { year } = props;
  const [yearData, setYearData] = React.useState({ months:[] });


  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.post(ApiUrl + 'stats/getExpensesByYear', { year: year }, { signal: abortCtrl.signal})
        .then(response => {
          if(response.data) setYearData(response.data);
          console.log(response);
        })
        .catch((err) => console.log('ABORTING: ', err));
      
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING CHART:");
      abortCtrl.abort();
    }

  },[year]);

  const data = {
    datasets: [
      {
        label: "Total £",
        type: 'line',
        data: yearData.months.map(p => p.total),
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        borderColor: "rgb(75, 192, 192)",
    },
      {
        label: "VAT",
        type:'line',
        data: yearData.months.map(p => p.vat),
        fill: false,
        backgroundColor: "rgba(54, 162, 235, 0.4)",
        borderColor: "rgb(54, 162, 235)",
        maxBarThickness:50
    },
  
    ],
    labels: yearData.months.map(p => p.name),
  };
console.log(data);
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card>
      <CardHeader

        title={ year + " spendings"}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Line
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
     
      </Box>
    </Card>
  );
};

export default YearMonthlyExpensesChart;
