import * as React from 'react';
import {Box,Card,CardContent, LinearProgress, Button, Alert, useMediaQuery, useTheme} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from '../../shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import ProductsFilters from './ProductsFilters';
import ProductDetailsTable from 'views/Dashboard/shared/ProductDetailsTable'
import ProductFeaturedPhoto from 'views/Dashboard/shared/ProductFeaturedPhoto'
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import currency from 'utils/currency'
import parseDate from 'utils/parseDate';
import { hidden_md, hidden_xs, hidden_sm } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { ProductActions } from './actions';
import { Published, hasFeature, hasImages } from '../utils';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Modal from 'components/Modal';
import ExportToFile from 'views/Dashboard/shared/ExportToFile';
import DownloadCombinedPdf from 'views/Dashboard/shared/DownloadCombinedPdf';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import useFB from 'components/facebook/hooks/useFB';
import FBContext from 'components/facebook/FBContext';
import TableRowActions from 'views/Dashboard/shared/TableRowActions';

const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new product' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    show:true
    
  },
  {
    id: 'reference',

    disablePadding: false,
    label: 'Ref',
    hidden : hidden_xs,
    show:true
  },
  {
    id: 'make',

    disablePadding: false,
    label: 'Make',
    show:true
  },
  {
    id: 'model',

    disablePadding: false,
    label: 'Model',
    show:true
  },
  {
    id: 'length',

    disablePadding: false,
    label: 'L',
    hidden : hidden_xs,
    show:true
  },
  {
    id: 'width',

    disablePadding: false,
    label: 'W',
    hidden : hidden_xs,
    show:true
  },
  {
    id: 'beds',

    disablePadding: false,
    label: 'Beds',
    hidden : hidden_xs,
    show:true
  },
  {
    id: 'dg',

    disablePadding: false,
    label: 'DG',
    hidden : hidden_md,
    show:true,
    parseFunction: hasFeature
  },
  {
    id: 'ch',

    disablePadding: false,
    label: 'CH',
    hidden : hidden_md,
    show:true,
    parseFunction: hasFeature
  },
  {
    id: 'published',

    disablePadding: false,
    label: 'P',
    hidden : hidden_md,
    parseFunction: Published,
    show:false
  },
  {
    id: 'featured',

    disablePadding: false,
    label: 'F',
    hidden : hidden_md,
    parseFunction: Published,
    show:false
  },
  {
    id: 'sticky',

    disablePadding: false,
    label: 'S',
    hidden : hidden_md,
    parseFunction: Published,
    show:false
  },
  {
    id: 'images',

    disablePadding: false,
    label: 'I',
    hidden : hidden_md,
    parseFunction: hasImages,
    show:false
  },
  {
    id: 'extra_info',

    disablePadding: false,
    label: 'D',
    hidden : hidden_md,
    parseFunction: hasFeature,
    show:false
  },
  {
    id: 'created_at',

    disablePadding: false,
    label: 'Purchase date',
    hidden : hidden_md,
    parseFunction: parseDate,
    show:true
  },
  {
    id: 'price',

    disablePadding: false,
    label: 'Total Cost',
    hidden : hidden_xs,
    parseFunction: currency.format,
    show:true
  }
];

const rowStyles = [
  { property: "backgroundColor", value:"#FCF1EF", column: "sale_id", condition: (value) => value !== null },
  { property: "backgroundColor", value:"#F6FCEF", column: "sale_id", condition: (value) => value === null }
]

export const ProductDrawer = (props) => {

  const { row, actions } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
        <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
        
          <ProductDetailsTable product={row} />
          {!isMobile &&
          <Box sx={{display:'flex',justifyContent:'flex-start',ml:2,mt:1,mb:1, columnGap:1}}>

            <TableRowActions row={row} actions={actions } />
          </Box>
          }
        </Box>
        <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>

            <ProductFeaturedPhoto
                product={row}
                size={{height:"300px", width:"100%"}}
                resolution="640"
                />
            <Box sx={{p:2}}>
              Published: <FiberManualRecordIcon sx={{ color: row.published ? "green" : "red",fontSize:"12px",mr:1 }}  />
              Featured: <FiberManualRecordIcon sx={{ color: row.featured ? "green" : "red",fontSize:"12px",mr:1 }}  />
              Special: <FiberManualRecordIcon sx={{ color: row.sticky ? "green" : "red",fontSize:"12px",mr:1 }}  />

            </Box>
        </Box>
      </Box>
    </>
  )

}

const ProductsList = (props) => {

  const { items } = props;
  const [showExport, setShowExport] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const FB = React.useContext(FBContext);

  React.useEffect(() => {
    console.log("FILTER LIST");
  });
  
  return (
    <>
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <ProductsFilters

                items={items}
                onChange={setFilteredItems}

              />
          </CardContent>
        </Card>
      </Box>
      <Box sx={{display:'flex', columnGap:1, justifyContent:'flex-end', marginY:1}}>
        <Button 
          onClick={() => setShowDownload(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<PictureAsPdfIcon />}
        >
            Download Pdfs
        </Button>
        <Button 
          onClick={() => setShowExport(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<ImportExportIcon />}
        >
          Export view
        </Button>
      </Box>
      { filteredItems.length === 0 ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="products"
                  items={filteredItems}
                  columns={columns}
                  rowActions={ProductActions(dispatch, navigate, enqueueSnackbar)}
                  withDrawer={true}
                  renderDrawer={<ProductDrawer />}
                  rowStyles={rowStyles}
                  selectable
                  maxSelected={200}
                  onSelectedRows={(rows) => setSelectedItems(rows)}
                />
              </CardContent>
            </Card>
            
          </Box>
        )}
      <MobileDial actions={actions} />
      <Modal 
          open={showExport}
          onClose={() => setShowExport(false)}
          title={'Export and download'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of entries: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <ExportToFile
            list={selectedItems.length >0 ? selectedItems : filteredItems}
            columns={columns}
            submitLabel= {selectedItems.length > 0 ? 'Export selection' : 'Export view'}
            entityType={'products'}
            exportable={{exportUrl : 'products/export'}}
          />
        </Modal>
        <Modal 
          open={showDownload}
          onClose={() => setShowDownload(false)}
          title={'Download multiple products'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of products: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <DownloadCombinedPdf
            list={selectedItems.length > 0 ? selectedItems : filteredItems}
            entityType='product'
          />
        </Modal>
    </>
  );
}

export default ProductsList;