import Users from './Users';
import AuthRole from 'layouts/Dashboard/AuthRole';
import {CreateUserForm, EditUserForm } from './components';

const routes = [

  { path: 'users', element: <AuthRole role="administrator" isPage><Users /></AuthRole>},
  { path: 'users/create', element: <AuthRole role="administrator" isPage><CreateUserForm /></AuthRole>},
  { path: 'users/edit/:id', element: <AuthRole role="moderator" isPage><EditUserForm /></AuthRole>}

]

  export default routes;