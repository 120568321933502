import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { 
    selectExpensesCount, 
    selectExpensesTotal,
    selectExpensesTotalVat,
    selectNotPrintedExpensesCount

    } from "features/expenses/expensesSlice";
import FigureBox from "../../shared/FigureBox";

import currencyFraction from "utils/currencyFraction";

const ShowStats = (props) => {

    const allExpensesCount = useSelector(selectExpensesCount);
    const expensesTotal = useSelector(selectExpensesTotal);
    const expensesTotalVat = useSelector(selectExpensesTotalVat);
    const expensesNotPrinted = useSelector(selectNotPrintedExpensesCount);

    return(
        <>
        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Expenses"
                        figure={allExpensesCount}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Amount"
                        figure={currencyFraction.format(expensesTotal)}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total VAT"
                        figure={currencyFraction.format(expensesTotalVat)}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Need printing"
                        figure={expensesNotPrinted}
                        avatarColor="green"
                        />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;