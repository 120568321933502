import * as React from 'react';
import { Grid, IconButton, Box, TextField, Button } from '@mui/material';
import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';
import Close from '@mui/icons-material/Close';

const StockinsFilters = (props) => {

    const {
        items,
        onChange,
        
    } = props;

    const [search, setSearch] = React.useState("");
    const [dateRange, setDateRange] = React.useState({fromDate: '', toDate: ''})
  
    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search, dateRange]);

  
    const handleSearch = (value) => setSearch(value);
    const filterSearch = (list) => searchObjects(list, search);
    const filterDateRange = (list) => {
        if(dateRange.fromDate || dateRange.toDate){
            let startDate = dateRange.fromDate ? new Date(dateRange.fromDate) : new Date(0);
            let endDate = dateRange.toDate ? new Date(dateRange.toDate) : new Date();

            return list.filter(a => {
                var date = new Date(a.created_at);
                return (date >= startDate && date <= endDate);
              });
        } else {
            console.log('No dates selected. Returning...');
            return list;
        }
    }
    const onFilterItems = () => {
        
        let filteredItems = items;
        filteredItems = filterDateRange(filteredItems);
        filteredItems = filterSearch(filteredItems);
        onChange(filteredItems);

    }

    return (
        <Grid
        container
        spacing={3}
        >
            <Grid
            item
            md={3}
            xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
            <Grid
                item
                md={4}
                xs={12}
            >
                <Box display={'flex'} sx={{ columnGap:1}}>
                    <TextField
                        fullWidth
                        label="From date"
                        type="date"
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.fromDate}
                        onChange={({target}) => setDateRange({ ...dateRange, [target.name] : target.value })}
                        variant="outlined"

                    />
                    <TextField
                        fullWidth
                        label="To date"
                        type="date"
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.toDate}
                        onChange={({target}) => setDateRange({ ...dateRange, [target.name] : target.value })}
                        variant="outlined"

                    />
                    <IconButton
                    onClick={() => setDateRange({fromDate:'', toDate:''})}
                >
                    <Close />
                </IconButton>
                </Box>
            </Grid>

        </Grid>

    )

}

export default StockinsFilters;