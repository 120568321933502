import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const rootRef = useRef();
  console.log("ROOT SCROLL");
  useEffect(() => {
    setTimeout(function () {
        rootRef.current.scrollIntoView({behavior: "smooth", block: 'start'});
    },200);
    
  },[pathname]);

  return <div ref={rootRef} className="scroller"></div>;
}