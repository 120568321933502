import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Box,
    IconButton,
    Collapse,
  } from '@mui/material';

import TableRowActions from './TableRowActions';
import { hidden_md } from "views/Dashboard/shared/EntitiesTable/cell_visibility"


const EntityTableRow = ({ row, rowActions, columns, withDrawer, renderDrawer, rowStyles, onPick, selectable, isSelected, toggleSelected }) => {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    
    let rowSx = {};
    if(rowStyles){

      rowStyles.forEach(element => {

        if(element.condition(row[element.column])){

          rowSx = { ...rowSx, [element.property] : element.value }

        }
      });
    }

    return (
      <>
      <TableRow
        hover
        key={row.id}
        onClick={() => setOpen(!open)}
        sx={rowSx}
      >
      { selectable &&
          <TableCell>
            <Checkbox
              color="primary"
              checked={isSelected}
              size={'small'}
              onChange={(event) => { toggleSelected(event, row);}}
              onClick={(event) => event.stopPropagation()}
              
            />
          </TableCell>
        }
      { withDrawer &&
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        }
        {
          columns.map(column => {
            return(
              <TableCell align="left" sx={column.hidden} key={column.id}>

                { column?.parseFunction ? column.parseFunction(row[column.id], row) : row[column.id]}

              </TableCell>
            )
          })
        }
        { (rowActions || onPick) &&
        <TableCell align="left" sx={hidden_md}>
        <Box sx={{ display:"flex"}}>
          { rowActions && <TableRowActions row={row} actions={rowActions } /> }
          { onPick &&
          
            <IconButton color="inherit" size="small" onClick={(event) => onPick(event, row)}>
              <AddCircleIcon sx={{color: (theme) => theme.palette.primary.main }}/>
            </IconButton>
          }
        </Box>
        </TableCell>
        }
      </TableRow>
      { withDrawer && 
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
        { open &&
          <>
          {React.cloneElement(renderDrawer, { row: row, actions: rowActions })}

          {(rowActions || onPick) && isMobile && 
            <Box sx={{ display:"flex"}}>
              { rowActions && <TableRowActions row={row} actions={rowActions } /> }
              { onPick &&
          
                <IconButton color="inherit" size="small" onClick={(event) => onPick(event, row)}>
                  <AddCircleIcon sx={{color: (theme) => theme.palette.primary.main }}/>
                </IconButton>
              }
              
            </Box>
          }
          </>
        }
        </Collapse>
      </TableCell>
    </TableRow>
    }
    </>
    )
  }

  export default EntityTableRow;