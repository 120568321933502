import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Alert, Box, Button, Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ApiUrl } from 'features/api_url';

const DownloadCombinedPdf = ({ list, submitLabel, entityType, exportable }) => {


  const downloadRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleDownload = () => {

    if(list.length > 0) {

      setLoading(true);
      axios.post(
       ApiUrl + "pdf/multi", 
        { ids: list.map(item => item.id), 
          type: entityType,
        }, 
        { responseType: 'blob' }
      )
        .then((response) => {
          try {
            downloadRef.current.href = URL.createObjectURL(response.data, {type: 'application/pdf'});
            downloadRef.current.setAttribute('download', 'CombinedDocuments.pdf');
            downloadRef.current.click();
            setStatus(true);
          } catch (e) {
            setError(e.message);
          } finally {
            setLoading(false);
          }

        }).catch((error) => {
          setError(error.message);
          setLoading(false);
        }); 
    }
  };
  
  return (
    <Box>
      { list.length > 200 &&
        <Alert severity={'error'} sx={{mt:1}}>
          Too many documents to combine. Max allowed is 200.
        </Alert>
        }
      <Box sx={{ display:'flex', justifyContent:'flex-start', flexDirection:'row', paddingY:2, columnGap:1 }}>
        
        <Button 
          variant={'contained'} 
          size={'small'}
          startIcon={<DownloadIcon />}
          disabled={loading || list.length > 200}
          onClick={handleDownload}
        >
          { !loading ? (submitLabel || 'Download') : 'Downloading... Please wait' }
        </Button>
      </Box>

      <Box>

        <Alert severity={'success'} sx={ downloadRef.current?.href  ? { marginY:2} : visuallyHidden }>
          <Typography variant={'caption'}>File download should start automatically. If not click{' '}
            
          </Typography>
          <Typography 
            component={'a'} 
            ref={downloadRef}

            size={'small'}
            
            variant={'caption'}
          >
            here
          </Typography>
        </Alert>
  
        { error &&
          <Alert severity={'error'} sx={{marginY:2}}>{error}</Alert>
        }
      </Box>
    </Box>
  );
 
  
};

DownloadCombinedPdf.propTypes = {
  list: PropTypes.array.isRequired,
  entityType: PropTypes.string.isRequired,

};

export default DownloadCombinedPdf;