import * as React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell, Box } from '@mui/material';
import parseDate from 'utils/parseDate';
import currency from 'utils/currency';
import { ProductsDetailsTable } from '.';
const SaleDetailsTable = (props) => {

    const { sale, withProducts } = props;

    return (
        <>
        <Table size="small" aria-label="stockins" sx={{ minWidth:"200px"}}>
        <TableBody>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">INV NO</Typography></TableCell>
                <TableCell>{sale.invoice_no}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DATE</Typography></TableCell>
                <TableCell>{parseDate(sale.created_at)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">SOLD FOR</Typography></TableCell>
                <TableCell>{currency.format(sale.total)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DEPOSIT </Typography></TableCell>
                <TableCell>
                {sale.deposit_paid ? <Typography variant="pgreen">PAID</Typography> 
                                    : <Typography variant="pred">NOT PAID</Typography>
                }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">BALANCE </Typography></TableCell>
                <TableCell>
                {sale.balance_paid ? <Typography variant="pgreen">PAID</Typography> 
                                    : <Typography variant="pred">NOT PAID</Typography>
                }                       
                </TableCell>
            </TableRow>
        </TableBody>
        </Table>
        { withProducts && 
        <Box sx={{mt:1, mb:1}}>
            
            <ProductsDetailsTable 
                products={sale.products}
                withDrawer={true}
                withActions={false}
                rowColor="#F6FCEF"/>
        </Box>
        }
        </>
    )
}

export default SaleDetailsTable;





