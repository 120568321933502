import * as React from 'react';
import {Box,Card,CardContent, LinearProgress, Grid, Button, Divider, CardHeader } from '@mui/material';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable';

import { ApiUrl } from 'features/api_url';
import axios from 'axios';
import ExpensesMonthsAccordion from './ExpensesMonthsAccordion';
import currencyFraction from 'utils/currencyFraction';

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'Year',
    
  },
  {
    id: 'total',

    disablePadding: false,
    label: 'Total spendings',
    parseFunction: currencyFraction.format
  },
  {
    id: 'vat',

    disablePadding: false,
    label: 'Total VAT',
    parseFunction: currencyFraction.format

  },
 
];



const YearDrawer = (props) => {

  const { row } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentVisitor, setCurrentVisitor] = React.useState("Default deta");
  const [showMonths, setShowMonths] = React.useState(false);

  const handleDialogOpen = (visitor) => {
    setCurrentVisitor(visitor);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
    
      <Grid container spacing={1} sx={{mt:1}}>
        <Grid item xs={12} md={12} order={{ xs:2, md:1 }}>
          <Box sx={{width:'95%',marginY:1}}>

          </Box>
          <Box sx={{marginY:2}}>
          {showMonths? (
              <ExpensesMonthsAccordion 
              months={row.months} 

              />
              ) : ( <Button variant="outlined" onClick={() => setShowMonths(true)} size={'small'} fullWidth sx={{marginY:1}}>Show per month</Button>)
          }
          </Box>
        </Grid>

      </Grid>

    </>
  )

}

const ExpensesYearList = (props) => {

  const [items, setItems] = React.useState(null);
  

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {

      const response = await axios.get(ApiUrl + 'stats/getExpensesAllYears',{ signal: abortCtrl.signal})
          .then(response => {

            if(response.data) setItems(response.data);

          })
          .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING LIST:");
      abortCtrl.abort();
    }

  },[]);

  return (
<Card>
      <CardHeader

        title={'Expenses Years'}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{

            position: 'relative',
            pb: 2
          }}
        >
      { !items ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="years"
                  items={items}
                  columns={columns}
                  withDrawer={true}
                  renderDrawer={<YearDrawer />}

                />
              </CardContent>
            </Card>
          </Box>
        )}
        </Box>
      </CardContent>
      <Divider />
    
    </Card>


  );
}

export default ExpensesYearList;