import Products from './Products';
import {CreateProductForm, EditProductForm, ViewProduct } from './components';
import AuthRole from 'layouts/Dashboard/AuthRole';
import ViewPdf from '../shared/ViewPdf';

const routes = [

  { path: 'products', element: <Products />},
  { path: 'products/view/:id', element: <AuthRole role="moderator" isPage><ViewProduct /></AuthRole>},
  { path: 'products/create', element: <AuthRole role="administrator" isPage><CreateProductForm /></AuthRole>},
  { path: 'products/edit/:id', element: <AuthRole role="moderator" isPage><EditProductForm /></AuthRole>},
  { path: 'products/pdf/:id', element: <AuthRole role="moderator" isPage><ViewPdf type="product" /></AuthRole>}

]

  export default routes;