import * as React from 'react';
import { SalesNav, SalesList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllSales} from 'features/sales/salesSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';

const Sales = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllSales);

  return (

      <DashboardContainer>
        <SalesNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>
        { statsOpen && <ShowStats />}
        { items.length != 0 ? (

          <SalesList
            items={items}
          />

        ) : (

          <CenteredCircularProgress />

        )
      }
      </DashboardContainer>

  );


}

export default Sales;