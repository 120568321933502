import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import TotalInStock from './components/TotalInStock';
import StockinSalesBalanceChart from 'views/Dashboard//Statistics/components/GeneralStats/components/StockinSalesBalanceChart';
import SoldProducts from './components/SoldProducts';
import AvailableProducts from './components/AvailableProducts';
import TotalProfit from './components/TotalSales';
import TrafficByDevice from './components/TrafficByDevice';
import { LatestSalesTable } from 'views/Dashboard/Statistics/components/SalesStats/components';
import { LatestProducts } from 'views/Dashboard/Statistics/components/ProductsStats/components'
import { YearChart } from 'views/Dashboard/Statistics/components/SalesStats/components';
import {LatestVisitorsTable} from 'views/Dashboard/Statistics/components/VisitorsStats/components';
import UpcomingEventsTable from 'views/Dashboard/Events/components/UpcomingEventsTable';

const Home = () => {

  const today = new Date();

  const thisYear = today.getFullYear();
  
  return(
  <>
    <Helmet>
      <title>Dashboard | Lincolnshire Static Ltd</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={6}
          >
            <TotalInStock />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={6}
          >
            <AvailableProducts />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={6}
          >
            <SoldProducts />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={6}
          >
            <TotalProfit sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={8}
            xs={12}
          >
            <StockinSalesBalanceChart weeks={12} sx={{ height: '100%' }}/>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <YearChart year={thisYear} />
            <Box sx={{marginY:2}}></Box>
            <YearChart year={thisYear-1} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <UpcomingEventsTable sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <LatestVisitorsTable sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <TrafficByDevice sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestSalesTable />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
  )
}

export default Home;
