import * as React from 'react';
import { Grid, Card } from '@mui/material';
import { uniqueId } from 'lodash';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const UploadPhotosList = (props) => {

    const { files, onDelete } = props;
    console.log("Rendering photo list");
    const renderGrid = React.useMemo(() => files.map((file,index) => {
        const img_url = URL.createObjectURL(file);
       
        return(
            <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={uniqueId()}
            >
                <Card sx={{ height:"100%",backgroundColor:"#f2f2f2", position:"relative"}}>
                    <img src={img_url} title={file.name} alt="Preview" width="100%"/>
                    <IconButton 
                        aria-label="delete" 
                        sx={{position:"absolute",top:"5px",right:"5px",color:"white"}}
                        onClick={() => onDelete(index)}
                    >
                    <DeleteIcon />
                    </IconButton>
                </Card>
            </Grid>
            )
        }), [files]);

    return(
        <Grid
            container
            spacing={3}
        >
        { renderGrid }
        </Grid>
    )
}

export default UploadPhotosList;