import * as React from 'react'
import {NavLink as RouterLink,} from 'react-router-dom';
import parseDate from 'utils/parseDate';
import { Table, TableSortLabel, TableBody, TableCell, TableHead, TableRow, Alert, Button, Card, CardHeader, Divider, Box, Tooltip, Chip, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import currency from 'utils/currency';
import { useSelector } from 'react-redux';
import { selectSalesMonthYear } from 'features/statistics/statisticsSlice';
const SingleMonthSalesTable = (props) => {

    const { month, year,title } = props;

    const selectedMonth = useSelector(state => selectSalesMonthYear(state, month,year));
    return (
        <>
            
            <Card {...props}>
            <CardHeader title={title} />
            <Divider />
              <Box>
              {selectedMonth && selectedMonth.sales.length > 0 ? (
                <>
                <Box sx={{ display:'flex', justifyContent:'flex-end',p:2,gap:'10px'}}>
                    <Chip label={ selectedMonth.name } size="small" />
                    <Chip label={ "Sales: " + selectedMonth.no_of_sales} size="small" />
                    <Chip label={ "Caravans: " + selectedMonth.no_of_caravans} size="small" />
                    <Chip label={ "Total: " + currency.format(selectedMonth.total)} size="small" />
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Order Ref
                      </TableCell>
                      <TableCell>
                        Products
                      </TableCell>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Date
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedMonth.sales.map((sale) => (
                      <TableRow
                        hover
                        key={sale.id}
                      >
                        <TableCell>
                          INV{sale.invoice_no}
                        </TableCell>
                        <TableCell>
                        { (sale.products && sale.products.length > 0) ? (
                            <>
                            <Typography variant='p'>{sale.products[0].make} {sale.products[0].model}</Typography>
                            </>
                            ) : (
                                <Typography variant="p">No products</Typography>
                            )
                        }
                        </TableCell>
                        <TableCell>
                          { parseDate(sale.created_at) }
                        </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={sale.balance_paid ? 'Paid' : 'Pending'}
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </>
                ) : (
                    <Alert severity='warning'>No sales this month</Alert>
                )
                }
              </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
                component={RouterLink}
                to={'/dashboard/sales'}
              >
                View all
              </Button>
            </Box>
          </Card>
        </>
    );
}

export default SingleMonthSalesTable;