import { stockinDeleted } from 'features/stockins/stockinsSlice';  
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
  // DELETE Stockin
export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    const confirm = window.confirm("Are you sure ?");

    if(confirm) dispatch(stockinDeleted(item)).then(result => {

      result.type === "stockins/stockinDeleted/fulfilled"
                            ? enqueueSnackbar("Stockin deleted successfully")
                            : enqueueSnackbar("STOCKIN - " + result.error.message);
                });
  }

  // EDIT Stockin
export const handleEditItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    navigate("/dashboard/stockins/edit/" + item.id)
  }
  // EDIT Stockin
export const handlePrintItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    navigate("/print/stockinInvoice/" + item.id)
  }

  export const handlePDFItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
    event.stopPropagation();
  
    navigate("/dashboard/stockins/pdf/" + item.id);
  }
  // ACTIONS
export const StockinActions = (dispatch, navigate, enqueueSnackbar) => [
    {
      id:1,
      name: "Edit",
      icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
      show: true
      },
      {
      id:2,
      name: "Delete",
      icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
      show: true
      },
      {
      id:3,
      name: "Print",
      icon: <LocalPrintshopIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handlePrintItem(dispatch, navigate, enqueueSnackbar),
      show: true
      },
      {
        id:4,
        name: "PDF",
        icon: <PictureAsPdfIcon sx={{color: (theme) => theme.palette.primary.main }}/>,
        callback: handlePDFItem(dispatch, navigate, enqueueSnackbar),
        show: true
        },
    ];