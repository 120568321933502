import * as React from 'react';
import { UsersNav, UsersList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllUsers} from 'features/users/usersSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';

const Users = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllUsers);

  return (

      <DashboardContainer>
        <UsersNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>
        { statsOpen && <ShowStats />}
        { items.length != 0 ? (

          <UsersList
            items={items}
            withFilters
            withActions
            withDrawer
          />

        ) : (

          <CenteredCircularProgress />

        )
      }
      </DashboardContainer>

  );


}

export default Users;