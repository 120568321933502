import * as React from 'react';
import {Grid, TextField} from '@mui/material';

import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';

const etype = [
    {
      value: 'All',
      label: 'All'
    },
    {
        value: 'New',
        label: 'New'
    },
    {
        value: 'Contact',
        label: 'Contact Page'
    },
    {
      value: 'SellCaravan',
      label: 'Sale request'
    },
    {
        value: 'Product',
        label: 'Product Enquire'
      },
  ];

const EnquiresFilters = (props) => {

    const {
        items,
        onChange,
        
    } = props;

    const [search, setSearch] = React.useState("");
    const [enquireType, setEnquireType] = React.useState('New');
  
    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search, enquireType]);

  
    const handleSearch = (value) => setSearch(value);
    const handleEnquireType = (value) => setEnquireType(value);
    const filterSearch = (list) => searchObjects(list, search);
    const filterEnquireType = (list) => {
        console.log("TYPE : ", enquireType);
        switch (enquireType) {

            case 'All':
        
                return list;

            case 'New':
                console.log("NEW");
                return list.filter(item => item.seen == false);

            case 'SellCaravan':

                return list.filter(item => item.type == 'SellCaravan');
            case 'Product':

                return list.filter(item => item.type == 'Product');

            case 'Contact':

                return list.filter(item => item.type == 'Contact');

            default:
                return list;
        }
    }
   
    const onFilterItems = () => {
        
        let filteredItems = items;

        filteredItems = filterSearch(filteredItems);
        filteredItems = filterEnquireType(filteredItems);
        console.log(filteredItems);
        onChange(filteredItems);

    }

    return (
        <Grid
        container
        spacing={3}
        >
            <Grid
            item
            md={3}
            xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
             <Grid
                item
                md={3}
                xs={12}
            >
                <TextField
                  fullWidth
                  label="Enquire Type"
                  name="enquireType"
                  onChange={(event) => handleEnquireType(event.target.value)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={enquireType}
                  variant="outlined"
                >
                  {etype.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
            </Grid>        
        </Grid>

    )

}

export default EnquiresFilters;