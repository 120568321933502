import * as React from 'react';
import { useParams } from 'react-router-dom';

import { ProductForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography, CardHeader } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { selectProductById, productUpdated, productDownloadedPhotos, checkIfPhotosZipExists } from 'features/products/productsSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import ProductPhotosList from './ProductPhotosList';
import { isEqual } from 'lodash';
import UploadPhotos from './UploadPhotos';
import PostProductOnFacebook from './PostProductOnFacebook';
import Modal from 'components/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import { PhotosArchivesUrl } from 'features/api_url';

export default function EditProductForm(props){

    const contentDiv = useContext(ContentContext);

    const { id } = useParams();

    const product = useSelector((state) => selectProductById(state, id), isEqual);
    const updateStatus = useSelector((state) => state.products.editing.status);
    const updateError = useSelector((state) => state.products.editing.error);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);
    const [showPhotosDownload, setShowPhotosDownload] = useState(false);
    const [photosSize, setPhotosSize] = useState('original');
    const [downloadLink, setDownloadLink] = useState(null);
    const [downloadError, setDownloadError] = useState(null);

    
    
    const handlePhotosSize = (e, size) => setPhotosSize(size);

    const handleDownloadPhotos = () => {
        console.log(photosSize);
        dispatch(productDownloadedPhotos({ id : product.id, size: photosSize })).then(result => {
            console.log('dispatched', result);
            setDownloadError(null);
            setDownloadLink(null);
            if(result.type === "products/downloadPhotos/fulfilled"){
                console.log(result.payload);    
                console.log('Download success');
                setDownloadLink(PhotosArchivesUrl + result.payload);
            }
            if(result.type === "products/downloadPhotos/rejected"){
                setDownloadError('An error occured. Please try again');
                
            }
        });    
    }

    console.log(product);
    const updateProduct = s => {
        
        setIsSaving(true);
        let ss = Object.assign({}, s);
        if(ss.sale_id == "") ss.sale_id = null;

        dispatch(productUpdated(ss)).then(result => {
            if(result.type === "products/productUpdated/fulfilled"){
                
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../..");
            }
            if(result.type === "products/productUpdated/rejected"){
                ScrollToContentTop(contentDiv);
            }
        });    
    };

    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>

                <Box sx={{ display:"flex",columnGap: 1 }}>

                    <Link to="edit/10">
                        <Button sx={{ mx: 1 }} size="small" >
                        Print
                        </Button>
                    </Link>

                </Box>
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { updateError && <Alert severity="error">{updateError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {updateStatus}</Alert>
                </>
            }   
            { product === undefined && <Alert severity="error">Product with given ID does not exist.</Alert> }
            { product &&
                <ProductForm 
                editMode={true} 
                model={product}
                onAction={updateProduct}
                />
            } 
            <Card sx={{mt:2}}>
                <CardContent>
                    <PostProductOnFacebook product={product} />
                </CardContent>
            </Card>
            <Card sx={{mt:2}}>
                <CardContent>
                    <UploadPhotos product={product} />
                </CardContent>
            </Card>
            <Card sx={{mt:2}}>
                <CardContent>
                    <ProductPhotosList product={product} />
                    <Button 
                        size={'small'} 
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => setShowPhotosDownload(true)}
                        sx={{mt:2}}
                        disabled={product ? product.images.length === 0 : true}
                    >
                        Download photos
                    </Button>
                </CardContent>
            </Card>

            
            </Box>
            <Modal 
            open={showPhotosDownload}
            onClose={() => setShowPhotosDownload(false)}
            title={'Download photos'}
            cancelLabel={'Close'}
                >
                <Typography>Please select size of the photos : </Typography>
                <Box
                    sx={{mt:2,mb:1,display:'flex', justifyContent:'center'}}
                >
                    <ToggleButtonGroup
                        value={photosSize}
                        exclusive
                        onChange={handlePhotosSize}
                        aria-label="text alignment"
                        >
                        <ToggleButton value="original" aria-label="original">
                            Original
                        </ToggleButton>
                        <ToggleButton value="1024" aria-label="large">
                            1024
                        </ToggleButton>
                        <ToggleButton value="640" aria-label="medium">
                            640
                        </ToggleButton>
                        <ToggleButton value="320" aria-label="small">
                            320
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Button 
                        variant={'contained'}
                        startIcon={<DownloadIcon />}
                        onClick={() => handleDownloadPhotos()}
                    >Create zip file
                    </Button>
                </Box>
                <Box  sx={{display:'flex', justifyContent:'center', mt:1}}>
                    { downloadError && <Typography color={'darkred'} variant={'caption'} align={'center'}> { downloadError } </Typography> }
                    { downloadLink && <Button size={'small'} color={'success'} variant={'contained'} component={'a'} href={downloadLink}>Download photos</Button> }
                </Box>
            </Modal>
        </DashboardContainer>
        </>
    )

}