import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';



const withLink = (to, children) => {

    return(
    <Link 
        to={to}
        
    >
        {children}
    </Link>
    )
}
export default withLink;