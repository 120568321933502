import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { enquireMarkedAsSeen, enquireDeleted } from 'features/enquires/enquiresSlice';

export const handleMArkAsSeenItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

      dispatch(enquireMarkedAsSeen(item)).then(result => {

        result.type === "enquires/markedAsSeen/fulfilled"
                          ? enqueueSnackbar("Enquire marked as seen successfully")
                          : enqueueSnackbar("Enquire - " + result.error.message);
      });
}
  // DELETE Stockin
export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    const confirm = window.confirm("Are you sure ?");

    if(confirm) dispatch(enquireDeleted(item)).then(result => {

      result.type === "enquires/enquireDeleted/fulfilled"
                            ? enqueueSnackbar("Enquire deleted successfully")
                            : enqueueSnackbar("Enquire - " + result.error.message);
                });
}

export const EnquiresActions = (dispatch, navigate, enqueueSnackbar) => [
  {
  id:1,
  name: "Mark as seen",
  icon: <VisibilityIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
  callback: handleMArkAsSeenItem(dispatch, navigate, enqueueSnackbar),
  show: true
  },
  {
    id:2,
    name: "Delete",
    icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
  ];