import React from 'react';
import { TextField, Box, Typography, Alert } from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const DeliveryDatePicker = (props) => {

    const { sale, onDelivery, onDelivered, onNotified } = props;

    const initDeliveryDate = sale.deliveries[0].delivery_on ? sale.deliveries[0].delivery_on.substr(0,10) : "";
    const initDeliveredDate = sale.deliveries[0].delivered_on ? sale.deliveries[0].delivered_on.substr(0,10) : "";

    const [pickedDate, setPickedDate] = React.useState(initDeliveryDate);
    const [deliveredDate, setDeliveredDate] = React.useState(initDeliveredDate);

    const [error, setError] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [editDates, setEditDates] = React.useState(false);



    const handleDeliveryDateChange = (event) => {
        console.log("DATE CHANGED", event);
        setPickedDate(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const handleDeliveredDateChange = (event) => {
        console.log("DATE CHANGED", event);
        setDeliveredDate(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const handleBookDeliveryDate = (event) => {
        if(pickedDate){
            console.log("BOOKING",event);
            onDelivery(event, sale, pickedDate);
            setError(false);
        }
        else {
            setError(true);
            setErrorMsg("Please pick a date")
        } 
        
    }
    const handleDeliveredDate = (event) => {
        if(deliveredDate){
            console.log("BOOKING",event);
            onDelivered(event, sale, deliveredDate);
            setError(false);
        }
        else {
            setError(true);
            setErrorMsg("Please pick a date")
        } 
        
    }
    return (
        <>
            { deliveredDate && <Alert severity={'info'}>Delivered on : { deliveredDate } <EditIcon sx={{fontSize:16,ml:1}} onClick={() => setEditDates(!editDates)}/></Alert> }

            { (!deliveredDate || editDates) &&
            <>
            <Box sx={{display:'flex',p:1}}>
                <TextField
                    fullWidth
                    label="Delivery Date"
                    type="date"
                    name="delivery_date"
                    value={pickedDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleDeliveryDateChange}
                    variant="outlined"
                />
                <Button onClick={handleBookDeliveryDate}>Book</Button>
            </Box>
            { initDeliveryDate &&
                <Box sx={{display:'flex',p:1}}>
                    <TextField
                        fullWidth
                        label="Delivered Date"
                        type="date"
                        name="delivered_date"
                        value={deliveredDate}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleDeliveredDateChange}
                        variant="outlined"
                    />
                    <Button onClick={handleDeliveredDate}>SET</Button>

                </Box>
            }
            {error &&
                <Box>
                    {errorMsg}
                </Box>
            }
            </>
            }

        </>
    )
}

export default DeliveryDatePicker;