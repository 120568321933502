import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useMediaQuery } from '@mui/material';
export default function MobileDial(props) {

    const matches = useMediaQuery("(max-width:600px)");
  return (
      <>
     { matches ? 
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {props.actions.map((action) => (

          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            sx={{
                "& svg": {
                    display: "block"
                  },
                "& a": {
                    color: "primary.main"
                  }
            }}
          />

        ))}
      </SpeedDial>
    : <></>}
    </>
  );
}
