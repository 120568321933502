import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'features/users/usersSlice';
import { checkRole, isAdmin } from 'utils/checkRole';
import { Typography } from '@mui/material';
import NotAuthorized from './NotAuthorized';
const AuthRole = (props) => {

    const { children, role, isPage } = props;

    const user = useSelector(selectUser);
    
    const isAuthorized = isAdmin(user) ? true : checkRole(user,role);

    return isAuthorized ? children : <NotAuthorized isPage={ isPage ? true : false } />;

}

export default AuthRole;