import * as React from 'react';
import { ProductsNav, ProductsList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllProducts} from 'features/products/productsSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';

const Products = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllProducts);

  return (

      <DashboardContainer>
        <ProductsNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>
        { statsOpen && <ShowStats />}

        { items.length != 0 ? (

          <ProductsList
            items={items}
          />

        ) : (

          <CenteredCircularProgress />

        )
      }
      </DashboardContainer>

  );


}

export default Products;