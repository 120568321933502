import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';
import { red } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { selectProductsAvailableTotal } from 'features/products/productsSlice';
import currency from 'utils/currency';
const TotalInStock = (props) => {

  const totalAmountInStock = useSelector(selectProductsAvailableTotal);
  return (
      <Card
        sx={{ height: '100%' }}
        {...props}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                TOTAL IN STOCK
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                { currency.format(totalAmountInStock) }
              </Typography>
            </Grid>

          </Grid>
        
        </CardContent>
      </Card>
  );
}

export default TotalInStock;
