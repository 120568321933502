import * as React from 'react';
import { CardMedia} from '@mui/material';
import { makeImgUrl } from 'features/api_url';
const ProductFeaturedPhoto = (props) => {

    const { product, size, resolution } = props;

    return (
        <>
        <CardMedia
            //className={classes.media}
            sx={size} 
            image={ product.images?.length > 0 ? makeImgUrl(product.id,resolution,product.images[0].url) : "/static/images/image_placeholder.jpeg"}
            title={ product.images?.length > 0 ? (product.images[0].title) : "No Image" }
            />      
        </>
    )
}

export default ProductFeaturedPhoto;