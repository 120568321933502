import * as React from 'react';
import {Box,Card,CardContent, LinearProgress} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'

//import CustomerFeaturedPhoto from 'views/Dashboard/shared/CustomerFeaturedPhoto'
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import parseDate from 'utils/parseDate';
import toUpperCase from 'utils/toUpperCase';
import { hidden_md, hidden_xs, hidden_lg } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { LoginHistoryDetailsTable } from 'views/Dashboard/shared';

const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new customer' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const parseUserName = (user) => user.name;

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    
  },
  {
    id: 'user',
    disablePadding: false,
    label: 'User',
    parseFunction: parseUserName

  },
  {
    id: 'login_date',
    disablePadding: false,
    label: 'Date',
    parseFunction: parseDate
  },
  {
    id: 'ip',
    disablePadding: false,
    label: 'Ip',
    hidden : hidden_md
  },
 
];

const LoginHistoryDrawer = (props) => {

  const { row } = props;
  return (
      <Box sx={{ mt: 1}}>
        <LoginHistoryDetailsTable item={row} />
      </Box>
  )

}


const LoginHistoryList = (props) => {

  const { items , withDrawer } = props;
  const [filteredItems, setFilteredItems] = React.useState(items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setFilteredItems(items);
  },[items]);

  if(items.length === 0) return <LinearProgress />

  return (
    <>
      { filteredItems.length === 0 ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="login history"
                  items={filteredItems}
                  columns={columns}
                  withDrawer
                  renderDrawer={<LoginHistoryDrawer />}
                />
              </CardContent>
            </Card>
          </Box>
        )}

    </>
  );
}

export default LoginHistoryList;