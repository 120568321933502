import * as React from 'react';
import { useRoutes } from 'react-router-dom'
import routes from './routes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import './scss/react-images.scss';
import './scss/slick-slider.scss';
import useInitFbSDK from 'components/facebook/hooks/useInitFbSDK';

function App() {
  const content = useRoutes(routes);
  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  );
}

export default App;
