import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    LinearProgress
} from '@mui/material';

import { green } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import { useSelector } from 'react-redux'
import { selectProductsAvailableCount } from 'features/products/productsSlice';

const AvailableProducts = (props) => {

    const AvailableProductsCount = useSelector(selectProductsAvailableCount);

    return ( <
        Card {...props } >
        <
        CardContent >
        <
        Grid container spacing = { 3 }
        sx = {
            { justifyContent: 'space-between' } } >
        <
        Grid item >
        <
        Typography color = "textSecondary"
        gutterBottom variant = "h6" >
        TOTAL AVAILABLE <
        /Typography> <
        Typography color = "textPrimary"
        variant = "h3" >
        { AvailableProductsCount } <
        /Typography> <
        /Grid>  <
        /Grid>

        <
        /CardContent> <
        /Card>
    )
}

export default AvailableProducts;