import * as React from 'react';
import {Box,Card,CardContent, LinearProgress, Grid, Button } from '@mui/material';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable';
import VisitorsMonthsAccordion from './VisitorsMonthsAccordion';
import { VisitorsMonthlyTotalChart } from '../../charts';
import VisitorDialog from './VisitorDialog';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'Year',
    
  },
  {
    id: 'total',

    disablePadding: false,
    label: 'No. of visitors',

  },
 
];



const YearDrawer = (props) => {

  const { row } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentVisitor, setCurrentVisitor] = React.useState("Default deta");
  const [showMonths, setShowMonths] = React.useState(false);

  const handleDialogOpen = (visitor) => {
    setCurrentVisitor(visitor);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
    
      <Grid container spacing={1} sx={{mt:1}}>
        <Grid item xs={12} md={12} order={{ xs:2, md:1 }}>
          <Box sx={{width:'95%',marginY:1}}>
            <VisitorsMonthlyTotalChart months={row.months} />
          </Box>
          <Box sx={{marginY:2}}>
          {showMonths? (
              <VisitorsMonthsAccordion 
              months={row.months} 
              handleDialogOpen={handleDialogOpen} 
              />
              ) : ( <Button variant="outlined" onClick={() => setShowMonths(true)} size={'small'} fullWidth sx={{marginY:1}}>Show per month</Button>)
          }
          </Box>
        </Grid>

      </Grid>
      <VisitorDialog open={openDialog} handleClose={handleDialogClose} visitor={currentVisitor} />
    </>
  )

}

const VisitorsYearList = (props) => {

  const { fromDate, toDate } = props;
  const [items, setItems] = React.useState(null);
  const [period,setPeriod] = React.useState({ from: fromDate, to: toDate });

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {

      const response = await axios.post(ApiUrl + 'stats/getVisitorsData',period,{ signal: abortCtrl.signal})
          .then(response => {

            if(response.data) setItems(response.data);

          })
          .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING LIST:");
      abortCtrl.abort();
    }

  },[period, fromDate, toDate]);

  return (
    <>
      { !items ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="years"
                  items={items}
                  columns={columns}
                  withDrawer={true}
                  renderDrawer={<YearDrawer />}

                />
              </CardContent>
            </Card>
          </Box>
        )}

    </>
  );
}

export default VisitorsYearList;