import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router';

export default function StockinsNav(props){

    const navigate = useNavigate();

    const handlePrint = (name) => {

      navigate("/print/stockinsList/");

    };

    return(
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 1
            }}
      >
        <Box>
          <Link to="../">
            <Button
              sx={{ mr: "auto" }}
              color="success"
              variant="outlined"
              size="small" 
            >
              Back
            </Button>
          </Link>
        </Box>

        <Box sx={{ display:"flex",columnGap: 1 }}>

          <Button
            variant="outlined" 
            size="small"
            id="print-button"

            onClick={handlePrint}
            startIcon={<PrintIcon />}
          >
          Print
          </Button>
          <Link to="create">
            <Button variant="outlined" size="small" startIcon={<AddCircleOutlineIcon />}>
              Add
            </Button>
          </Link>

        </Box>

      </Box>
    )

}