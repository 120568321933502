import * as React from 'react';
import EntityTableRow from './EntityTableRow'

const EntityTableRows = ({ rows, rowActions, columns, withDrawer, renderDrawer, rowStyles, onPick, selectable, isSelected, toggleSelected }) => {

  
    return rows.map((row, index) => {
            const isItemSelected = isSelected(row);
            return (
              <EntityTableRow 
                row={row} 
                key={row.id} 
                rowActions={rowActions} 
                columns={columns} 
                withDrawer={withDrawer}
                renderDrawer={renderDrawer}
                rowStyles={rowStyles}
                onPick={onPick}
                selectable={selectable}
                isSelected={isItemSelected}
                toggleSelected={toggleSelected}
                />
            );
          })
    


}

export default EntityTableRows;