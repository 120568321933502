import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { StockinForm } from '.';
import { DashboardContainer } from '../../components';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
  } from '@mui/material';

import { StockinModel, ProductModel } from 'models';
import { stockinAdded } from 'features/stockins/stockinsSlice';
import { productAdded } from 'features/products/productsSlice';

export default function CreateStockinForm(props){

    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [dg,setDg] = useState(false);
    const [ch,setCh] = useState(false);

    const [addToProducts, setAddToProducts] = useState(false);
    const [newProductRetailPrice, setnewProductRetailPrice] = useState(0);
 
    const { enqueueSnackbar } = useSnackbar();
    
    const handleAddToProducts = (event) => {
        setAddToProducts(event.target.checked)
      }

    
    const addStockin = stockin => {

        dispatch(stockinAdded({...stockin}))
            .then(result => {
                console.log("Promise:", result);
                if(result.type === "stockins/stockinAdded/fulfilled"){ 

                    enqueueSnackbar("New stock added successfully")

                    if(addToProducts){
         
                        let newProduct = Object.assign({}, ProductModel);
                  
                        Object.keys(stockin).forEach(function(key) {
                          if (key in newProduct) { // or obj1.hasOwnProperty(key)
                              newProduct[key] = stockin[key];
                          }
                        });
                        // new product has no sale_id
                  
                        newProduct = {...newProduct, 
                          id: "",
                          dg: dg, 
                          ch: ch, 
                          price: newProductRetailPrice, 
                          beds: stockin.no_of_beds, 
                          created_at: "",
                          updated_at: ""
                          }
                  
                        delete newProduct.sale_id;
                  
                        dispatch(productAdded(newProduct))
                            .then(result => {

                                result.type === "products/productAdded/fulfilled"
                                    ? enqueueSnackbar("New product added successfully")
                                    : enqueueSnackbar("PRODUCT - " + result.error.message);
                            });
                    }
                    
                    navigate("../");
                }
                else { enqueueSnackbar("STOCK IN - " + result.error.message) }
  
            });
    }

    console.log("EDITPARAMS:", params);

    return(
        <DashboardContainer>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>


            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            <StockinForm 
                editMode={false}
                model={StockinModel}
                onAction={addStockin}

            />

            <Card sx={{mt:1}}>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                        >
                        { addToProducts && 
                        <>
                        <Grid 
                            item
                            xs={3}
                        
                        >
                            <FormControlLabel
                                control={<Switch checked={dg} onChange={(event) => setDg(event.target.checked)} name="dg" />}
                                label="DG"
                            />
                        </Grid>
                        <Grid 
                            item
                            xs={3}
                        >
                            <FormControlLabel
                                control={<Switch checked={ch} onChange={(event) => setCh(event.target.checked)} name="ch" />}
                                label="CH"
                            />
                        </Grid>
                        <Grid 
                            item
                            xs={6}
                        >
                            <TextField
                                fullWidth
                                label="Retail Price"
                                name="retailPrice"
                                onChange={(event) => setnewProductRetailPrice(event.target.value)}
                                type="Number"
                                value={newProductRetailPrice}
                                variant="outlined"
                                />
                        </Grid>
                        </>
                        }
                        <Grid
                            item
                            xs={12}
                            >
                            <FormControlLabel
                            control={<Switch checked={addToProducts} onChange={handleAddToProducts} />}
                            label="Add to products"
                            />
                          
                        </Grid>
             
                    </Grid>
                </CardContent>
            </Card>
            </Box>
        </DashboardContainer>
    )

}