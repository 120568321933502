import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TableCell,
  TableRow,
  Table,
  TextField,
  IconButton,
  Typography,
  TableBody

} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEmpty } from 'underscore';
import { ExtraModel } from 'models';
import _ from 'lodash';
import currency from "utils/currency"
import { uniqueId } from 'lodash';
export const ExtrasTable = ({extras, onDelete}) => {

  const total = _.sumBy(extras, (v) => parseFloat(v.quantity) * parseFloat(v.price));

  return(
    <Table sx={{border:1, borderColor:"#f2f2f2",mt:1}}>
      <TableBody>
        <TableRow><TableCell colSpan={4}>ADDED ITEMS: {extras.length}</TableCell></TableRow>
        { extras.map((extra, index) => <TableRow key={extra.uid}>
                                        <TableCell>{extra.description}</TableCell>
                                        <TableCell>{extra.quantity}</TableCell>
                                        <TableCell>{extra.price}</TableCell>
                                        { onDelete &&
                                          <TableCell>
                                            <IconButton color="inherit" size="small" onClick={(event) => onDelete(event, extra)}>
                                              <DeleteIcon sx={{color: (theme) => theme.palette.primary.main }}/>
                                            </IconButton>
                                          </TableCell>
                                        }
                                      </TableRow>)
        }
      <TableRow>
        <TableCell colSpan={4}>
          <Typography variant="pgreen">TOTAL: </Typography>
          <Typography>{currency.format(total)}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
    </Table>
  )
}

const ExtraForm = ({editMode, onAction, onBack, onSave, submitLabel, extraItems }) => {

    // update form fields if model changes
    React.useEffect(() => {
      setExtras(extraItems);

    }, [extraItems])

    console.log("Extra Form Render");
    const [extras, setExtras] = useState(extraItems);
    const [formValues, setFormValues] = useState(ExtraModel);
    const [touched, setTouched] = useState(false);
    const [formErrors, setFormErrors] = useState({
      description: "",
      quantity: "",
      price: "",

    });
    
    const onAddToList = () => {
      const validate = validateForm(formValues);
      console.log(validate);
      if(isEmpty(validate)) {

        let extra = { ...formValues };
        extra.uid = uniqueId("E");
        setExtras([...extras, extra]);
        setFormValues(ExtraModel);
        setFormErrors({ description: "", quantity: "", price:""});
      }
      else setFormErrors({ ...validate });     
    }

    const onRemoveFromList = (event,item) => {
      const index = extras.findIndex(function(o){
        return o.uid == item.uid;
      })
      let extrasCopy = [ ...extras ];
      extrasCopy.splice(index,1);
      setExtras(extrasCopy);
      setTouched(true);
    }
    const handleChange = (event) => {

        const numberFields = ['price','quantity'];
        let value = event.target.value;
        if(numberFields.includes(event.target.name)) {
            if (value != "-") value = parseFloat(event.target.value);

            // if field goes to empty, make empty string to avoid uncontrolled input
            if(value == NaN) value="";
        }
        

        setFormValues({
          ...formValues,
          [event.target.name]: value
        });
        setTouched(true);
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.description) errors.description = emptyMsg;
      if(!values.quantity || values.quantity == 0) errors.quantity= emptyMsg;
      if(!values.price || values.price == 0) errors.price = emptyMsg;

      return errors;
    }

    const onFormSubmit = (event) => {
        
       onAction(extras);

    }
    const onSaveForm = () => {
      
        onSave(extras);
        setTouched(false);
    }

    return (
        <>
        <form
        autoComplete="off"

        >
          <Grid
            container
            spacing={3}
          >
          
            <Grid
              item
              sm={4}
              xs={4}
            >
              <TextField
                fullWidth
                label="Item"
                name="description"
                onChange={handleChange}
                required
                value={formValues.description}
                variant="outlined"
                helperText={formErrors.description}
              />
            </Grid>
            <Grid
              item
              sm={2}
              xs={4}
            >
              <TextField
                fullWidth
                label="Quantity"
                name="quantity"
                onChange={handleChange}
                required
                value={formValues.quantity}
                variant="outlined"
                helperText={formErrors.quantity}
              />
            </Grid>
            <Grid
              item
              sm={3}
              xs={4}
            >
              <TextField
                fullWidth
                label="Price"
                name="price"
                onChange={handleChange}
                required
                value={formValues.price}
                variant="outlined"
                helperText={formErrors.price}
              />
            </Grid>
          
            <Grid
              item
              sm={3}
              xs={12}
              sx={{ display:"flex",justifyContent: { xs: "flex-end", sm: "flex-start"}}}
            >
           <Button
              color="primary"
              variant="outlined"
              onClick={onAddToList}
              >
            Add
            </Button>         
            </Grid>           
          </Grid>
        <Box>
          <ExtrasTable extras={extras} onDelete={onRemoveFromList} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent:'flex-end',
            flexDirection: "row",
            columnGap:'10px',
            p: 2
          }}
        >
          { onBack && !touched && 
          <Button

            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          }
          { touched && 
          <Button

            color="primary"
            variant="outlined"
            onClick={onSaveForm}
          >
          Save
          </Button>
          }
          { onAction &&
          <Button

            color="primary"
            variant="outlined"
            onClick={onFormSubmit}
          >
            {submitLabel}
          </Button>
          }

        </Box>

      </form>
    </>
    );

}

export default ExtraForm;