import * as React from 'react';
import {Alert, Box,Card,CardContent, LinearProgress, Typography, Table, TableCell, TableRow, TableBody} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import ExpensesFilters from './ExpensesFilters';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import parseDate from 'utils/parseDate';
import currencyFraction from 'utils/currencyFraction';
import { hidden_md, hidden_xs, hidden_lg } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { ExpensesActions } from './actions';
import { invoicePrinted } from '../utils';
import currency from 'utils/currency';


const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new user' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Desc',

  },
  {
    id: 'supplier',
    disablePadding: false,
    label: 'Supplier',
    hidden : hidden_md,
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Amount',
    parseFunction: currencyFraction.format
  },
  {
    id: 'vat',
    disablePadding: false,
    label: 'VAT',
    hidden : hidden_md,
    parseFunction: currencyFraction.format
  },
  {
    id: 'invoice_printed',
    disablePadding: false,
    label: 'Invoice',
    hidden : hidden_md,
    parseFunction: invoicePrinted
  },
  {
    id: 'ex_date',

    disablePadding: false,
    label: 'Inv date',
    hidden : hidden_md,
    parseFunction: parseDate
  }
];

const ExpenseDrawer = (props) => {

  const { row } = props;
  return (
    <>
    <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>DESCRIPTION</TableCell>
            <TableCell>{row.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TYPE</TableCell>
            <TableCell>{row.ex_type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>INVOICE DATE</TableCell>
            <TableCell>{parseDate(row.ex_date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SUPPLIER</TableCell>
            <TableCell>{row.supplier}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AMOUNT</TableCell>
            <TableCell>{ currencyFraction.format(row.amount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>VAT</TableCell>
            <TableCell>{ currencyFraction.format(row.vat)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PRINTED</TableCell>
            <TableCell>{ row.invoice_printed ? "PRINTED" : "NOT PRINTED YET"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CERATED</TableCell>
            <TableCell>{parseDate(row.created_at)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  </>
  )

}
const ExpensesList = (props) => {

  const { items, withFilters, withDrawer, withActions, onPick } = props;
  const [filteredItems, setFilteredItems] = React.useState(items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log("FILTER LIST");
    setFilteredItems(items);
  },[items]);

  if(items.length === 0) return <LinearProgress />

  return (
    <>
      { withFilters && 
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <ExpensesFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      }
      { filteredItems.length === 0 ?
        (
          <Alert severity="warning">Nothing to show</Alert>
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="users"
                  items={filteredItems}
                  columns={columns}
                  rowActions={withActions ? ExpensesActions(dispatch, navigate, enqueueSnackbar) : false}
                  onPick={onPick}
                  withDrawer
                  renderDrawer={withDrawer ? <ExpenseDrawer /> : <></>}
                  orderColumn={'ex_date'}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
    </>
  );
}

export default ExpensesList;