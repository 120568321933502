import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card, CardContent, Typography, Button, Divider} from '@mui/material'
import { DashboardContainer } from 'views/Dashboard/components';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorageIcon from '@mui/icons-material/Storage';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { SaleModel, AddressModel, CustomerModel, ExtraModel} from 'models';
import { CustomerDetailsTable, ProductsDetailsTable, SaleDetailsTable, AddressDetailsTable } from 'views/Dashboard/shared';
import { selectAllItems, selectCartTotal } from 'features/cart/cartSlice';
import { removeFromCart, addToCart } from 'features/cart/cartSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useMediaQuery, useTheme } from "@mui/material"
import { productAddToStore, selectProductsAvailable, productRemoveFromStore } from 'features/products/productsSlice';
import { AddressForm } from 'views/Dashboard/Addresses/components';
import { SaleForm } from '.';
import { CustomerForm } from 'views/Dashboard/Customers/components';
import { ExtraForm } from 'views/Dashboard/Extras';
import { saleAdded } from 'features/sales/salesSlice';
import { selectNextInvoiceNo } from 'features/sales/salesSlice';
import { uniqueId } from 'lodash';
import CustomerPicker from 'views/Dashboard/Customers/components/CustomerPicker';
import AddressFinder from 'views/Dashboard/Addresses/components/AddressFinder';
import { DistanceDetails } from 'views/Dashboard/Addresses/components/DistanceFinder';
import { nullToString } from 'utils/nullToString';
import { ExtrasTable } from 'views/Dashboard/Extras/components/ExtraForm';
import { reset as ResetCart } from 'features/cart/cartSlice'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sale-tabpanel-${index}`}
      aria-labelledby={`sale-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `sale-tab-${index}`,
    'aria-controls': `sale-tabpanel-${index}`,
   
  };
}

const ShowErrors = (props) => {
  const { errors } = props;
  let errorMsgs = [];

  Object.entries(errors).forEach(entry => {
    const [key, value] = entry;
    if(value) errorMsgs.push(key.toUpperCase() + " : " + value);
  })


  return (
    <>
    { errorMsgs.length > 0 &&
        <Box sx={{ border:1, borderColor:"#f2f2f2"}}>
          <Card sx={{mt:2}}>
              <CardContent>
                {errorMsgs.map(error => <Box key={uniqueId()}><Typography variant="pred">{error}</Typography></Box>)}
              </CardContent>
          </Card>
        </Box>
    }
   </>
  )
}

const CreateSaleForm = () => {

  const [value, setValue] = React.useState(0);
  const [showProductsTable, setShowProductsTable] = React.useState(false);
  const [showAddressFinder, setShowAddressFinder] = React.useState(false);
  const [showDistanceFinder, setShowDistanceFinder] = React.useState(false);
  const [sale, setSale] = React.useState(SaleModel);
  const [billingAddress, setBillingAddress] = React.useState(AddressModel);
  const [showBillingForm, setShowBillingForm] = React.useState(false);
  const [deliveryAddress, setDeliveryAddress] = React.useState(AddressModel);
  const [customer, setCustomer] = React.useState(CustomerModel);
  const [selectedCustomer, setSelectedCustomer] = React.useState(false);
  const [extras, setExtras] = React.useState([]);
  const [completed, setCompleted] = React.useState(false);
  const [errors, setErrors] = React.useState({
    products: null,
    customer: null,
    billing:null,
    delivery:null,
    sale:null,
    extra:null
  });

  const cartItems = useSelector(selectAllItems);
  const cartTotal = useSelector(selectCartTotal);
  const nextInvNo = useSelector(selectNextInvoiceNo);
  const availableProducts = useSelector(selectProductsAvailable);

  React.useEffect(() => {
    setSale({ ...sale, total: cartTotal, invoice_no: nextInvNo});
  },[cartTotal, nextInvNo]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeNext = () => {

    if(cartItems.length < 1) setErrors({ ...errors, products: "No products selected."});
    else {
      
      if(value < 4){
        setValue((prev) => { if((prev+1) == 4) setCompleted(true); return prev + 1;});
        
      }
    }
    

  };

  const handleChangePrev = () => {

    if(cartItems.length < 1) setErrors({ ...errors, products: "No products selected."});
    else {
      if(value > 0) setValue(value - 1);
    }

  };

  const addExtrasToSale = (items) => {
    setExtras(items);
  }
  const pickProducts = () => setShowProductsTable(!showProductsTable);

  const handleRemoveFromCart = (event, product) => {
    event.stopPropagation();
    dispatch(removeFromCart({ id: product.id}));
    dispatch(productAddToStore(product));
  }
  const handleRemoveFromProductsList = (event, product) => {

    event.stopPropagation();
    dispatch(removeFromCart({ id: product.id}));
    dispatch(productAddToStore(product));
  }
  const handleAddToCart = (event, product) => {
    event.stopPropagation();
    dispatch(addToCart(product));
    dispatch(productRemoveFromStore({ id: product.id}));
    setErrors({ ...errors, products: null });
  }

  const handleSaveBillingAddress = (values) => {
    setBillingAddress(values);
  }

  const handleSaveDeliveryAddress = (values) => {
    setDeliveryAddress(values);
    setShowDistanceFinder(true);
  }

  const handleSaveExtras = (values) => {
    setExtras(values);
  }

  const handleCustomerPick = (event, customer) => {
    const { addresses, sales, ...customer_details } = customer;
    const { addressable_id, addressable_type, ...address_details } = addresses[0];
    setCustomer(customer_details);
    setBillingAddress(nullToString(address_details));
    setSelectedCustomer(true);
    console.log(customer_details, address_details);
  }
  const handleBillingAddressPicked = (event,address) => {
    console.log("Billing:", address);

    const addressObj = {
      id: "",
      address_line_1: address[0],
      address_line_2: address[1],
      town: address[3],
      county: address[4],
      postcode: address[5],
      extra_info: ""
    }

    setBillingAddress(addressObj);
    setShowDistanceFinder(true);
    setShowAddressFinder(false);
  }
  const handleDeliveryAddressPicked = (event,address) => {
    console.log("Delivery:", address);
    const addressObj = {
      id: "",
      address_line_1: address[0],
      address_line_2: address[1],
      town: address[3],
      county: address[4],
      postcode: address[5],
      extra_info: ""
    }

    setDeliveryAddress(addressObj);
    setShowDistanceFinder(true);
    setShowAddressFinder(false);
  }
  const handleDeselectCustomer = () => {

    setSelectedCustomer(false);
    setCustomer(CustomerModel);
    setBillingAddress(AddressModel);
    setShowBillingForm(false);
  }
  const handleSubmitSale = () => {
    const data = {

      customer: customer,
      customerChosen: selectedCustomer,
      items: cartItems,
      delivery_address: deliveryAddress,
      invoice_address: billingAddress,
      extras: extras,
      sale: sale

    }

    dispatch(saleAdded(data))
    .then(result => {

        if(result.type === "sales/saleAdded/fulfilled"){
                
            enqueueSnackbar("New sale added successfully");
            dispatch(ResetCart());
            navigate("../");
        }
        else enqueueSnackbar("SALE - " + result.error.message);
    });
  }
  return (
    <DashboardContainer>
        <ShowErrors errors={errors} />
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                
                {isMobile ? (
                  <Tabs value={value} aria-label="Sale Tabs" centered>
                    <Tab icon={<PointOfSaleIcon />} aria-label="Sale Details" sx={{pl:1,pr:1,minWidth:"70px"}}/>
                    <Tab icon={<ContactsIcon />} aria-label="Customers details" sx={{pl:1,pr:1,minWidth:"70px"}} />
                    <Tab icon={<LocalShippingIcon />} aria-label="Delivery Address" sx={{pl:1,pr:1,minWidth:"70px"}} />
                    <Tab icon={<StorageIcon />} aria-label="Extra services" sx={{pl:1,pr:1,minWidth:"70px"}} />
                    <Tab icon={<FactCheckIcon />} aria-label="Confirm details" sx={{pl:1,pr:1,minWidth:"70px"}} />
                  </Tabs>
                  
                  ) : (
                
                  <Tabs value={value} aria-label="Sale Tabs">
                    <Tab label="Sale details" {...a11yProps(0)} />
                    <Tab label="Customer details" {...a11yProps(1)} />
                    <Tab label="Delivery details" {...a11yProps(2)} />
                    <Tab label="Extra services" {...a11yProps(3)} />
                    <Tab label="Confirmation" {...a11yProps(4)} />
                  </Tabs>
                )
                }
                
            </Box>
            
            
            
            <TabPanel value={value} index={0}>
                <Card sx={{mt:2}}>
                  <CardContent>
                    <Typography variant="p">Sale Details</Typography>
                    <Divider sx={{mt:1,mb:3}} />
                    <SaleForm 
                      editMode={false} 
                      model={sale}
                      onAction={(value) => { setSale(value);handleChangeNext()}}
                      onBack={handleChangePrev}
                      submitLabel="Next"
                      />
                  </CardContent>
                </Card>
                { cartItems && 
                <Card sx={{mt:2}}>
                  <CardContent>
                  { cartItems.length > 0 ?
                  (
                    <>
                    <ProductsDetailsTable 
                      products={cartItems}
                      withDrawer={true}
                      onDelete={handleRemoveFromCart}

                      withActions={true}
                      rowColor="#F6FCEF"/>
                    
                    </>
                  ) : (
                    <Box sx={{p:1,border:1, borderColor:"#f2f2f2", mt:1}}>
                      <Typography variant="pred">No products in the cart. Please add products to cart to proceed with sale.</Typography>
                    </Box>
                  )
                  }
                  <Box sx={{mt:1}}>
                      <Button 
                          variant="outlined" 
                          onClick={pickProducts}
                          size={isMobile ? "small" : "medium"}
                          >
                        { showProductsTable ? "Hide products" : "Add products" }
                      </Button>
                  </Box>
                  { availableProducts && showProductsTable &&
                    <Box sx={{ height:"300px", overflowY:"auto"}}>

                      <ProductsDetailsTable 
                        products={availableProducts}
                        withDrawer={true}
                        onPick={handleAddToCart}
                        withActions={true}
                        rowColor="#fff"/>

                    </Box>
                    }
                  </CardContent>
                </Card>
                
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card sx={{mt:2}}>
                  <CardContent>
                  { !showBillingForm &&
                  <>
                  <Typography variant="p">Customer details</Typography>
                  <Divider sx={{mt:1,mb:3}} />

                  <CustomerForm 
                        editMode={true} 
                        model={customer}
                        onAction={(value) => { setCustomer(value);setShowBillingForm(true)}}
                        onBack={handleChangePrev}
                        submitLabel="Next"
                        formDisabled={selectedCustomer}
                    />

                    { !selectedCustomer && <CustomerPicker onPick={handleCustomerPick} /> }

                    { selectedCustomer &&

                      <Button 
                          variant="outlined" 
                          onClick={handleDeselectCustomer} 
                          sx={{ml:1}}
                          size={isMobile ? "small" : "medium"}
                          >
                        Reset Customer
                      </Button>
                    }
                    </>
                    }
                    {showBillingForm &&
                    <>
                    <Box sx={{p:1,border:1, borderColor:"#f2f2f2", mt:1,mb:1}}>
                    
                      <Typography variant="pgreen">Invoice for {customer.name} { customer.surname } </Typography>

                      <Button 
                          variant="outlined" 
                          onClick={() => setShowBillingForm(false)} 
                          size={isMobile ? "small" : "medium"}
                          sx={{ml:2}}
                          >
                        edit
                      </Button>

                    </Box>
                    <Typography variant="p">Billing address</Typography>
                    <Divider sx={{mt:1,mb:3}} />

                    <AddressForm
                        editMode={false} 
                        model={billingAddress}
                        onAction={(value) => { setBillingAddress(value);handleChangeNext()}}
                        onSave={handleSaveBillingAddress}
                        onBack={handleChangePrev}
                        submitLabel="Next"
                        formDisabled={selectedCustomer} />

                    <Box sx={{display:"flex",columnGap:1,rowGap:1,minWidth:"200px", flexWrap:"wrap"}}>
                      { selectedCustomer &&
                        <Button 
                            variant="outlined" 
                            onClick={handleDeselectCustomer}
                            size={isMobile ? "small" : "medium"}
                            >
                          Reset Customer
                        </Button>
                      }
                      { !selectedCustomer &&
                      <Button 
                          variant="outlined" 
                          onClick={() => {setShowAddressFinder(!showAddressFinder)}}
                          size={isMobile ? "small" : "medium"}
                          >
                        { showAddressFinder ? "Hide Address finder" : "Find address"}
                      </Button>
                      }
                    </Box>

                    { showAddressFinder && <AddressFinder onPick={handleBillingAddressPicked}/> }

                    </>
                    }

                  </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card sx={{mt:2}}>
                  <CardContent>
                    <Typography variant="p">Delivery address</Typography>
                    <Divider sx={{mt:1,mb:3}} />

                    <AddressForm
                        editMode={false} 
                        model={deliveryAddress}
                        onAction={(value) => { setDeliveryAddress(value);handleChangeNext()}}
                        onBack={handleChangePrev}
                        onSave={handleSaveDeliveryAddress}
                        submitLabel="Next" />

                    <Box sx={{mt:2,display:"flex",columnGap:1,rowGap:1, width:"100%",flexWrap:"wrap"}}>

                      <Button 
                          variant="outlined" 
                          onClick={() => { setDeliveryAddress({ ...billingAddress });setShowDistanceFinder(true)}}
                          size={isMobile ? "small" : "medium"}
                          >
                        Use Billing 
                      </Button>

                      <Button 
                          variant="outlined" 
                          onClick={() => setShowAddressFinder(!showAddressFinder)} 
                          size={isMobile ? "small" : "medium"}
                          >
                        { showAddressFinder ? "Close address" : "Find address"}
                        
                      </Button>

                      <Button 
                          variant="outlined" 
                          onClick={() => setShowDistanceFinder(!showDistanceFinder)} 
                          size={isMobile ? "small" : "medium"}
                          >
                        { showDistanceFinder ? "Close route" : "Find route"}
                      </Button>

                    </Box>
                    <Box sx={{mt:3}}>
                      { showDistanceFinder && <DistanceDetails fromP="NG322EP" toP={deliveryAddress.postcode} /> }
                      { showAddressFinder && <AddressFinder  onPick={handleDeliveryAddressPicked}/> }
                    </Box>
                  </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <Card sx={{mt:2}}>
                  <CardContent>

                    <ExtraForm
                        extraItems={extras}
                        editMode={false}
                        onAction={(value) => { addExtrasToSale(value);handleChangeNext()}}
                        onBack={handleChangePrev}
                        onSave={handleSaveExtras}
                        submitLabel="Next" />

                  </CardContent>
            </Card>
            </TabPanel>
            <TabPanel value={value} index={4}>
            <Card sx={{mt:2}}>
                <CardContent>

                  <SaleDetailsTable sale={sale} />

                  <ProductsDetailsTable 
                      products={cartItems}
                      withDrawer={true}
                      withActions={false}
                      rowColor="#F6FCEF"/>

                  <ExtrasTable extras={extras} />

                  <CustomerDetailsTable customer={customer} />

                  <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"200px",width:{ "xs": "100%"}}}>
    
                      <AddressDetailsTable address={billingAddress} type="Billing" />
                      <AddressDetailsTable address={deliveryAddress} type="Delivery" />
                      
                  </Box>
                  <Box>
                    <DistanceDetails fromP="NG322EP" toP={deliveryAddress.postcode} />
                  </Box>
                  <Typography variant="pgreen" component="p" sx={{mt:1,mb:2}}>Please check all the details and submit if correct.</Typography>

                  <Box sx={{display:"flex",columnGap:1,rowGap:1,minWidth:"200px", flexWrap:"wrap"}}>

                    <Button 
                        variant="outlined" 
                        onClick={handleChangePrev}
                        size={isMobile ? "small" : "medium"}
                        >
                        Go Back
                    </Button>
                    <Button 
                        variant="outlined" 
                        onClick={handleSubmitSale}
                        size={isMobile ? "small" : "medium"}
                        >
                      Submit sale
                    </Button>

                  </Box>
                </CardContent>
            </Card>
            </TabPanel>
            </Box>            
                    
    </DashboardContainer>
  );
}

export default CreateSaleForm;