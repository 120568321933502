import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { 
    selectCustomersCount, 
    selectCustomersEmailCount,
    selectCustomersMobileCount,
    selectCustomersPhoneCount

    } from "features/customers/customersSlice"
import FigureBox from "../../shared/FigureBox"
const ShowStats = (props) => {

    const allCustomersCount = useSelector(selectCustomersCount);
    const allCustomersEmailCount = useSelector(selectCustomersEmailCount);
    const allCustomersMobileCount = useSelector(selectCustomersMobileCount);
    const allCustomersPhoneCount = useSelector(selectCustomersPhoneCount);

    return(
        <>
        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Customers"
                        figure={allCustomersCount}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Mobiles"
                        figure={allCustomersMobileCount}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total landlines"
                        figure={allCustomersPhoneCount}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Emails"
                        figure={allCustomersEmailCount}
                        avatarColor="green"
                        />
                </Grid>               
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;