import React from 'react';
import { Table, TableBody, TableCell, TableRow, Box, Typography, Button, } from '@mui/material';
import parseDate from 'utils/parseDate';
import { Delete } from '@mui/icons-material';


const Collection = ({model, onDelete}) => {

  return (
    <Box>
          <Table size="small" aria-label="Customer details table" sx={{ minWidth:"200px"}}>
            <TableBody>
                <TableRow>
                  <TableCell component="th" colSpan={2}><Typography variant="h6" gutterBottom component="div">Collection details</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Date</Typography></TableCell>
                  <TableCell>{parseDate(model.collection_date)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Title</Typography></TableCell>
                  <TableCell>{model.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" colSpan={2}><Typography variant="h6" gutterBottom component="div">{model.description}</Typography></TableCell>
                </TableRow>
                
            </TableBody>
          </Table>
          <Box sx={{display:'flex', justifyContent:'flex-end',m:1}}>
            <Button size={'small'} startIcon={<Delete />} onClick={onDelete}>Cancel</Button>
          </Box>
    </Box>
  );
}

export default Collection;