
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CachedIcon from '@mui/icons-material/Cached';
import {Badge,IconButton, Menu, MenuItem, Typography,Box, Divider } from '@mui/material';
import { productsReload } from 'features/products/productsSlice';
import { stockinsReload } from 'features/stockins/stockinsSlice';
import {salesReload } from 'features/sales/salesSlice';
import {addressesReload } from 'features/addresses/addressesSlice';
import {customersReload } from 'features/customers/customersSlice';

const isTrue = (status) => status === "succeeded" ? true : false;

const EntityMenuLoadingStatus = (props) => {

    const { status, onClick, name } = props;

    return (
        <MenuItem onClick={onClick}>
            <Box sx={{ 
                display:'flex',
                justifyContent:'space-between',
                width:'200px'
                }}
                >
                <Typography variant="p">{name}</Typography>
                <Typography variant="p">
                    {isTrue(status) ? "OK" : "FAIL"}
                </Typography>
            </Box>
        </MenuItem>
    )
}

const ReloadEntitiesIcon = () => {
    
    const dispatch = useDispatch();

    const reload = () => {
        dispatch(productsReload());
        dispatch(stockinsReload());
        dispatch(salesReload());
        dispatch(customersReload());
        dispatch(addressesReload());
    }

    return(
        <IconButton color="inherit" size="large" onClick={reload}>      
            <CachedIcon />
        </IconButton>
    )

}

const DashboardNavbarLoadingStatus = (props) => {

    const StockinsStatus = useSelector(state => state.stockins.status);
    const ProductsStatus = useSelector(state => state.products.status);
    const SalesStatus = useSelector(state => state.sales.status);
    const CustomersStatus = useSelector(state => state.customers.status);
    const AddressesStatus = useSelector(state => state.addresses.status);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
  
    const loadedStatus = isTrue(StockinsStatus) 
                            && isTrue(SalesStatus) 
                            && isTrue(ProductsStatus) 
                            && isTrue(CustomersStatus) 
                            && isTrue(AddressesStatus);
    
    return(
        <React.Fragment>
            
            <IconButton color="inherit" size="large" onClick={handleClick}>
                <Badge
                badgeContent={loadedStatus ? "Ok" : "Fail"}
                color={loadedStatus ? "success" : "secondary"}
                variant={loadedStatus ? "" : "dot"}
                title={loadedStatus ? "All data loaded." : "Data not loaded..."}
                >
                <CloudDownloadIcon />
                </Badge>
            </IconButton>
            <Menu
                id="data-loaded-status"
                aria-labelledby="data-loaded-status"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <EntityMenuLoadingStatus status={ProductsStatus} onClick={handleClose} name="Products" />
                <EntityMenuLoadingStatus status={StockinsStatus} onClick={handleClose} name="Stockins" />
                <EntityMenuLoadingStatus status={SalesStatus} onClick={handleClose}  name="Sales" />
                <EntityMenuLoadingStatus status={CustomersStatus} onClick={handleClose}  name="Customers" />
                <EntityMenuLoadingStatus status={AddressesStatus} onClick={handleClose}  name="Addresses" />
                <Divider/>
                <ReloadEntitiesIcon />
            </Menu>
        </React.Fragment> 
    )


}

export default DashboardNavbarLoadingStatus;