import Expenses from './Expenses';
import AuthRole from 'layouts/Dashboard/AuthRole';
import {CreateExpenseForm, EditExpenseForm } from './components';

const routes = [

  { path: 'expenses', element: <AuthRole role="administrator" isPage><Expenses /></AuthRole>},
  { path: 'expenses/create', element: <AuthRole role="administrator" isPage><CreateExpenseForm /></AuthRole>},
  { path: 'expenses/edit/:id', element: <AuthRole role="administrator" isPage><EditExpenseForm /></AuthRole>}

]

  export default routes;