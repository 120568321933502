import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography
} from '@mui/material';

import { isEmpty } from 'underscore';


const CustomerForm = ({editMode, model, onAction, onBack, formDisabled }) => {

    console.log("Customer Form Render");
    const [formValues, setFormValues] = useState(model);
    const [touched, setTouched] = useState(false);
    const [formErrors, setFormErrors] = useState({
      name: "",
      surname: "",
      mobile: "",
    });

    // update form fields if model changes
    React.useEffect(() => {
      setFormValues(model);

    }, [model])
    
    const handleChange = (event) => {
        
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value
        });
        setTouched(true);
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.name) errors.name = emptyMsg;
      if(!values.surname) errors.surname = emptyMsg;
      if(!values.mobile) errors.mobile = emptyMsg;

      return errors;
    }

    const onFormSubmit = (event) => {
        
        const validate = validateForm(formValues);

        if(isEmpty(validate)) onAction(formValues);
        else setFormErrors({ ...validate });

    }

    return (
        <>
        <form
        autoComplete="off"

        >
          <fieldset disabled={ formDisabled ? "disabled" : false} style={{border:0}}>
          <Grid
            container
            spacing={3}
          >
            { formDisabled &&
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="pred">Editing is disabled.</Typography>
              </Grid>
            }
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={formValues.name}
                variant="outlined"
                helperText={formErrors.name}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Surname"
                name="surname"
                onChange={handleChange}
                required
                value={formValues.surname}
                variant="outlined"
                helperText={formErrors.surname}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                value={formValues.mobile}
                variant="outlined"
                helperText={formErrors.mobile}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                onChange={handleChange}
                required
                value={formValues.phone}
                variant="outlined"
                helperText={formErrors.phone}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={formValues.email}
                variant="outlined"
                helperText={formErrors.email}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Notes"
                name="extra_info"
                onChange={handleChange}
                required
                value={formValues.extra_info}
                variant="outlined"
                
              />
            </Grid>            
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="DB ID"
                name="id"
                disabled
                value={formValues.id}
                variant="outlined"
                helperText="Database reference"
              />
            </Grid>                        
          </Grid>
       
      </fieldset>
      <Box
          sx={{
            display: 'flex',
            justifyContent:'flex-end',
            flexDirection: "row",
            columnGap:'10px',
            p: 2
          }}
        >
          { onBack && !touched &&
          <Button

            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          }

          <Button

            color="primary"
            variant="outlined"
            onClick={onFormSubmit}
          >
            Save
          </Button>


        </Box>
      </form>
    </>
    );

}

export default CustomerForm;