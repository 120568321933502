import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, Box } from '@mui/material';
import currency from 'utils/currency';
import MonthSalesTable from './MonthSalesTable';


export default function SalesMonthsAccordion(props) {

  const { months } = props;

  let sortedMonths = months.slice().sort((a,b) => a.id - b.id);
  return (
    <>
    { sortedMonths && sortedMonths.map(month => (
      <Accordion key={month.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box>
            {month.name}
          </Box>
          <Box marginRight={1}>
            {currency.format(month.total)}
          </Box>
        </Box>
        </AccordionSummary>
        <AccordionDetails>
          <MonthSalesTable month={month} />
        </AccordionDetails>
      </Accordion>
      ))
    }
    { !months && <LinearProgress />}

    </>
  );
}
