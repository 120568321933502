import React from 'react';
import {
  Box,
  Typography, Grid, Card, CardContent, useTheme, useMediaQuery
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import {  selectProductsAvailable } from 'features/products/productsSlice';
import { hasDescOver30 } from 'views/Dashboard/Products/utils';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Modal from 'components/Modal';
import { PostProductOnFacebook, ProductFacebookPostsList } from 'views/Dashboard/Products/components';
import TableRowActions from 'views/Dashboard/shared/TableRowActions';
import { Edit } from '@mui/icons-material';

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
}
function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
}
function isWithinAWeek(momentDate) {
    return momentDate.isAfter(A_WEEK_OLD);
}
function isTwoWeeksOrMore(momentDate) {
    return !isWithinAWeek(momentDate);
}

const ProductDrawer = (props) => {

  const { row, actions } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box sx={{ mt: 1,mb:1,p:1,border:"1px solid #ddd"}}>
        <Box>
          <ProductFacebookPostsList product={row.product} />
        </Box>
          {!isMobile &&
          <Box sx={{display:'flex',justifyContent:'flex-start',ml:2,mt:1,mb:1, columnGap:1}}>

            <TableRowActions row={row.product} actions={actions} />
          </Box>
          }
      </Box>
    </>
  )

}
const FacebookPostsPerProduct = (props) => {

    const productsWithPosts = useSelector(selectProductsAvailable);
    const [showPostToFB, setShowPostToFB] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const navigate = useNavigate();
    const productsWithPostsArray = React.useMemo(
      () => productsWithPosts
          .filter(product => product.published)
          .map((product) => {
            return {
              product: product,
              id: product.id,
              ref: product.reference,
              desc: product.extra_info,
              no_of_facebook_posts: product.facebook_posts.length,
              latest_post: product.facebook_posts.reduce((acc, post) => new Date(post.created_at) > new Date(acc) ? post.created_at : acc, new Date(0).toISOString()),
              m_m: product.make + " " + product.model
            };
    }), [productsWithPosts]);

    const lastWeekPosts = React.useMemo(
      () => productsWithPostsArray.filter((product) => isWithinAWeek(moment(product.latest_post))), 
      [productsWithPostsArray]
    );
    const todayPosts = React.useMemo(
      () => productsWithPostsArray.filter((product) => isToday(moment(product.latest_post))), 
      [productsWithPostsArray]
    );
    const yesterdayPosts = React.useMemo(
      () => productsWithPostsArray.filter((product) => {
        for (let post of product.product.facebook_posts) {
          if (isYesterday(moment(post.created_at))) {
            return true;
          }
        }
        return false;
      }), 
      [productsWithPostsArray]
    );
    const notPostedForAWeek = React.useMemo(
      () => productsWithPostsArray.filter((product) => !isWithinAWeek(moment(product.latest_post))), 
      [productsWithPostsArray]
      );

    const columns = [
      {
        id: 'ref',
        disablePadding: true,
        label: 'Ref',
        show:true,

        
      },
      {
        id: 'm_m',
        disablePadding: true,
        label: 'M&M',
        show:true,
        
      },
      {
        id: 'desc',
        disablePadding: false,
        label: 'Desc',
        show:true,
        parseFunction: hasDescOver30,
      },
      {
        id: 'no_of_facebook_posts',
        disablePadding: false,
        label: 'Total',
        show:true,
        
      },
      {
        id: 'latest_post',
        disablePadding: false,
        label: 'Lastest',
        show:true,
        parseFunction: (value) => new Date(value).getTime() == 0 ? 'Never' : moment(value).fromNow(),
      
      },
    ];

    const actions = [
      {
        id:1,
        name: "Post to FB",
        icon: <CloudUploadIcon fontSize={'small'}  sx={{color: (theme) => theme.palette.primary.main }}/>,
        callback: (event,item) => {
          event.stopPropagation();
          setSelectedProduct(item.product);
          setShowPostToFB(true);
        },
        show: true
  
      },
      {
        id:2,
        name: "Edit",
        icon: <Edit fontSize={'small'}  sx={{color: (theme) => theme.palette.primary.main }}/>,
        callback: (event,item) => {
          event.stopPropagation();
          navigate(`/dashboard/products/edit/${item.id}`);
        },
        show: true
  
      }
  ];

    return (
      <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{mt:2,mb:2}}>
              <CardContent>
                <Typography variant={'h4'}>Needs posting</Typography>
                <Typography variant={'body2'}>Products not posted for more then week</Typography>

                <EntitiesTable
                  items={notPostedForAWeek}
                  columns={columns.filter((column) => column.id !== 'no_of_facebook_posts')}
                  perPage={5}
                  rowActions={actions}
                  withDrawer={true}
                  renderDrawer={<ProductDrawer />}
                  />
              </CardContent>
          </Card>
        
        </Grid>
        <Grid item xs={12} md={6}>
            <Card sx={{mt:2,mb:2}}>
              <CardContent>
                <Typography variant={'h4'}>Todays posts</Typography>
                <Typography variant={'body2'}>Products posted to facebook today</Typography>

                <EntitiesTable
                  items={todayPosts}
                  columns={columns.filter((column) => column.id !== 'no_of_facebook_posts')}
                  perPage={10}
                  />
              </CardContent>
            </Card>
            <Card sx={{mt:2,mb:2}}>
              <CardContent>
                <Typography variant={'h4'}>Yesterday posts</Typography>
                <Typography variant={'body2'}>Products posted to facebook yesterday</Typography>

                <EntitiesTable
                  items={yesterdayPosts}
                  columns={columns.filter((column) => column.id !== 'no_of_facebook_posts')}
                  perPage={10}
                  />
              </CardContent>
          </Card>
        
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{mt:2,mb:2}}>
              <CardContent>
                <Typography variant={'h4'}>This week posts</Typography>
                <Typography variant={'body2'}>Products posted in last 7 days</Typography>

                <EntitiesTable
                  items={lastWeekPosts}
                  columns={columns.filter((column) => column.id !== 'no_of_facebook_posts')}
                  perPage={10}
                  />
              </CardContent>
          </Card>
        
        </Grid>
        <Grid item xs={12}>
          <Card sx={{mt:2,mb:2}}>
            <CardContent>
            <Typography variant={'h4'}>Posts per product</Typography>
                <Typography variant={'body2'}>No of post for each available product</Typography>
                <EntitiesTable
                  items={productsWithPostsArray}
                  columns={columns}
                  perPage={10}
                  rowActions={actions}
                  withDrawer={true}
                  renderDrawer={<ProductDrawer />}
                />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={showPostToFB}
        onClose={() => { setShowPostToFB(false); setSelectedProduct(null) }}
        title={'Post to Facebook'}
        cancelLabel={'Close'}
      >
        { showPostToFB && selectProductsAvailable &&
          <PostProductOnFacebook product={selectedProduct} />
        }
    </Modal>
    </>
    );
};

export default FacebookPostsPerProduct;
