import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Table, TableBody, TableRow, TableCell, Box } from '@mui/material';



const CustomerDetailsTable = (props) => {

    const { customer } = props;

    return (
        <>
            <Table size="small" aria-label="Customer details table" sx={{ minWidth:"200px"}}>
            <TableBody>
                <TableRow>
                <TableCell component="th" colSpan={2}><Typography variant="h6" gutterBottom component="div">CUSTOMER DETAILS</Typography></TableCell>
                </TableRow>
                <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Name</Typography></TableCell>
                <TableCell>{customer.name} {customer.surname}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Mobile</Typography></TableCell>
                <TableCell>{customer.mobile}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Phone</Typography></TableCell>
                <TableCell>{customer.phone || "No landline"}</TableCell>
                </TableRow>
                <TableRow>
                
                <TableCell colSpan={2}>{customer.email || "No Email"}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div" >Company</Typography></TableCell>
                <TableCell>{customer.company}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div" >Extra Info</Typography></TableCell>
                <TableCell>{customer.extra_info}</TableCell>
                </TableRow>
                
            </TableBody>
            </Table>
            <Box sx={{ display:"flex",columnGap: 1}}>
                <Link to={"/dashboard/customers/edit/" + customer.id}>
                    <Button size="small" variant={'outlined'} >
                    Edit
                    </Button>
                </Link>
            </Box>
        </>
    )
}

export default CustomerDetailsTable;