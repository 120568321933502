import * as React from 'react';
import { CustomersNav, CustomersList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllCustomers} from 'features/customers/customersSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';

const Customers = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllCustomers);

  return (

      <DashboardContainer>
        <CustomersNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>
        { statsOpen && <ShowStats />}
        { items.length != 0 ? (

          <CustomersList
            items={items}
            withFilters
            withDrawer
            withActions
          />

        ) : (

          <CenteredCircularProgress />

        )
      }
      </DashboardContainer>

  );


}

export default Customers;