import * as React from 'react'

import { Box, Container } from '@mui/material';

const DashboardContainer = ({ children }) => {

    return(
        <Container maxWidth={false}>
            <Box sx={{ pt: 3 }}>
                { children }
            </Box>
        </Container>
    )


}

export default DashboardContainer;