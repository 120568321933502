import * as React from 'react'
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
const VisitorsMonthlyTotalChart = (props) => {

    const { months } = props;
    
    const data_total = {
        labels: months.map( month => month.name.substr(0,3) ),
        datasets: [
          {
            label: "Monthly total",
            data: months.map( month => month.total ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
          },
    
        ]
    };

    return (
      
        <Line data={data_total} />

    );
}

export default VisitorsMonthlyTotalChart;