import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, Box, TableHead, IconButton, Collapse} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaleDetailsTable } from '.';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SalesDetailsTableRow = (props) => {

    const { sale, onDelete, withDrawer, withActions, rowColor } = props;
    const [open,setOpen] = React.useState(false);

    let columnsNo = 2;
    columnsNo = withDrawer ? ++columnsNo : columnsNo;
    columnsNo = withActions ? ++columnsNo : columnsNo;

    return (
        <>
        <TableRow hover onClick={() => setOpen(!open)} sx={{backgroundColor: rowColor}}>
            { withDrawer &&
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            }
            <TableCell>{sale.invoice_no}</TableCell>
            <TableCell>{ sale.total}</TableCell>
            { withActions &&
            <TableCell >
                <IconButton color="inherit" size="small" onClick={(event) => onDelete(event, sale)}>
                    <DeleteIcon sx={{color: (theme) => theme.palette.primary.main }}/>
                </IconButton>
            </TableCell>
            }
        </TableRow>
        { withDrawer && 
        <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnsNo}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ mt:1,display:'flex',columnGap:2, flexWrap:"wrap"}}>
                        <Box sx={{ flexGrow:1,flexBasis:0,minWidth:"200px",width:{ xs: "100%"}}}>
                            <SaleDetailsTable sale={sale} />
                        </Box>
                    </Box>
                    </Collapse>
                </TableCell>
        </TableRow>
        }
        </>
    )

}

const SalesDetailsTable = (props) => {

    const { sales, onDelete, withDrawer, withActions, withHeader,rowColor } = props;

    return (
        <>
            <Table size="small" aria-label="Sold sales details table" sx={{width:"100%"}}>
            { withHeader &&
            <TableHead>
                <TableRow>
                    {withDrawer && <TableCell></TableCell>}
                    <TableCell>Invoice no</TableCell>
                    <TableCell>Total</TableCell>
                    { withActions && <TableCell></TableCell> }
                </TableRow>
            </TableHead>
            }
            <TableBody>
                
                { sales.map(sale => <SalesDetailsTableRow 
                                            sale={sale} 
                                            onDelete={onDelete} 
                                            key={sale.id}
                                            withDrawer={withDrawer}
                                            withActions={withActions}
                                            rowColor={rowColor} />)}
                
            </TableBody>
            </Table>
         
        </>
    )
}

export default SalesDetailsTable;