import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Autocomplete
} from '@mui/material';

import { isEmpty } from 'underscore';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';

const expensesTypes = ['','Cleaning', 'Caravan parts', 'Lorry', 'Adertising', 'Reimbursment','Tools','Utilities','Other Services','Diesel'];

const ExpenseForm = ({editMode, model, onAction, onBack, formDisabled }) => {

    console.log("Expense Form Render");
    const [formValues, setFormValues] = useState(model);
    const [touched, setTouched] = useState(false);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState(['']);
    const [formErrors, setFormErrors] = useState({
      description: "",
      supplier: "",
      ex_date: "",
      ex_type:"",
      vat: "",
      amount: "",

    });

    const abortCtrl = new AbortController();

    React.useEffect(() => {
  
      const getData = async () => {
        await axios.get(ApiUrl + 'expenses/getOptions',{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) {
              setSupplierOptions(response.data.suppliers);
              setTypeOptions(response.data.types);
            }
  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();
  
      return () => {
        console.log("UNMOUNTING supplier data:");
        abortCtrl.abort();
      }
  
    },[]);
    // update form fields if model changes
    React.useEffect(() => {
      setFormValues(model);

    }, [model])
    
    const handleChange = (event) => {

      const numberFields = ['vat','amount'];
      let value = event.target.value;
        
      setFormValues({
          ...formValues,
          [event.target.name]: value
      });

      setTouched(true);
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.description) errors.description = emptyMsg;
      if(!values.ex_date) errors.ex_date = emptyMsg;
      if(!values.supplier) errors.supplier = emptyMsg;
      if(!values.vat && values.vat != 0) errors.vat = emptyMsg;
      if(!values.amount) errors.amount = emptyMsg;
      if(!values.ex_type) errors.ex_type = emptyMsg;

      if(isNaN(values.amount)) errors.amount = "Needs to be a number";
      if(isNaN(values.vat)) errors.vat = "Needs to be a number";
      return errors;
    }

    const onFormSubmit = (event) => {
        
        const validate = validateForm(formValues);

        if(isEmpty(validate)) onAction(formValues);
        else setFormErrors({ ...validate });

    }

    return (
        <>
        <form
        autoComplete="off"

        >

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="Desc"
                name="description"
                onChange={handleChange}
                required
                value={formValues.description}
                variant="outlined"
                helperText={formErrors.description}
              />
            </Grid>
           
              <Grid
                item
                md={4}
                xs={12}
              >

                <Autocomplete
                  disablePortal
                  freeSolo
                  name="make"
                  label="Make"
                  id="combo-box-demo"
                  options={supplierOptions}
                  value={formValues.supplier}
                  //inputValue={formValues.make}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Supplier" helperText={formErrors.supplier}/>}
                  onChange={(event, value) => setFormValues({...formValues, supplier: value})}
                  onInputChange={(event, value) => setFormValues({...formValues, supplier: value})}
                  
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
               <TextField
                  fullWidth
                  label="Type"
                  name="ex_type"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.ex_type}
                  variant="outlined"
                  helperText={formErrors.ex_type}
                >
                  {typeOptions.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Amount"
                name="amount"
                onChange={handleChange}
                required
                value={formValues.amount}
                variant="outlined"
                helperText={formErrors.amount}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="VAT"
                name="vat"
                onChange={handleChange}
                required
                value={formValues.vat}
                variant="outlined"
                helperText={formErrors.vat}
              />
            </Grid>
          
            <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                    fullWidth
                    label="Inv Date"
                    type="date"
                    name="ex_date"
                    InputLabelProps={{ shrink: true }}
                    value={formValues.ex_date}
                    onChange={handleChange}
                    variant="outlined"
                    helperText={formErrors.ex_date}
                />
              </Grid>
                   
          </Grid>

      </form>
      <Box
            sx={{
              display: 'flex',
              justifyContent:'flex-end',
              flexDirection: "row",
              columnGap:'10px',
              p: 2
            }}
          >

            <Button
  
              color="primary"
              variant="contained"
              onClick={onFormSubmit}
            >
              Save
            </Button>
            <Button
  
              color="primary"
              variant="contained"
 
            >
              Cancel
            </Button>

          </Box>
    </>
    );

}

export default ExpenseForm;