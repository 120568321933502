import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'


import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  sendSaleMail: {
    status:'idle',
    loading:false,
    error:null,

  },
}


export const fetchSales = createAsyncThunk('sales/fetchSales', async () => {

  const response = await axios.get(ApiUrl + "sales");
  return response.data

})

export const saleAdded = createAsyncThunk('sales/saleAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "sales", payload);
  return response.data

})

export const saleUpdated = createAsyncThunk('sales/saleUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "sales/" + payload.id, payload);
  return response.data

})

export const saleDeleted = createAsyncThunk('sales/saleDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "sales/" + payload.id, payload);
  return response.data

})

export const saleMailSent = createAsyncThunk('sales/saleMailSent', async (payload,thunkAPI) => {

  const response = await axios.post(ApiUrl + 'sales/sendMail', payload);

  return response.data;

});

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    salesReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH SALES
        .addCase(fetchSales.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchSales.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Sales to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchSales.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE SALE
        .addCase(saleAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(saleAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Sales to the array
          state.items.push(action.payload);
        })
        .addCase(saleAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE SALE
        .addCase(saleUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(saleUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(saleUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })

        // DELETE SALE
        .addCase(saleDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(saleDeleted.fulfilled, (state, action) => {
          console.log(action);
         const index = state.items.findIndex(function(o){
            return o.id == action.payload.sale_id;
          })
          console.log("INDEX ",index);
          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(saleDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })
        // Mail sale document
        .addCase(saleMailSent.pending, (state, action) => {

          state.sendSaleMail.error = null;
          state.sendSaleMail.loading = true;
          state.sendSaleMail.status = 'inprogress';
        })
        .addCase(saleMailSent.fulfilled, (state, action) => {
          console.log('MAIL FULFILLED');
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          });

          index != -1 ? state.items[index] = action.payload : state.sendSaleMail.error = 'Order does\'t exist locally';
          state.sendSaleMail.loading = false;

        })
        .addCase(saleMailSent.rejected, (state, action) => {
          state.sendSaleMail.loading = false;
          state.sendSaleMail.error = action.error.message;
          state.sendSaleMail.status = 'fail';

        })
  }
})

export const { salesReload, reset } = salesSlice.actions

export const selectAllSales = state => state.sales.items;
export const selectSalesCount = state => state.sales.items.length;

export const selectLatestSales = state => state.sales.items.slice().sort((a,b) => b.id - a.id).slice(0,5);

export const selectNextInvoiceNo = state => {

    let lastNo = { invoice_no: 0 };

    if(state.sales.items.length > 0){
      lastNo = state.sales.items.reduce((prev, current) => (prev.id > current.id) ? prev : current) 
    }
    return parseInt(lastNo.invoice_no) + 1;
  }

export const selectSaleById = (state, saleId) => {

    let foundItem = null;
    if(state.sales.items.length > 0) {
  
      foundItem = state.sales.items.find(sale => sale.id == saleId);
  
      foundItem = foundItem ? nullToString(foundItem) : undefined;
      
    }
    return foundItem;
  }

  export const selectTotalOwned = (state, saleId) => {
    let total = 0;
    let not_paid = state.sales.items.filter(sale => sale.balance_paid == false);
    if(not_paid.length > 0){
      
      not_paid.forEach(sale => {
        total += parseFloat(sale.total) - parseFloat(sale.deposit)
      });
    }
    return total;
  }

export default salesSlice.reducer;