import * as React from 'react';
import { useSelector } from 'react-redux'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableRow, LinearProgress, Box, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { hidden_md, hidden_xs } from 'views/Dashboard/shared/EntitiesTable/cell_visibility';
import { ApiUrl } from 'features/api_url';

const DistanceTable = (props) => {

    const { distance } = props;

    return(
        <>
        { distance &&
        <Box>
            <Table sx={{ border:1, borderColor:"#f2f2f2",mt:1}}>
                <TableRow><TableCell><strong>From: </strong> </TableCell><TableCell>{ distance.from }</TableCell></TableRow>
                <TableRow><TableCell><strong>To: </strong></TableCell><TableCell>{ distance.to }</TableCell></TableRow>
                <TableRow><TableCell><strong>Distance: </strong></TableCell><TableCell>{ parseInt(parseFloat(distance.distance)/1.61) } miles</TableCell></TableRow>
                <TableRow><TableCell><strong>Duration</strong></TableCell><TableCell>{ distance.time }</TableCell></TableRow>
                <TableRow><TableCell><strong>Route: </strong></TableCell><TableCell>{ distance.summary }</TableCell></TableRow>
            </Table>
        </Box>
        }
        </>
    )
}
const DistanceFinder = (props) => {

    const { fromPostcode, toPostcode } = props;

    React.useEffect(() => {
        
        if(fromPostcode.length < 0) setFromTo({ ...fromTo, from: fromPostcode });
        if(toPostcode.length < 0) setFromTo({ ...fromTo, to: toPostcode });

    },[fromPostcode, toPostcode]);

    const [distance, setDistance] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [fromTo, setFromTo] = React.useState({ from: "", to: ""});


    const handleChange = ({target}) => setFromTo({ ...fromTo, [target.name]: target.value });

    const handleGetDistance = () => {

        if(fromTo.from.length < 4 || fromTo.to.length < 4){
            setError("Invalid postcodes");
            return false;
        }

        const params = {
            fromPostcode: fromTo.from,
            toPostcode: fromTo.to
        }
        axios.get(ApiUrl + "getDistance", params)
            .then(res => {
                console.log(res);
                setDistance(res.data);
                setError(null);

            })
            .catch(err => {
                setError(err.message);
            });
    }
    return (
        <>
         <Grid
            container
            spacing={3}
          >
          
            <Grid
              item
              sm={3}
              xs={4}
            >
              <TextField
                fullWidth
                label="From"
                name="from"
                onChange={handleChange}
                required
                value={fromTo.from}
                variant="outlined"

              />
            </Grid>
            <Grid
              item
              sm={2}
              xs={4}
            >
              <TextField
                fullWidth
                label="To"
                name="to"
                onChange={handleChange}
                required
                value={fromTo.to}
                variant="outlined"
              />
            </Grid>
        
            <Grid
                item
                sm={2}
                xs={4}
                >
                <Button onClick={handleGetDistance}>GetDistance</Button>
            </Grid>
        </Grid>
        <Box>
        { error &&
            <Typography variant="pred" component="p">{ error }</Typography>
        }
        { distance &&
            <DistanceTable distance={distance} />
        }
        </Box>
        </>
    )
}
const AddressesResults = (props) => {
    const { addresses, onPick, postcode } = props;
    const [picked, setPicked] = React.useState(false);

    return (
        <Table sx={{border:1,borderColor:"#f2f2f2",mt:1}}>
            <TableBody>
            { addresses.map((address,index) => 
            <TableRow key={index}>
                <TableCell>{ address.formatted_address[0] }</TableCell>
                <TableCell sx={hidden_xs}>{ address.formatted_address[1] }</TableCell>
                <TableCell sx={hidden_md}>{ address.formatted_address[3] }</TableCell>
                <TableCell sx={hidden_md}>{ address.formatted_address[4] }</TableCell>
                <TableCell sx={hidden_xs}>{ postcode }</TableCell>
                <TableCell>
                    <IconButton color="inherit" size="small" onClick={(event) => onPick(event, [ ...address.formatted_address, postcode])}>
                        <AddCircleIcon sx={{color: (theme) => theme.palette.primary.main }}/>
                    </IconButton>
                </TableCell>
            </TableRow>
            )}
            </TableBody>
        </Table>
  
    )
}

const AddressFinder = (props) => {

    const { onPick } = props;
    const [showList, setShowList] = React.useState(false);
    const [postcode, setPostcode] = React.useState("");
    const [addresses, setAddresses] = React.useState([]);
    const [loading,setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleFindAddresses = (event,postcode) => {
        let api_link = "https://api.getaddress.io/find/" 
                        + postcode + "?api-key=kIIDty68wUO1U4XVawgSGA20106&format=true&expand=true"
        setLoading(true);
        axios.get(api_link)
            .then(res => {
                console.log(res);
                setAddresses(res.data.addresses)
                setLoading(false);
                setShowList(true);
                setError(null);

            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
                
    }

    const handleChange = ({target}) => {
        setShowList(false);
        setPostcode(target.value);
    }

    const handleOnPick = (event,address) => {

        console.log(address);
        setShowList(false);
        setPostcode("");
        onPick(event,address);
    };
    
    return (
            <>
            <Grid
                container
                spacing={1}
                sx={{mt:1}}
            >
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        fullWidth
                        label="Postcode"
                        name="postcode"
                        onChange={handleChange}
                        required
                        value={postcode}
                        variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <Button variant="outlined" onClick={(event) => handleFindAddresses(event,postcode)}>
                        Search
                    </Button>
                </Grid>
            </Grid>
            { loading && <LinearProgress /> }
            {(showList && !error) &&
                <AddressesResults addresses={addresses} postcode={postcode} onPick={handleOnPick}/>
            }
            { error &&
                <Typography variant="pred" component="p" sx={{mt:1}}>{ error }</Typography>
            }
            </>
    )
}


export default AddressFinder;