import { CountertopsOutlined } from "@mui/icons-material";

export const nullToString = (o) => {

    let new_o = {}
    for (const [key, value] of Object.entries(o)) {
    new_o[key] = value == null ? "" : value;
    
  }
  return new_o;
}