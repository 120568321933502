import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Switch,

} from '@mui/material';

import { isEmpty } from 'underscore';


const SaleForm = ({editMode, model, onAction, onBack, submitLabel }) => {

    // update form fields if model changes
    React.useEffect(() => {
      setFormValues(model);

    }, [model])

    console.log("Sale Form Render");
    const [formValues, setFormValues] = useState(model);
    const [formErrors, setFormErrors] = useState({
      invoice_no: "",
      total: "",
      deposit: "",

    });
    
    const handleChange = (event, newValue) => {

        const numberFields = ['deposit','total'];

        let value = event.target.name == "balance_paid" || event.target.name == "deposit_paid" ? event.target.checked : event.target.value;
      
        if(numberFields.includes(event.target.name)) {
            value = parseFloat(event.target.value);

            // if field goes to empty, make empty string to avoid uncontrolled input
            if(!value && value != 0) value="";
        }
        

        setFormValues({
          ...formValues,
          [event.target.name]: value
        });
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.invoice_no) errors.invoice_no = emptyMsg;
      if(!values.total) errors.total= emptyMsg;
      if(!values.deposit && values.deposit != 0) errors.deposit = emptyMsg;

      return errors;
    }

    const onFormSubmit = (event) => {
        
        const validate = validateForm(formValues);
        console.log(validate);
        if(isEmpty(validate)) onAction(formValues);
        else setFormErrors({ ...validate });

    }

    return (
        <>
        <form
        autoComplete="off"

        >
          <Grid
            container
            spacing={3}
          >
          
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="Invoice Number"
                name="invoice_no"
                onChange={handleChange}
                required
                value={formValues.invoice_no}
                variant="outlined"
                helperText={formErrors.invoice_no}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={4}
            >
              <TextField
                fullWidth
                label="Deposit"
                name="deposit"
                onChange={handleChange}
                required
                value={formValues.deposit}
                variant="outlined"
                helperText={formErrors.deposit}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={4}
            >
              <TextField
                fullWidth
                label="Total"
                name="total"
                onChange={handleChange}
                required
                value={formValues.total}
                variant="outlined"
                helperText={formErrors.total}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={4}
            >
              <TextField
                fullWidth
                label="Notes"
                name="extra_info"
                onChange={handleChange}
                required
                value={formValues.extra_info}
                variant="outlined"
                
              />
            </Grid>            
          
            <Grid 
              item
              xs={6}
              md={3}
            >
              <FormControlLabel
                control={<Switch checked={formValues.deposit_paid} onChange={handleChange} name="deposit_paid" />}
                label="Deposit Payment"
              />
            </Grid>
            <Grid 
              item
              xs={6}
              md={3}
            >
              <FormControlLabel
                control={<Switch checked={formValues.balance_paid} onChange={handleChange} name="balance_paid" />}
                label="Balance Payment"
              />
            </Grid>
          
              
          </Grid>

        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent:'flex-end',
            flexDirection: "row",
            columnGap:'10px',
            p: 2
          }}
        >
          { onBack && 
          <Button

            color="primary"
            variant="contained"
            onClick={onBack}
          >
            Back
          </Button>
          }
          <Button

            color="primary"
            variant="contained"
            onClick={onFormSubmit}
          >
            {submitLabel}
          </Button>
         

        </Box>

      </form>
    </>
    );

}

export default SaleForm;