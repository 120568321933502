import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

export const handleEditItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  navigate("/dashboard/customers/edit/" + item.id)
}

export const handlePrintItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {}

export const CustomerActions = (dispatch, navigate, enqueueSnackbar) => [
  {
  id:1,
  name: "Edit",
  icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
  callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
  show: true

  },
  {
  id:3,
  name: "Print",
  icon: <LocalPrintshopIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
  callback: handlePrintItem(dispatch, navigate, enqueueSnackbar),
  show: true
  }
  ];