
import * as React from 'react';
import { Grid, Button, TextField, Typography} from '@mui/material'
import { useMediaQuery, useTheme } from "@mui/material"

const DistanceForm = (props) => {

    const { onAction } = props;

    const [fromToCtrl, setFromToCtrl] = React.useState({ from: "", to: "" });
    const [error, setError] = React.useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = ({target}) => setFromToCtrl({ ...fromToCtrl, [target.name]: target.value });

    const handleSubmit = () => {

        if(fromToCtrl.from.length > 5 && fromToCtrl.to.length > 5){
            onAction(fromToCtrl);
            setError(null);
        } else {
            setError("Wrong postcode format.");
        }
        
    }

    return (
        <>
         <Grid
            container
            spacing={3}
          >
          
            <Grid
              item
              sm={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="From"
                name="from"
                onChange={handleChange}
                required
                value={fromToCtrl.from}
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid
              item
              sm={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="To"
                name="to"
                onChange={handleChange}
                required
                value={fromToCtrl.to}
                variant="outlined"
                type="text"
              />
            </Grid>
        
            <Grid
                item
                sm={4}
                xs={12}
                >
                <Button 
                    variant="outlined" 
                    onClick={handleSubmit}
                    fullWidth={isMobile ? true : false}
                    >
                  Get Directions
                </Button>
            </Grid>
        </Grid>
        <Typography variant="pred" component="p" sx={{ mt:1 }}>{error}</Typography>
        </>
    )
}

export default DistanceForm;