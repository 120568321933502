export const makeOptions = ['','Willerby','Atlas','Abi','Pemberton','Cosalt','Tudor','Delta','Swift','BK','Lodge','Westbrook','Brentmere'];

export const condition = [
    {
      value: 'Excellent',
      label: 'Excellent'
    },
    {
      value: 'Good',
      label: 'Good'
    },
    {
      value: 'Average',
      label: 'Average'
    },
    {
        value: 'Needs Work',
        label: 'Needs Work'
      },
  ];

  export const color = [
    {
      value: 'Beige',
      label: 'Beige'
    },
    {
      value: 'Dark green',
      label: 'Dark green'
    },
    {
      value: 'Light green',
      label: 'Light green'
    },
    {
        value: 'Brown',
        label: 'Brown'
    },
    {
        value: 'Multicolor',
        label: 'Multicolor'
    },
  ];
  
  export const payment_method = [
    {
      value: 'BACS',
      label: 'BACS'
    },
    {
      value: 'Cash',
      label: 'Cash'
    },
    {
      value: 'Transfer',
      label: 'Transfer'
    }
  ]