import * as React from 'react';
import {Grid, TextField} from '@mui/material';

import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const ExpensesFilters = (props) => {

    const {
        items,
        onChange
        
    } = props;

    const [search, setSearch] = React.useState("");
    const [expenseType, setExpenseType] = React.useState("All");
    const [expenseSupplier, setExpenseSupplier] = React.useState("All");

    const [supplierOptions, setSupplierOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);

    const abortCtrl = new AbortController();

    React.useEffect(() => {
  
      const getData = async () => {
        await axios.get(ApiUrl + 'expenses/getOptions',{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) {
              setSupplierOptions(['All',...response.data.suppliers]);
              setTypeOptions(['All',...response.data.types]);
            }
  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();
  
      return () => {
        console.log("UNMOUNTING supplier data:");
        abortCtrl.abort();
      }
  
    },[]);
    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search, expenseSupplier, expenseType]);

  
    const handleSearch = (value) => setSearch(value);
    const handleSupplierChange = (event) => setExpenseSupplier(event.target.value);
    const handleTypeChange = (event) => setExpenseType(event.target.value);

    const filterSearch = (list) => searchObjects(list, search);

    const filterType = (list) => {

        if(expenseType == 'All') return list;

        return list.filter(item => item.ex_type == expenseType);
    }

    const filterSupplier = (list) => {

        if(expenseSupplier == 'All') return list;

        return list.filter(item => item.supplier == expenseSupplier);
    }

    const onFilterItems = () => {
        
        let filteredItems = items;

        filteredItems = filterSearch(filteredItems);
        filteredItems = filterType(filteredItems);
        filteredItems = filterSupplier(filteredItems);
        onChange(filteredItems);

    }

    return (
        <Grid
        container
        spacing={3}
        >
            <Grid
            item
            md={3}
            xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
            <Grid
                item
                md={4}
                xs={12}
              >
               <TextField
                  fullWidth
                  label="Type"
                  name="ex_type"
                  onChange={handleTypeChange}
                  value={expenseType}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {typeOptions.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
               <TextField
                  fullWidth
                  label="Supplier"
                  name="supplier"
                  onChange={handleSupplierChange}
                  select
                  SelectProps={{ native: true }}
                  value={expenseSupplier}
                  variant="outlined"

                >
                  {supplierOptions.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </Grid>
        </Grid>

    )

}

export default ExpensesFilters;