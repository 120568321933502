import { Navigate } from 'react-router-dom';
import SellCaravanPage from './pages/SellCaravanPage';
import Login from './pages/Login';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import ProductsPage from './pages/ProductsPage';

import DashboardLayout from './layouts/Dashboard';
import PrintLayout from './layouts/PrintLayout'
import Main from './layouts/Main';

// DASHBOARD VIEWS
import {Home as DashboardHome} from './views/Dashboard';
import {Account as DashboardAccount} from './views/Dashboard';
import Facebook from 'views/Dashboard/Facebook';
import {Products as DashboardProducts} from './views/Dashboard';
import {Customers as DashboardCustomers} from './views/Dashboard';
import { StockinsRoutes } from './views/Dashboard/Stockins';
import { ProductsRoutes } from 'views/Dashboard/Products';
import { SalesRoutes } from 'views/Dashboard/Sales';
import { CustomersRoutes } from 'views/Dashboard/Customers';
import { StockinInvoice } from 'views/Dashboard/Stockins/components';
import { SaleInvoice } from 'views/Dashboard/Sales/components';
import { AddressesRoutes } from 'views/Dashboard/Addresses';
import {DistanceRoutes } from 'views/Dashboard/Distance';
import { UsersRoutes } from 'views/Dashboard/Users';
import {SettingsRoutes } from 'views/Dashboard/Settings';
import {StatisticsRoutes } from 'views/Dashboard/Statistics';
import {ExpensesRoutes } from 'views/Dashboard/Expenses';
import {EnquiresRoutes } from 'views/Dashboard/Enquires';
import {SubscriptionsRoutes } from 'views/Dashboard/Subscriptions';
import {EventsRoutes } from 'views/Dashboard/Events';
import AuthContainer from 'layouts/Dashboard/AuthContainter';
import { Home as WebsiteHome } from './views/Website'
import { PrintProductsList } from 'views/Dashboard/Products/components';
import PrintStockinsList from 'views/Dashboard/Stockins/components/PrintStockinsList';
import PrintExpensesList from 'views/Dashboard/Expenses/components/PrintExpensesList';
const routes = [
  {
    path: '/',
    element: <Main />,
    children: [
      { path: 'home', element: <WebsiteHome /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/home" /> },
      { path: '*', element: <Navigate to="/404" /> },
      

    ]
  },
  {
    path: 'dashboard',
    element: <AuthContainer><DashboardLayout /></AuthContainer>,
    children: [
      { path: 'home', element: <DashboardHome /> },
      { path: 'account', element: <DashboardAccount /> },
      { path: 'facebook-stats', element: <Facebook /> },
      ...ProductsRoutes,
      ...CustomersRoutes,
      ...StockinsRoutes,
      ...SalesRoutes,
      ...AddressesRoutes,
      ...DistanceRoutes,
      ...UsersRoutes,
      ...SettingsRoutes,
      ...StatisticsRoutes,
      ...ExpensesRoutes,
      ...EnquiresRoutes,
      ...SubscriptionsRoutes,
      ...EventsRoutes,
      { path: '', element: <Navigate to="home" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'print',
    element: <PrintLayout />,
    children: [
      { path: 'stockinsList', element: <PrintStockinsList /> },
      { path: 'productsList/:id', element: <PrintProductsList /> },
      { path: 'salesList', element: <DashboardProducts /> },
      { path: 'expensesRangeList', element: <PrintExpensesList /> },
      { path: 'stockinInvoice/:id', element: <StockinInvoice /> },
      { path: 'saleInvoice/:id', element: <SaleInvoice /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;