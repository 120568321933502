import React from 'react';
import { Box, Alert, Button } from '@mui/material';
import { AddressDetailsTable, CustomerDetailsTable } from 'views/Dashboard/shared';
import parseDate from 'utils/parseDate';
import { Delete } from '@mui/icons-material';


const Delivery = ({model, onDelete}) => {

  if(!model) return null;

  return (
    <Box>
      <Alert severity={'info'} variant={'outlined'}>{ parseDate(model.deliveries[0].delivery_on) }</Alert>
      <AddressDetailsTable address={model.deliveries[0].addresses[0]} withActions type="Delivery" />
      <CustomerDetailsTable customer={model.customers[0]} />
      <Box sx={{display:'flex', justifyContent:'flex-end',m:1}}>
        <Button size={'small'} startIcon={<Delete />} onClick={onDelete}>Cancel</Button>
      </Box>
    </Box>

  );
}

export default Delivery;