import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { expenseDeleted, expenseMarkedAsPrinted } from 'features/expenses/expensesSlice';

export const handleEditItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  navigate("/dashboard/expenses/edit/" + item.id)
}
  // DELETE Stockin
export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    const confirm = window.confirm("Are you sure ?");

    if(confirm) dispatch(expenseDeleted(item)).then(result => {

      result.type === "expenses/expenseDeleted/fulfilled"
                            ? enqueueSnackbar("Expense deleted successfully")
                            : enqueueSnackbar("Expense - " + result.error.message);
                });
}

export const handleMarkAsPrintedItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

  dispatch(expenseMarkedAsPrinted(item)).then(result => {

    result.type === "expenses/markedAsPrinted/fulfilled"
                      ? enqueueSnackbar("Expense marked as printed successfully")
                      : enqueueSnackbar("Expense - " + result.error.message);
  });
}

export const ExpensesActions = (dispatch, navigate, enqueueSnackbar) => [
  {
    id:1,
    name: "Mark as printed",
    icon: <DoneAllIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleMarkAsPrintedItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
    id:2,
    name: "Edit",
    icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
      id:3,
      name: "Delete",
      icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
      show: true
    },
  ];