import * as React from 'react';
import {Grid} from '@mui/material';

import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';

const CustomersFilters = (props) => {

    const {
        items,
        onChange
        
    } = props;

    const [search, setSearch] = React.useState("");
  
    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search]);

  
    const handleSearch = (value) => setSearch(value);
    const filterSearch = (list) => searchObjects(list, search);
   
    const onFilterItems = () => {
        
        let filteredItems = items;

        filteredItems = filterSearch(filteredItems);
        onChange(filteredItems);

    }

    return (
        <Grid
        container
        spacing={3}
        >
            <Grid
            item
            md={3}
            xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
         
        </Grid>

    )

}

export default CustomersFilters;