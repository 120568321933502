import React from "react";

// Injects the Facebook SDK into the page
const injectFbSDKScript = () => {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_UK/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

const useInitFbSDK = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);

  // Initializes the SDK once the script has been loaded
  // https://developers.facebook.com/docs/javascript/quickstart/#loading

  if(!isInitialized){
    window.fbAsyncInit = function () {
      window.FB.init({
        // Find your App ID on https://developers.facebook.com/apps/
        appId: "362192046567176",
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });

      window.FB.AppEvents.logPageView();
      setIsInitialized(true);
    }
    injectFbSDKScript();
    console.log('FACEBOOK SDK INITIALIZED.')
  };

  return isInitialized;
};

export default useInitFbSDK;