import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,

}


export const fetchLoginHistory = createAsyncThunk('LoginHistory/fetchLoginHistory', async () => {

  const response = await axios.get(ApiUrl + "loginHistory");
  return response.data

})


export const LoginHistorySlice = createSlice({
  name: 'loginHistory',
  initialState,
  reducers: {
    LoginHistoryReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH CUSTOMERS
        .addCase(fetchLoginHistory.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchLoginHistory.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched LoginHistory to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchLoginHistory.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
      

  }
})

export const { LoginHistoryReload, reset } = LoginHistorySlice.actions;

export const selectAllLoginHistory = state => state.loginHistory.items;
export const selectLoginHistoryCount = state => state.loginHistory.items.length;


export default LoginHistorySlice.reducer;