import * as React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@mui/material';
import { selectProductsToPrint } from 'features/expenses/expensesSlice';
import parseDate from 'utils/parseDate';
import currency from 'utils/currency';
import { Box } from '@mui/system';
import { useLocation } from 'react-router';
import { ExpensesList } from '.';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';
import _ from 'lodash';
import currencyFraction from 'utils/currencyFraction';
const PrintExpensesList = (props) => {


    const { state } = useLocation();
    const [expensesList,setExpensesList] = React.useState(null);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalVat, setTotalVat] = React.useState(0);

    const abortCtrl = new AbortController();

    React.useEffect(() => {
  
      const getData = async () => {
        await axios.post(ApiUrl + 'expenses/getExpensesRangeList',state,{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) {
                
                let ta = _.sumBy(response.data, (o) => o.amount);
                let tv = _.sumBy(response.data, (o) => o.vat);

                setTotalAmount(ta);
                setTotalVat(tv);
                setExpensesList(response.data);
                
            }
  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();
  
      return () => {
        console.log("UNMOUNTING expenses list data:");
        abortCtrl.abort();
      }
  
    },[]);

    return (
        <Box sx={{ p:2 }}>
        { expensesList ? (
        <>
        <Box>
            <Typography variant="body2" sx={{ padding:1, border: "1px solid ddd"}}>
                EXPENSES : { expensesList.length }{' '}
                TOTAL EXPENSES : {currencyFraction.format(totalAmount)}{' '}
                TOTAL VAT : {currencyFraction.format(totalVat)}{' '}
            </Typography>
        </Box>
        <Table sx={{border:"1px solid #ddd"}}>
            <TableHead>
                <TableRow sx={{backgroundColor:"#f2f2f2"}}>
                    <TableCell>Description</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Inv date</TableCell>
                    <TableCell>Printed?</TableCell>
                    <TableCell>Created</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {expensesList.map(expense => 
                <TableRow key={expense.id}>
                    <TableCell>{expense.description}</TableCell>
                    <TableCell>{expense.ex_type}</TableCell>
                    <TableCell>{expense.supplier}</TableCell>
                    <TableCell>{currencyFraction.format(expense.amount)}</TableCell>
                    <TableCell>{currencyFraction.format(expense.vat)}</TableCell>
                    <TableCell>{parseDate(expense.ex_date)}</TableCell>
                    <TableCell>{ expense.invoice_printed ? "YES" : "NO" }</TableCell>
                    <TableCell>{parseDate(expense.created_at)}</TableCell>
                </TableRow>
                )}
            </TableBody>
        </Table>
        </>
        ) : (
            <Typography variant="body2">NOTHING TO SHOW</Typography>
        )
        }

        </Box>
    )
}

export default PrintExpensesList;