import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  expense: null,
  items:[],
  status: "idle",
  error:null,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},

}

export const fetchExpenses = createAsyncThunk('expenses/fetchExpenses', async () => {

  const response = await axios.get(ApiUrl + "expenses");
  return response.data

})

export const expenseAdded = createAsyncThunk('expenses/expenseAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "expenses", payload);
  return response.data

})

export const expenseMarkedAsPrinted = createAsyncThunk('expenses/markedAsPrinted', async(payload, thunkAPI) => {

  const response = await axios.post(ApiUrl + "expenses/markAsPrinted",payload);
  return response.data

})

export const expenseUpdated = createAsyncThunk('expenses/expenseUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "expenses/" + payload.id, payload.expense);
  return response.data

})

export const expenseDeleted = createAsyncThunk('expenses/expenseDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "expenses/" + payload.id, payload);
  return response.data

})

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {

    reset: state => initialState

  },
  extraReducers(builder) {
    builder
  
      // FETCH expenses
      .addCase(fetchExpenses.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        // Add any fetched Expenses to the array
        state.items = [];
        state.items = state.items.concat(action.payload.map(item => nullToString(item)));

      })
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // CREATE Expense
      .addCase(expenseAdded.pending, (state, action) => {
        state.creating.status = 'inprogress';
      })
      .addCase(expenseAdded.fulfilled, (state, action) => {
        state.creating.status = 'succeeded';
        state.creating.error = null;
        // Add any fetched Expenses to the array
        state.items.push(action.payload);
      })
      .addCase(expenseAdded.rejected, (state, action) => {
        state.creating.status = 'failed';
        state.creating.error = action.error.message;
      })
      // UPDATE Expense
      .addCase(expenseUpdated.pending, (state, action) => {
        state.editing.status = 'pending';
      })
      .addCase(expenseUpdated.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items[index] = action.payload;
        state.editing.status = 'success';

        // state.items.push(action.payload)
      })
      .addCase(expenseUpdated.rejected, (state, action) => {
        state.editing.status = 'failed';
        state.editing.error = action.error.message;
      })

      // DELETE expense
      .addCase(expenseDeleted.pending, (state, action) => {
        state.deleting.status = 'pending';
      })
      .addCase(expenseDeleted.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items.splice(index,1);
        state.deleting.status = 'success';
        
      })
      .addCase(expenseDeleted.rejected, (state, action) => {
        state.deleting.status = 'failed';
        state.deleting.error = action.error.message;
      })
      // MARK AS PRINTED
      .addCase(expenseMarkedAsPrinted.pending, (state, action) => {
      state.editing.status = 'pending';
      })
      .addCase(expenseMarkedAsPrinted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o) {
              return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';

          // state.items.push(action.payload)
      })
      .addCase(expenseMarkedAsPrinted.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
      })
  }

})

// Action creators are generated for each case reducer function
export const { reset } = expensesSlice.actions;

export const selectAllExpenses = state => state.expenses.items;
export const selectExpensesCount = state => state.expenses.items.length;
export const selectNotPrintedExpensesCount = state => state.expenses.items.filter(item => item.invoice_printed == false).length;
export const selectExpensesTotal = state => state.expenses.items.reduce((prev,next) => prev + next.amount,0);
export const selectExpensesTotalVat = state => state.expenses.items.reduce((prev,next) => prev + next.vat,0);

export const selectExpenseById = (state, expenseId) => {

  let foundItem = null;
  if(state.expenses.items.length > 0) {
    console.log("expense PRESENT");
    foundItem = state.expenses.items.find(expense => expense.id == expenseId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}

export default expensesSlice.reducer;