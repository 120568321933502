import * as React from 'react'
import { Line } from 'react-chartjs-2';

const SalesYearlyTotalChart = (props) => {

    const { years } = props;
    
    const data_total = {
        labels: years.map( year => year.id ),
        datasets: [
          {
            label: "Yearly total",
            data: years.map( year => year.total ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
          },
    
        ]
    };

    return (
        <Line data={data_total} />
    );
}

export default SalesYearlyTotalChart;