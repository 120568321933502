import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel,
  MenuItem,
  Select, Typography, useTheme
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ApiUrl } from 'features/api_url';

const MimeTypes = {
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
  tsv: 'text/tab-separated-values',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  html: 'text/html'
};

const ExportToFile = ({ list, columns, submitLabel, entityType, exportable }) => {


  const downloadRef = React.useRef(null);
  const [fileType, setFileType] = React.useState('');
  const theme = useTheme();
  const [exportColumns, setExportColumns] = React.useState(columns.map(c => ({ id: c.id, show: c.show, label: c.label })));
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [error, setError] = React.useState(null);

  if(!exportable?.exportUrl){
    console.warn('ExportToFile: exportUrl not provided');
    return null;
  }

  const handleChangeType = (type) => {
    setFileType(type);
    setStatus(false);
    setError(null);
    setLoading(false);
    downloadRef.current.removeAttribute('href');
  };

  const atLeastOneChecked = exportColumns.filter(c => c.show).length > 1;

  const onChangeColumns = (event) => {
    const { name, checked } = event.target;
    if(atLeastOneChecked || checked){
      setExportColumns(exportColumns.map(c => c.id == name ? { id: c.id, show: checked, label: c.label } : c));
    }
    else enqueueSnackbar('At least one column must be selected', { variant: 'warning' });
  };

  const handleExport = (value) => {

    if(fileType && list.length > 0) {

      setLoading(true);
      axios.post(
       ApiUrl + exportable.exportUrl, 
        { ids: list.map(item => item.id), 
          columns: exportColumns.filter(c => c.show).map(c => ({ id: c.id, label: c.label })),
          entity: entityType,
          fileType: fileType
        }, 
        { responseType: 'blob' }
      )
        .then((response) => {
          try {
            downloadRef.current.href = URL.createObjectURL(response.data, {type: MimeTypes[fileType]});
            downloadRef.current.setAttribute('download', 'List.' + fileType);
            downloadRef.current.click();
            setStatus(true);
          } catch (e) {
            setError(e.message);
          } finally {
            setLoading(false);
          }

        }).catch((error) => {
          setError(error.message);
          setLoading(false);
        }); 
    }
  };
  
  return (
    <Box>
      <Box sx={{ display:'flex', justifyContent:'flex-start', flexDirection:'row', paddingY:2, columnGap:1 }}>
        <FormControl>
          <InputLabel id="export-to-file-select-label" size={'small'}>Export to...</InputLabel>
          <Select
            labelId="export-to-file-select-label"
            id="export-to-file-select"
            value={fileType}
            size={'small'}
            label="Export to..."
            onChange={(event) => handleChangeType(event.target.value)}
            sx={{ minWidth: 120 }}
          >
            { Object.keys(MimeTypes).map((key) => {
              if(exportable?.formats && !exportable.formats.includes(key)) return null;
              return <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>;
            }
            )}
          </Select>
        </FormControl>
        <Button 
          variant={'contained'} 
          size={'small'}
          startIcon={<DownloadIcon />}
          disabled={!fileType || loading}
          onClick={() => handleExport(fileType)}
        >
          { !loading ? (submitLabel || 'Download') : 'Downloading... Please wait' }
        </Button>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="column-settings-content"
          id="column-settings-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Column settings
          </Typography>
          <Typography variant={'caption'} sx={{ color: 'text.secondary' }}>Adjust look of the exported table</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            { exportColumns.map((column, index) => (
              <Grid item xs={6} md={4} key={column.id}>
                <FormControlLabel 
                  control={
                    <Checkbox 
                      sx={{marginRight:1}}
                      checked={column.show} 
                      onChange={onChangeColumns} 
                      name={column.id} 
                      color={'primary'} />
                  } 
                  label={column.label} 
                  key={column.id}
                />
              </Grid>
            )
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="column-settings-content"
          id="column-settings-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            More settings
          </Typography>
          <Typography variant={'caption'} sx={{ color: 'text.secondary' }}>Some additional settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        </AccordionDetails>
      </Accordion>     
      <Box>
        { fileType &&
          <Typography variant={'caption'} component={'p'} sx={{marginY:2, padding:1}}>
            You can adjust generated document settings like columns and extra data in the tab section above. </Typography>
        }

        <Alert severity={'success'} sx={ downloadRef.current?.href  ? { marginY:2} : visuallyHidden }>
          <Typography variant={'caption'}>File download should start automatically. If not click{' '}
            
          </Typography>
          <Typography 
            component={'a'} 
            ref={downloadRef}

            size={'small'}
            
            variant={'caption'}
          >
            here
          </Typography>
        </Alert>
  
        { error &&
          <Alert severity={'error'} sx={{marginY:2}}>{error}</Alert>
        }
      </Box>
    </Box>
  );
 
  
};

ExportToFile.propTypes = {
  list: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  submitLabel: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  exportable: PropTypes.object.isRequired
};

export default ExportToFile;