import React from 'react';
import {
    NavLink as RouterLink,

  } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,

} from '@mui/material';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';
import { ArrowRightAlt } from '@mui/icons-material';
import currencyFraction from 'utils/currencyFraction';
import ExpensesDatesFilter from './ExpensesDatesFilter';

const ExpensesByType = (props) => {

    const [fromDate, setFromDate] = React.useState(props.fromDate);
    const [toDate, setToDate] = React.useState(props.toDate);

    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalVat, setTotalVat] = React.useState(0);

    const [typesData, setTypesData] = React.useState(null);

    const abortCtrl = new AbortController();

    const onFilter =(dates) => {

        setFromDate(dates.fromDate);
        setToDate(dates.toDate);
    }

    React.useEffect(() => {
  
      const getData = async () => {
        await axios.post(ApiUrl + 'stats/getExpensesTypeData',{ from: fromDate, to: toDate},{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) {
                setTypesData(response.data);
            }
  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();
  
      return () => {
        console.log("UNMOUNTING type data:");
        abortCtrl.abort();
      }
  
    },[fromDate, toDate]);

    React.useEffect(() => {

        if(typesData){

            let ta = typesData.reduce((prev,next) => parseFloat(prev) + parseFloat(next.total),0);
            let tv = typesData.reduce((prev,next) => parseFloat(prev) + parseFloat(next.vat),0);

            setTotalAmount(ta);
            setTotalVat(tv);
        }
    },[typesData]);

    return (
        <Card>
            <CardHeader
                title="Expenses by type"
            />
            <Divider />
            <ExpensesDatesFilter onSubmit={onFilter} fromDate={fromDate} toDate={toDate} />
            <Box sx={{p:2}} >
                Total amount: <strong>{ currencyFraction.format(totalAmount) }</strong>
                {' '}
                Total VAT: <strong>{ currencyFraction.format(totalVat) }</strong> 
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Type
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Vat
                        </TableCell>
                        <TableCell>
                            Orders
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { typesData && typesData.map(type =>
                    <TableRow key={type.ex_type}>
                        <TableCell>{type.ex_type}</TableCell>
                        <TableCell>{currencyFraction.format(type.total)}</TableCell>
                        <TableCell>{currencyFraction.format(type.vat)}</TableCell>
                        <TableCell>{type.noofitems}</TableCell>
                    </TableRow>
                    )}
                    { !typesData &&
                        <TableRow>
                            <TableCell colSpan={4}>No data to show</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <Divider />
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
            >


            </Box>
        </Card>
    );
};

export default ExpensesByType;
