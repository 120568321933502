import * as React from 'react';
import {Box, LinearProgress,Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable';

import { ApiUrl } from 'features/api_url';
import axios from 'axios';
import ExpensesMonthsAccordion from './ExpensesMonthsAccordion';
import currencyFraction from 'utils/currencyFraction';
import parseDate from 'utils/parseDate';
const ExpensesMonthItems = (props) => {

    const { items } = props;

  return (
    <>
      { !items ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

<Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Desc
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Date
                        </TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    { items && items.map(item =>
                    <TableRow key={item.id}>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{currencyFraction.format(item.amount)}</TableCell>
                        <TableCell>{parseDate(item.ex_date)}</TableCell>

                    </TableRow>
                    )}
                    { !items &&
                        <TableRow>
                            <TableCell colSpan={3}>No data to show</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
           
          </Box>
        )}

    </>
  );
}

export default ExpensesMonthItems;