import * as React from 'react';
import {Grid, Box, TextField, Button, IconButton} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';
import { date } from 'yup';

const StatusOptions = [
    { value:'all', label: 'All'}, 
    { value: 'unpaid', label: 'Unpaid'},
    { value: 'not_booked', label: 'Not booked'},
    { value: 'with_extras', label: 'With Extras'}, 
];

const SalesFilters = (props) => {

    const {
        items,
        onChange,
        
    } = props;

    const [search, setSearch] = React.useState("");
    const [dateRange, setDateRange] = React.useState({fromDate: '', toDate: ''})
    const [status, setStatus] = React.useState("all");

    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search,dateRange, status]);

    const filterStatus = (list) => {
        return list.filter((item) => {
            if(status === "all") return true;
            if(status === "unpaid") return !item.balance_paid;
            if(status === "not_booked") return item.deliveries[0] && !item.deliveries[0].delivery_on
            if(status === "with_extras") return item.extras.length > 0;
          
        });
    };
    const handleStatusChange = (event) => setStatus(event.target.value);
    const handleSearch = (value) => setSearch(value);
    const filterSearch = (list) => searchObjects(list, search);
    const filterDateRange = (list) => {
        if(dateRange.fromDate || dateRange.toDate){
            let startDate = dateRange.fromDate ? new Date(dateRange.fromDate) : new Date(0);
            let endDate = dateRange.toDate ? new Date(dateRange.toDate) : new Date();

            return list.filter(a => {
                var date = new Date(a.created_at);
                return (date >= startDate && date <= endDate);
              });
        } else {
            console.log('No dates selected. Returning...');
            return list;
        }
    }
   
    const onFilterItems = () => {
        
        let filteredItems = items;
        filteredItems = filterStatus(filteredItems);
        filteredItems = filterDateRange(filteredItems);
        filteredItems = filterSearch(filteredItems);
        onChange(filteredItems);

    }

    return (
        <Grid
        container
        spacing={3}
        >
            <Grid
                item
                md={3}
                xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
            <Grid
                item
                md={4}
                xs={12}
            >
                <Box display={'flex'} sx={{ columnGap:1}}>
                    <TextField
                        fullWidth
                        label="From date"
                        type="date"
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.fromDate}
                        onChange={({target}) => setDateRange({ ...dateRange, [target.name] : target.value })}
                        variant="outlined"

                    />
                    <TextField
                        fullWidth
                        label="To date"
                        type="date"
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.toDate}
                        onChange={({target}) => setDateRange({ ...dateRange, [target.name] : target.value })}
                        variant="outlined"

                    />
                    <IconButton
                    onClick={() => setDateRange({fromDate:'', toDate:''})}
                >
                    <Close />
                </IconButton>
                </Box>
            </Grid>
            <Grid
                item
                md={2}
                xs={12}
            >
                <TextField
                fullWidth
                label="Status"
                name="status"
                value={status}
                onChange={handleStatusChange}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                >
                {StatusOptions.map((option) => (
                    <option
                    key={option.value}
                    value={option.value}
                    >
                    {option.label}
                    </option>
                ))}
                </TextField>
                
            </Grid>
        </Grid>

    )

}

export default SalesFilters;