import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const StyledDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

StyledDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};

const Modal = (props) => {

  const { 
    open,
    title,
    cancelLabel,
    onClose, 
    children, 
    ...rest } = props;
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <StyledDialog
        onClose={onClose ? onClose : null}
        aria-labelledby='customized-dialog-title'
        open={open}
        {...rest}
        //fullScreen={fullScreen}
      >
        <StyledDialogTitle
          id='customized-dialog-title'
          onClose={onClose}

        >
          {title}
        </StyledDialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
        { cancelLabel && onClose &&
          <DialogActions>
            
            <Button autoFocus onClick={onClose}>
              {cancelLabel}
            </Button>
          </DialogActions>
        }
      </StyledDialog>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string
};

export default Modal;