import Statistics from './Statistics';
import AuthRole from 'layouts/Dashboard/AuthRole';

const routes = [

  { path: 'statistics', element: <AuthRole role="administrator" isPage><Statistics /></AuthRole>},


]

  export default routes;