import * as React from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';


import {
    Table,
    Typography,
    Button,
 
  } from '@mui/material';

const AddressDetailsTable = (props) => {

    const { address, type, withActions } = props;

    return (
        <>
        <Table size="small" aria-label="Address details table" sx={{ minWidth:"200px"}}>
            <TableBody>
           
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">{ type } Address</Typography>
                        { withActions &&
                        <Link to={ "/dashboard/addresses/edit/" + address.id }>
                            <Button size="small" variant="outlined" >
                            Edit
                            </Button>
                        </Link>
                        }
                    </TableCell>
                    <TableCell sx={{textAlign:"right"}}>
                        
                        {address.address_line_1}<br />
                        {address.address_line_2}<br />
                        {address.town}<br />
                        {address.postcode.split(/\s/).join('').toUpperCase()}<br />
                        {address.county}<br />

                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </>
    )

}

export default AddressDetailsTable;