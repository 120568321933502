import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Logo from './Logo';
import DashboardNavbarLoadingStatus from './DashboardNavbarLoadingStatus';
import DashboardNavbarAccountMenu from './DashboardNavbarAccountMenu';
import { selectAllItems, selectItemsCount, selectCartTotal } from 'features/cart/cartSlice';
import { selectNewEnquiresCount } from 'features/enquires/enquiresSlice';
import { selectUser, logout }  from 'features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const cartItems = useSelector(selectAllItems);
  const cartTotal = useSelector(selectCartTotal);
  const newEnquires = useSelector(selectNewEnquiresCount);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCheckout = () => navigate("/dashboard/sales/create");

  console.log('new enq:' , newEnquires);
  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {cartItems.length > 0 &&
        <IconButton color="inherit" size="large" onClick={handleCheckout}>
            <Badge
              badgeContent={cartItems.length}
              color="secondary"
            >
              <ShoppingBasketIcon />
            </Badge>
          </IconButton>
          }
          <RouterLink to="/dashboard/enquires">
            <IconButton size="large" sx={{ color:"white" }} >
              <Badge
                badgeContent={newEnquires}
                color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </RouterLink>
          <DashboardNavbarLoadingStatus />
          <DashboardNavbarAccountMenu />      


        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
