
import EditAddressForm from './components/EditAddressForm';
//import {CreateCustomerForm, EditCustomerForm} from './components';
import AuthRole from 'layouts/Dashboard/AuthRole';

const routes = [

  //{ path: 'customers', element: <Customers />},
  //{ path: 'customers/create', element: <CreateCustomerForm />},
  { path: 'addresses/edit/:id', element: <AuthRole role="moderator" isPage><EditAddressForm /></AuthRole>}

]

  export default routes;