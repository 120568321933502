import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const Published = (status) => {
    return status 
                ? <FiberManualRecordIcon sx={{ color: "green",fontSize:"12px" }}  />
                : <FiberManualRecordIcon sx={{ color: "red",fontSize:"12px" }} />;
}

export const hasImages = (images) => {
    return images?.length > 0 
                ? <FiberManualRecordIcon sx={{ color: "green",fontSize:"12px" }}  />
                : <FiberManualRecordIcon sx={{ color: "red",fontSize:"12px" }} />;
}

export const hasDescOver30 = (desc) => {
    return desc.length > 30
                ? <FiberManualRecordIcon sx={{ color: "green",fontSize:"12px" }}  />
                : <FiberManualRecordIcon sx={{ color: "red",fontSize:"12px" }} />;
}

export const hasFeature = (status) => {
    return status 
                ? <FiberManualRecordIcon sx={{ color: "green",fontSize:"12px" }}  />
                : <FiberManualRecordIcon sx={{ color: "red",fontSize:"12px" }} />;
}