import * as React from 'react';
import { useParams } from 'react-router-dom';
import { CustomerForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert, Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { selectCustomerById, customerUpdated } from 'features/customers/customersSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import parseDate from 'utils/parseDate';

export default function EditCustomerForm(props){

    const contentDiv = useContext(ContentContext);

    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    const id = params.id

    const customer = useSelector((state) => selectCustomerById(state, id));
    const updateStatus = useSelector((state) => state.customers.editing.status);
    const updateError = useSelector((state) => state.customers.editing.error);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    const updateCustomer = c => {
        const payload = { id: c.id, customer: c }
        setIsSaving(true);
        dispatch(customerUpdated(payload)).then(result => {
            if(result.type === "customers/customerUpdated/fulfilled"){
                
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../../");
            }
            if(result.type === "customers/customerUpdated/rejected"){
                ScrollToContentTop(contentDiv);
            }
        });    
    }

    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>
                { customer &&
                <Box sx={{ display:"flex",columnGap: 1 }}>

                    <Link to={"printCustomerDetails/" + customer.id }>
                        <Button sx={{ mx: 1 }} size="small" >
                        Print
                        </Button>
                    </Link>

                </Box>
                }
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { updateError && <Alert severity="error">{updateError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {updateStatus}</Alert>
                </>
            }   
            { customer === undefined && <Alert severity="error">Customer with given ID does not exist.</Alert> }
            { customer &&
            <>
            <Alert variant="outlined" severity="success">DATE : { parseDate(customer.created_at) }</Alert>
            <Card sx={{mt:2}}>
                <CardContent>
                    <CustomerForm 
                        editMode={true} 
                        model={customer}
                        onAction={updateCustomer}
                    />                   
                </CardContent>
            </Card>
           
         
            </>
            }

        </Box>
        </DashboardContainer>
        </>
    )

}

