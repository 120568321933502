import * as React from 'react';
import { EnquiresNav, EnquiresList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllEnquires} from 'features/enquires/enquiresSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';
import { Typography, Alert } from '@mui/material';

const Enquires = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllEnquires);

  return (

      <DashboardContainer>
        <EnquiresNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>

        { statsOpen && <ShowStats />}
        { items && 
        <>
          { items.length != 0 ? (

            <EnquiresList
              items={items}
              withFilters
              withActions
              withDrawer
            />

          ) : (
            <>
            <Alert severity="warning" sx={{mt:1}}>Nothing to show</Alert>
            </>
          )
          }
        </>
        }
      </DashboardContainer>

  );


}

export default Enquires;