import React from 'react';

import {
  Box,
  Button,
  TextField,
  Typography

} from '@mui/material';


const ExpensesDatesFilter = (props) => {

    const [fromDate, setFromDate] = React.useState(props.fromDate);
    const [toDate, setToDate] = React.useState(props.toDate);
    const [error, setError] = React.useState("");
    const { onSubmit } = props;

    const onFilter = () => {

        if(fromDate != "" && toDate != "") {

            onSubmit({ fromDate: fromDate, toDate: toDate });
            setError("");
        }
        else { setError("Wrong date range")}
    }

    return (
        <>
        <Box display={'flex'} sx={{ columnGap:1, p:2}}>
            <TextField
                fullWidth
                label="From date"
                type="date"
                name="ex_date"
                InputLabelProps={{ shrink: true }}
                value={fromDate}
                onChange={({target}) => setFromDate(target.value)}
                variant="outlined"

            />
             <TextField
                fullWidth
                label="To date"
                type="date"
                name="ex_date"
                InputLabelProps={{ shrink: true }}
                value={toDate}
                onChange={({target}) => setToDate(target.value)}
                variant="outlined"

            />
            <Button
                onClick={onFilter}
                variant="contained"
            >
                Filter
            </Button>

        </Box>
        <Box>
            <Typography variant="p">{error}</Typography>
        </Box>
        </>
    )
}

export default ExpensesDatesFilter;
