import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AccountProfile from './components/AccountProfile';
import AccountProfileDetails from './components/AccountProfileDetails';
import { useSelector } from 'react-redux';
import { selectUser, selectUserById } from 'features/users/usersSlice';
import { useState, useEffect } from 'react';

const Account = () => {

  const user = useSelector(selectUser);
  
  return (
  <>
    <Helmet>
      <title>Account | Zimnet</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            { user && 
            <AccountProfile 
                user={user}
                avatarHeight="100px"
                avatarWidth="100px"
                uploadPhotoButton />
            }
            
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >

            { user && <AccountProfileDetails user={user} /> }
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
)
}
export default Account;
