import React from 'react';
import { selectUser, login, logout, setUser } from 'features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router';
import { Button } from "@mui/material";

const AuthContainer = ({children}) => {

    const dispatch = useDispatch();
    const routerLocation = useLocation();
    
    let user = useSelector(selectUser);

    if(!user){
        // check if user has session and update store 
        const sessionUser = sessionStorage.getItem("user");
        if(sessionUser){
            dispatch(setUser(JSON.parse(sessionUser)));
            user = JSON.parse(sessionUser);
        }
    }

    if(!user) return (
        <Navigate
            to="/login"
            replace
            state={{ path: routerLocation.pathname }} />
    )

    return (
        <>
            { user &&  
                <>
                {children}
                </>
            }
        </>
    )
}

export default AuthContainer;