import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@mui/material';

  
  const TotalCustomers = (props) => {
    
    const avatarColor = props?.avatarColor ? props.avatarColor : "primary.main";

    return (
    <Card >
      <CardContent>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid 
            item 
            xs={props.avatarIcon ? 9 : 12}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {props.label}
            </Typography>
            <Typography
              color={avatarColor}
              variant="h5"
            >
              {props.figure}
            </Typography>
          </Grid>
          { props?.avatarIcon &&
            <Grid item xs={3}>
                <Avatar
                sx={{
                    backgroundColor: avatarColor,
                    height: 36,
                    width: 36
                }}
                >
                {props.avatarIcon}
                </Avatar>
            </Grid>
          } 
        </Grid>
 
      </CardContent>
    </Card>
    )
  }
  
  export default TotalCustomers;
  