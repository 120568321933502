import * as React from 'react'
import { Line } from 'react-chartjs-2';

const SalesYearlyQuantityChart = (props) => {

    const { years } = props;
    
    const data_sales_caravans = {
        //labels: ["J", "F", "M", "A", "M", "J",'J','A','S','O','N','D'],
        labels: years.map( year => year.id ),
        datasets: [
        {
            label: "Yearly no. of sales",
            data: years.map( year => year.no_of_sales ),
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
        },
        {
            label: "Yearly no. of caravans",
            data: years.map( year => year.no_of_caravans ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "green",
        },
        ]
    };
    return (
        <Line data={data_sales_caravans} />
    );
}

export default SalesYearlyQuantityChart