import * as React from 'react'
import { Bar } from 'react-chartjs-2';

const SalesMonthlyTotalChart = (props) => {

    const { year } = props;
    
    const data_total = {
        labels: year.months.map( month => month.name.substr(0,3) ),
        datasets: [
          {
            label: "Monthly total",
            data: year.months.map( month => month.total ),
            //fill: true,
            backgroundColor: "rgba(75,192,192,0.5)",
            borderColor: "rgba(75,192,192,1)",
          },
    
        ]
    };

    return (
        <Bar data={data_total} />
    );
}

export default SalesMonthlyTotalChart;