export const checkRole = (user, resourceRole) => {

    if(user?.roles){

        return user.roles.find(role => role.name == resourceRole) ? true : false;
    }
    else {
        return false;
    }
    

}

export const isAdmin = (user) => {

    if(user?.roles){

        return user.roles.find(role => role.name == "administrator") ? true : false;
    }
    else {
        return false;
    }
    

}

export const getRoles = (user) => {
    
    let roles = [];

    if(user?.roles){
        user.roles.forEach(element => {
            roles.push(element.name);
        });
        return roles;
    }
    else return [];
}