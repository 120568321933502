import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
    NavLink as RouterLink,

  } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeImgUrl } from 'features/api_url';
import { useSelector } from 'react-redux';
import { selectLatestProducts } from 'features/products/productsSlice';
import parseDate from 'utils/parseDate';

const LatestProducts = (props) => {

    const latestProducts = useSelector(selectLatestProducts);

    return (
        <Card {...props}>
            <CardHeader
            subtitle={`${latestProducts.length} in total`}
            title="Latest Products"
            />
            <Divider />
            <List>
            {latestProducts && latestProducts.map((product, i) => (
                <ListItem
                divider={i < latestProducts.length - 1}
                key={product.id}
                >
                <ListItemAvatar>
                    <img
                    alt={product.name}
                    src={product.images.length > 0 ? makeImgUrl(product.id,"320",product.images[0].url) : "/static/images/image_placeholder.jpeg"}
                    style={{
                        height: 48,
                        width: 48
                    }}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={product.make + ' ' + product.model}
                    secondary={`Added ${parseDate(product.created_at)}`}
                />
                <IconButton
                    edge="end"
                    size="small"
                    component={RouterLink}
                    to={'/dashboard/products/edit/' + product.id}
                >
                    <ModeEditIcon />
                </IconButton>
                </ListItem>
            ))}
            </List>
            <Divider />
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
            >

            <Button
                color="primary"

                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
                component={RouterLink}
                to="/dashboard/products"
            >
                View all
            </Button>
            </Box>
        </Card>
    );
};

export default LatestProducts;
