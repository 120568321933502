import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function ExpensesNav(props){

    const { onStats, isStatsOpen, onPrint } = props;

    return(
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 1
            }}
      >
        <Box>
          <Link to="..">
            <Button
              sx={{ mr: "auto" }}
              color="success"
              variant="outlined"
              size="small" 
            >
              Back
            </Button>
          </Link>
        </Box>

        <Box sx={{ display:"flex",columnGap: 1 }}>
        <Button size="small" variant="text" onClick={() => onStats(!isStatsOpen)}>{ isStatsOpen ? "Hide stats" : "Show stats" }</Button>
        <Button variant="outlined" size="small" onClick={onPrint}>
              Print
        </Button>
        <Link to="create">
          <Button variant="outlined" size="small" startIcon={<AddCircleOutlineIcon />}>
            Add
          </Button>
        </Link>

        </Box>

      </Box>
    )

}