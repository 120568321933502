import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { 
    selectEnquiresCount,
    selectNewEnquiresCount,
    selectProductEnquiresCount,
    selectSellCaravanEnquiresCount,
    selectContactEnquiresCount

    } from "features/enquires/enquiresSlice";
import FigureBox from "../../shared/FigureBox";

import currencyFraction from "utils/currencyFraction";

const ShowStats = (props) => {

    const allEnquiresCount = useSelector(selectEnquiresCount);
    const enquiresProduct = useSelector(selectProductEnquiresCount);
    const enquiresContact = useSelector(selectContactEnquiresCount);
    const enquiresSellCaravan = useSelector(selectSellCaravanEnquiresCount);
    const enquiresNew = useSelector(selectNewEnquiresCount);

    return(
        <>
        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="New Enquires"
                        figure={enquiresNew}
                        avatarColor="green"
                        />
                </Grid>
               
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Product Enquires"
                        figure={enquiresProduct}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Sale requests"
                        figure={enquiresSellCaravan}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Contact page"
                        figure={enquiresContact}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Enquires"
                        figure={allEnquiresCount}
                        avatarColor="black"
                        />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;