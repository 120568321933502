import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import currency from 'utils/currency';
import { 
    selectProductsCount, 
    selectProductsSoldCount, 
    selectProductsAvailableCount,
    selectProductsTotal,
    selectProductsAvailableTotal,
    selectProductsSoldTotal,

    } from "features/products/productsSlice"
import { selectTotalOwned } from 'features/sales/salesSlice';
import FigureBox from "../../shared/FigureBox"
const ShowStats = (props) => {

    const allProductsCount = useSelector(selectProductsCount);
    const availableProductsCount = useSelector(selectProductsAvailableCount);
    const soldProductsCount = useSelector(selectProductsSoldCount);
    const productsTotal = useSelector(selectProductsTotal);
    const productsAvailableTotal = useSelector(selectProductsAvailableTotal);
    const productsSoldTotal = useSelector(selectProductsSoldTotal);
    const salestotalOwned = useSelector(selectTotalOwned);
    return(
        <>

        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >

                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="In stock"
                        figure={availableProductsCount}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Sold to date"
                        figure={soldProductsCount}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        //avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total worth"
                        figure={currency.format(productsTotal)}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        //avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="In-stock worth"
                        figure={currency.format(productsAvailableTotal)}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        //avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Owned"
                        figure={currency.format(salestotalOwned)}
                        //avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                        //avatarColor="green"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Sold worth"
                        figure={currency.format(productsSoldTotal)}
                       // avatarIcon={<AutoAwesomeMotionOutlinedIcon fontSize="small" />}
                       // avatarColor="green"
                        />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;