  // DELETE PRODUCT
import { addToCart } from 'features/cart/cartSlice';
import { productDeleted, productRemoveFromStore } from 'features/products/productsSlice';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    const confirm = window.confirm("Are you sure ?");
    
    
    if(confirm) dispatch(productDeleted(item)).then(result => {

                  result.type === "products/productDeleted/fulfilled"
                                        ? enqueueSnackbar("Product deleted successfully")
                                        : enqueueSnackbar("PRODUCT - " + result.error.message);
                }); 
  }

// VIEW PRODUCT
export const handleViewItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

  event.stopPropagation();

  navigate("/dashboard/products/view/" + item.id)
}

  // EDIT PRODUCT
export const handleEditItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    navigate("/dashboard/products/edit/" + item.id)
  }

  // ADD TO CART
export const handleAddToCartItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    dispatch(addToCart(item));
    dispatch(productRemoveFromStore(item));

    return;
  }

export const handlePrintItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {}

export const handlePDFItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  event.stopPropagation();

  navigate("/dashboard/products/pdf/" + item.id);
}

export const ProductActions = (dispatch, navigate, enqueueSnackbar) => [
  {
    id:1,
    name: "View",
    icon: <VisibilityIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleViewItem(dispatch, navigate, enqueueSnackbar),
    show: true

    },
    {
    id:2,
    name: "Edit",
    icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
    show: true

    },
    {
    id:3,
    name: "Delete",
    icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
      id:4,
      name: "Print product leaflet",
      icon: <PictureAsPdfIcon sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handlePDFItem(dispatch, navigate, enqueueSnackbar),
      show: true
      },
    {
      id:5,
      name: "Add To Cart",
      icon: <ShoppingCartIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleAddToCartItem(dispatch, navigate, enqueueSnackbar),
      show: true
      },
    ];