import * as React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';



const ProductDetailsTable = (props) => {

    const { product } = props;

    return (
        <>
        <Table size="small" aria-label="stockins" sx={{width:"100%"}}>
        <TableBody>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">M&M</Typography></TableCell>
                <TableCell>{product.make} {product.model}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">SIZE</Typography></TableCell>
                <TableCell>{product.length} x {product.width}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">REF</Typography></TableCell>
                <TableCell>{product.reference}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">COND</Typography></TableCell>
                <TableCell>{product.year} {product.condition}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">COLOR</Typography></TableCell>
                <TableCell>{product.color}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th"><Typography variant="h6" gutterBottom component="div">PRICE</Typography></TableCell>
                <TableCell>{product.price}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div">BEDROOMS</Typography></TableCell>
                <TableCell sx={{ borderBottom:0}}>{product.beds}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div">FEATURES</Typography></TableCell>
                <TableCell sx={{ borderBottom:0}}>{product.dg ? "DG" : "SG"} {product.ch ? "CH" : "EH" } </TableCell>
            </TableRow>            
        </TableBody>
        </Table>
        </>
    )
}

export default ProductDetailsTable;