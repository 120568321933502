import * as React from 'react';
import { useParams } from 'react-router-dom';
import PdfViewer from 'components/PdfViewer';
import { DashboardContainer } from '../components';

export default function ViewPdf(props){

    const { type } = props;
    const params = useParams();

    console.log("PARAMS", params);
    const id = parseInt(params.id);

    return (
      <DashboardContainer>
        <PdfViewer type={type} id={id} />
      </DashboardContainer>
      
    );

}

