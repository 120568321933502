import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import { indigo } from '@mui/material/colors';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useSelector } from 'react-redux';
import currency from 'utils/currency';
import { selectProductsSoldTotal } from "features/products/productsSlice";

const TotalSales = (props) => {

  const totalSales = useSelector(selectProductsSoldTotal);
  return (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            TOTAL SALES
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {currency.format(totalSales)}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  );
}
export default TotalSales;
