import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { orange } from '@mui/material/colors';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import { useSelector} from 'react-redux'
import { selectProductsSoldCount } from 'features/products/productsSlice';
const SoldProducts = (props) => {

  const productsSold = useSelector(selectProductsSoldCount);
  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
     >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            TOTAL SOLD
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            { productsSold }
          </Typography>
        </Grid>

      </Grid>
   
    </CardContent>
  </Card>
  );
}


export default SoldProducts;
