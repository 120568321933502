const User = {
    id: "",
    name: "",
    email: "",
    password: "",
    api_token: "",
    created_at: null,
    updated_at: null
  }
  
  export default User;