import * as React from 'react';
import { useParams } from 'react-router-dom';

import { StockinForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { selectStockinById, stockinUpdated } from 'features/stockins/stockinsSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';

export default function EditStockinForm(props){

    const contentDiv = useContext(ContentContext);

    console.log("EDIT CONTEXT", contentDiv);

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);

    const stockin = useSelector((state) => selectStockinById(state, id));
    const updateStatus = useSelector((state) => state.stockins.editing.status);
    const updateError = useSelector((state) => state.stockins.editing.error);

    console.log("EditForm Render");

    const updateStockin = s => {
        setIsSaving(true);
        dispatch(stockinUpdated(s)).then(result => {
            if(result.type === "stockins/stockinUpdated/fulfilled"){
                console.log("STOCKIN NAVIGATE");
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../..");
            }
            if(result.type === "stockins/stockinUpdated/rejected"){
                ScrollToContentTop(contentDiv);
            }
        });    
        

    }

    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>

                <Box sx={{ display:"flex",columnGap: 1 }}>

                    <Link to="edit/10">
                        <Button sx={{ mx: 1 }} size="small" >
                        PDF
                        </Button>
                    </Link>


                    <Link to="edit/10">
                        <Button variant="outlined" size="small" startIcon={<DeleteIcon />}>
                        Delete
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { updateError && <Alert severity="error">{updateError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {updateStatus}</Alert>
                </>
            }   
            { stockin === undefined && <Alert severity="error">Stock with given ID does not exist.</Alert> }
            { stockin &&
                <StockinForm 
                editMode={true} 
                model={stockin}
                onAction={updateStockin}
                />
            } 
        
            </Box>
        </DashboardContainer>
        </>
    )

}