import React, { useState } from 'react';
import Page from 'components/Page'
import { Outlet } from 'react-router';
import Container from 'components/Container';
import { Box } from '@mui/system';
const PrintLayout = () => {


    return(
        <Page>
            <Box>
                <Outlet />
            </Box>
        </Page>
    )
}

export default PrintLayout;