import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, Box, TableHead, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ExtraSingleForm } from '../Extras/components';

const ProductExtrasDetailsTable = (props) => {

    const { extras, onDelete, onAdd } = props;

    return (
            <>
                { extras && extras.length > 0 ?
                (
                <Table size="small" aria-label="Sold products details table" sx={{width:"100%"}} >

                <TableHead>
                    <TableRow>

                        <TableCell>Name</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                { extras.map(extra => {
                    return (
                        <TableRow key={extra.id}>
                            <TableCell>{extra.description}</TableCell>
                            <TableCell>{extra.quantity}</TableCell>
                            <TableCell>{extra.price}</TableCell>
                            {onDelete &&
                            <TableCell>
                                <IconButton onClick={(event) => onDelete(event, extra)}>
                                    <DeleteIcon sx={{color: (theme) => theme.palette.primary.main, fontSize:17}}/>
                                </IconButton>
                            </TableCell>
                            }
                        </TableRow>
                    )
                })}
                </TableBody>
                </Table>

                ) : <Box sx={{p:1,border:1, borderColor:"#f2f2f2", mt:1}}><Typography variant="pred">No extras with this order</Typography></Box>

            }
            {onAdd &&
            <Box sx={{mt:2}}>
                <ExtraSingleForm
                    onSave={onAdd}
                />
            </Box>
            }
        </>
    )
}

export default ProductExtrasDetailsTable;