import React from 'react';
import { Alert } from '@mui/material';
import { Delivery, Collection, Reminder } from '.';

const EventInfo = ({eventData, onDelete}) => {

  if(!eventData) return <Alert variant={'filled'} severity={'error'}>No event selected</Alert>

  switch (eventData.type) {
    case 'Delivery':
      return <Delivery model={eventData.model} onDelete={onDelete} />
    case 'Collection':
      return <Collection model={eventData.model} onDelete={onDelete} />
    case 'Reminder':
      return <Reminder model={eventData.model} onDelete={onDelete} />
    default:
      return <Alert severity={'error'}>Unknown event type</Alert>
  }
}

export default EventInfo;