import * as React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Chip} from '@mui/material';
import currency from 'utils/currency';
const MonthSalesTable = (props) => {

    const { month } = props;
    return (
        <>
            <Box sx={{ display:'flex', justifyContent:'flex-end',p:2,gap:'10px'}}>
                <Chip label={ "Sales: " + month.no_of_sales} size="small" />
                <Chip label={ "Caravans: " + month.no_of_caravans} size="small" />
            </Box>
            <Table sx={{border:"1px solid #d2d2d2"}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{alignItems:'left'}}>
                            Inv No
                        </TableCell>
                        <TableCell sx={{alignItems:'left'}}>
                            Caravans
                        </TableCell>
                        <TableCell sx={{textAlign:'left'}}>
                            Total
                        </TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                            {month.sales.map(sale => (
                                <TableRow key={sale.id}>
                                    <TableCell>{ sale.invoice_no}</TableCell>
                                    <TableCell sx={{textAlign:'right'}}> { sale.products.length }</TableCell>
                                    <TableCell sx={{textAlign:'right'}}> { currency.format(sale.total) }</TableCell>
                                    
                                </TableRow>
                            ))
                            }

                </TableBody>

            </Table>        
        </>
    );
}

export default MonthSalesTable;