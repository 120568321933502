import {
    NavLink as RouterLink,

  } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeImgUrl } from 'features/api_url';
import { useSelector } from 'react-redux';
import { selectLatestSales } from 'features/sales/salesSlice';
import parseDate from 'utils/parseDate';

const LatestSales = (props) => {

    const latestSales = useSelector(selectLatestSales);

    return (
        <Card {...props}>
            <CardHeader
            subtitle={`${latestSales.length} in total`}
            title="Latest Sales"
            />
            <Divider />
            <List>
            {latestSales && latestSales.map((sale, i) => (
                <ListItem
                divider={i < latestSales.length - 1}
                key={sale.id}
                >
                <ListItemAvatar>
                    <img
                    alt={sale.invoice_no}
                    src={sale.products[0].images.length > 0 ? makeImgUrl(sale.products[0].id,"320",sale.products[0].images[0].url) : "/static/images/image_placeholder.jpeg"}
                    style={{
                        height: 48,
                        width: 48
                    }}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={sale.products[0].make + ' ' + sale.products[0].model}
                    secondary={`Sold on ${parseDate(sale.created_at)}`}
                />
                <IconButton
                    edge="end"
                    size="small"
                    component={RouterLink}
                    to={'/dashboard/sales/edit/' + sale.id}
                >
                    <ModeEditIcon />
                </IconButton>
                </ListItem>
            ))}
            </List>
            <Divider />
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
            >

            <Button
                color="primary"

                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
                component={RouterLink}
                to="/dashboard/sales"
            >
                View all
            </Button>
            </Box>
        </Card>
    );
};

export default LatestSales;
