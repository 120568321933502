import * as React from 'react';

import { ExpenseForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert, Card, CardContent, Typography, Divider} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { expenseAdded } from 'features/expenses/expensesSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import parseDate from 'utils/parseDate';
import { ExpenseModel } from 'models';

export default function CreateExpenseForm(props){

    const contentDiv = useContext(ContentContext);

    console.log("Expense CREATE CONTEXT", contentDiv);

    const { enqueueSnackbar } = useSnackbar();

    const createStatus = useSelector((state) => state.expenses.creating.status);
    const createError = useSelector((state) => state.expenses.creating.error);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    console.log("Expense EditForm Render");

    const createExpense = c => {
        const payload = c;
        setIsSaving(true);
        dispatch(expenseAdded(payload)).then(result => {
            if(result.type === "expenses/expenseAdded/fulfilled"){
                
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../");
            }
            if(result.type === "expenses/expenseAdded/rejected"){
                ScrollToContentTop(contentDiv);
                setIsSaving(false);
            }
        });    
    }

    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>
               
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { createError && <Alert severity="error">{createError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {createStatus}</Alert>
                </>
            }
            <Card sx={{mt:2}}>
                <CardContent>
                    <ExpenseForm 
                        editMode={true} 
                        model={ExpenseModel}
                        onAction={createExpense}
                    />                   
                </CardContent>
            </Card>

        </Box>
        </DashboardContainer>
        </>
    )

}

