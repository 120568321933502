import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Container from '../../../components/Container';

import {

  FeaturedProperties,
  Hero,
  StockGrid

} from './components';

const Home = () => {
  return (
    <>
      <Hero />
      <Box bgcolor={'alternate.main'}>
        <Container>

        </Container>
      </Box>
     
      </>
  );
};

export default Home;
