import * as React from 'react';
import { useSelector } from 'react-redux'
import { selectAllCustomers} from 'features/customers/customersSlice'
import CustomersList from 'views/Dashboard/Customers/components/CustomersList'
import { Button } from '@mui/material'
const CustomerPicker = (props) => {

    const { onPick } = props;
    const [showList, setShowList] = React.useState(false);
    const customers = useSelector(selectAllCustomers);

    const handleOnPick = (event,customer) => {
        setShowList(false);
        onPick(event,customer);
    }
    
    return (
            <>
            <Button variant="outlined" onClick={() => setShowList(!showList)}>
                { showList ? "Cancel" : "Select Customer"}
            </Button>
            {showList &&
            <CustomersList
                items={customers}
                withFilters
                withDrawer
                onPick={handleOnPick}
                />
            }
            </>
    )
}


export default CustomerPicker;