import * as React from 'react';
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { FacebookPostsPerProduct, ShowStats } from './components';

const Facebook = (props) => {
  return (
      <DashboardContainer>
        <ShowStats />
        <FacebookPostsPerProduct />
      </DashboardContainer>

  );


}

export default Facebook;