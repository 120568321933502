import React from 'react';
import Paper from '@mui/material/Paper';

const Image = (props) => {

    const { src, alt, style } = props;
    return(
        <img 
            src={src} 
            alt={alt} 
            style={style}
        />
    )
}

export default Image;