const Stockin = {
    id: "",
    make: "",
    model: "",
    condition: "Excellent",
    length: "",
    width: "",
    no_of_beds: "",
    price: "",
    deposit: "",
    year: "",
    color: "Beige",
    reference: "",
    extra_info: "",
    seller_name: "",
    town: "",
    address: "",
    postcode: "",
    telephone: "",
    diesel_total: "",
    repairs_total: "",
    deposit_payment_method: "BACS",
    balance_payment_method: "BACS",
    balance_paid_on: new Date().toISOString().slice(0, 10),
    deposit_paid_on: new Date().toISOString().slice(0, 10),
    collected: true,

  }

  export default Stockin;