import * as React from 'react';
import {Box,Card,CardContent, LinearProgress, Alert, Button} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from '../../shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import StockinsFilters from './StockinsFilters';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import currency from 'utils/currency'
import parseDate from 'utils/parseDate';
import { hidden_md, hidden_xs, hidden_sm } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import StockinDetailsTable from 'views/Dashboard/shared/StockinDetailsTable';
import StockinSellerDetailsTable from 'views/Dashboard/shared/StockinSellerDetailsTable';
import { StockinActions } from './actions';
import Modal from 'components/Modal';
import ExportToFile from 'views/Dashboard/shared/ExportToFile';
import DownloadCombinedPdf from 'views/Dashboard/shared/DownloadCombinedPdf';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new stockin' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
    {
      id: 'id',

      disablePadding: true,
      label: 'ID',
      hidden : hidden_md,
      show:false
    },
    {
      id: 'reference',

      disablePadding: false,
      label: 'Ref',
      hidden : hidden_xs,
      show:true
    },
    {
      id: 'make',

      disablePadding: false,
      label: 'Make',
      show:true

    },
    {
      id: 'model',

      disablePadding: false,
      label: 'Model',
      show:true

    },
    {
      id: 'length',

      disablePadding: false,
      label: 'L',
      hidden : hidden_sm,
      show:true
    },
    {
      id: 'width',

      disablePadding: false,
      label: 'W',
      hidden : hidden_sm,
      show:true
    },
    {
      id: 'created_at',

      disablePadding: false,
      label: 'Purchase date',
      parseFunction: parseDate,
      hidden : hidden_md,
      show:true
    },
    {
      id: 'price',

      disablePadding: false,
      label: 'Total Cost',
      parseFunction: currency.format,
      hidden : hidden_xs,
      show:true
    },
   
];

const StockinDrawer = (props) => {

  const { row } = props;
  return (
    <>
        <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
          <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
          
            <StockinDetailsTable stockin={row} />
 
          </Box>
          <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
  
            <StockinSellerDetailsTable stockin={row} />
  
          </Box>
        </Box>
    </>
  )

}

const StockinsList = (props) => {

  const { items } = props;
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showExport, setShowExport] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log("FILTER LIST");
  });



  return (
    <>
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <StockinsFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      <Box sx={{display:'flex', columnGap:1, justifyContent:'flex-end', marginY:1}}>
        <Button 
          onClick={() => setShowDownload(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<PictureAsPdfIcon />}
        >
            Download Pdfs
        </Button>
        <Button 
          onClick={() => setShowExport(true)} 
          variant={'contained'} 
          size={'small'}
          startIcon={<ImportExportIcon />}
        >
          Export view
        </Button>
      </Box>
      { filteredItems.length === 0 ?
        (
          <LinearProgress/>
        ) : (

          <Box sx={{ width: '100%', mt:1,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="stockins"
                  items={filteredItems}
                  columns={columns}
                  rowActions={StockinActions(dispatch, navigate, enqueueSnackbar)}
                  withDrawer={true}
                  renderDrawer={<StockinDrawer />}
                  selectable
                  maxSelected={200}
                  onSelectedRows={(rows) => setSelectedItems(rows)}

                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
      <Modal 
          open={showExport}
          onClose={() => setShowExport(false)}
          title={'Export and download'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of entries: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <ExportToFile
            list={selectedItems.length > 0 ? selectedItems : filteredItems}
            columns={columns}
            submitLabel= {selectedItems.length > 0 ? 'Export selection' : 'Export view'}
            entityType={'stockins'}
            exportable={{exportUrl : 'stockins/export'}}
          />
        </Modal>
        <Modal 
          open={showDownload}
          onClose={() => setShowDownload(false)}
          title={'Download multiple invoices'}
          cancelLabel={'Close'}
        >
          <Alert variant={'outlined'} color={'info'}>No of documents: { selectedItems.length > 0 ? selectedItems.length : filteredItems.length }</Alert>
          <DownloadCombinedPdf
            list={selectedItems.length > 0 ? selectedItems : filteredItems}
            entityType='stockin'
          />
        </Modal>
    </>
  );
}

export default StockinsList;