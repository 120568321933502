import * as React from 'react';
import {Alert, Box,Card,CardContent, LinearProgress, Typography, Table, TableBody, TableRow, TableCell} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import EnquiresFilters from './EnquiresFilters';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import parseDate from 'utils/parseDate';
import currencyFraction from 'utils/currencyFraction';
import { hidden_md, hidden_xs, hidden_lg } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { EnquiresActions } from './actions';
import { Seen } from '../utils';

const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new user' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    
  },
  {
    id: 'firstName',
    disablePadding: false,
    label: 'Desc',

  },
  {
    id: 'lastName',
    disablePadding: false,
    label: '',
  },
  {
    id: 'type',
    disablePadding: false,
    label: 'Type',
    hidden : hidden_md,
  },
  {
    id: 'item',
    disablePadding: false,
    label: 'Item',
    hidden : hidden_md
  },
  {
    id: 'seen',
    disablePadding: false,
    label: 'Read',
    parseFunction: Seen,
    hidden : hidden_md
  },
  {
    id: 'created_at',

    disablePadding: false,
    label: 'Date',
    parseFunction: parseDate
  }
];

const EnquireDrawer = (props) => {

  const { row } = props;
  return (
    <>
    <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>FROM</TableCell>
            <TableCell>{row.firstName}{' '}{row.lastName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ABOUT</TableCell>
            <TableCell>{row.item}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TEL</TableCell>
            <TableCell>{row.telephone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>EMAIL</TableCell>
            <TableCell>{row.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MESSAGE</TableCell>
            <TableCell>{row.message}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SEEN AT</TableCell>
            <TableCell>{ row.seen ? parseDate(row.seen_at) : "NOT SEEN YET"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  </>

  )

}
const EnquiresList = (props) => {

  const { items, withFilters, withDrawer, withActions, onPick } = props;
  const [filteredItems, setFilteredItems] = React.useState(items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    
    console.log("FILTER LIST");

  });
  console.log("NEW F ITEMS :", filteredItems);
  if(items.length === 0) return <LinearProgress />

  return (
    <>
      { withFilters && 
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <EnquiresFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      }
      { filteredItems.length === 0 ?
        (
          <Alert severity="warning">No new enquires.</Alert>
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="users"
                  items={filteredItems}
                  columns={columns}
                  rowActions={withActions ? EnquiresActions(dispatch, navigate, enqueueSnackbar) : false}
                  onPick={onPick}
                  withDrawer
                  renderDrawer={withDrawer ? <EnquireDrawer /> : <></>}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
    </>
  );
}

export default EnquiresList;