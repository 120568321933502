import * as React from 'react';
import { ExpensesNav, ExpensesList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllExpenses} from 'features/expenses/expensesSlice'
import ShowStats from './components/ShowStats';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';
import { Typography, Alert } from '@mui/material';
import PrintExpensesDialog from './components/PrintExpensesDialog';
const Expenses = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllExpenses);
  const [printExpensesOpen, setPrintExpensesOpen] = React.useState(false);

  const closePrintExpensesDialog = () => setPrintExpensesOpen(false);
  const openPrintExpensesDialog = () => setPrintExpensesOpen(true);
  return (

      <DashboardContainer>
        <ExpensesNav onStats={setStatsOpen} isStatsOpen={statsOpen} onPrint={openPrintExpensesDialog} />

        { statsOpen && <ShowStats />}
        { items && 
        <>
          { items.length != 0 ? (

            <ExpensesList
              items={items}
              withFilters
              withActions
              withDrawer
            />

          ) : (
            <>
            <Alert severity="warning" sx={{mt:1}}>Nothing to show</Alert>
            </>
          )
          }
        </>
        }
        <PrintExpensesDialog open={printExpensesOpen} handleClose={closePrintExpensesDialog} />
      </DashboardContainer>

  );


}

export default Expenses;