import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import { NavItem, ThemeModeToggler } from './components';
import { Typography } from '@mui/material';
import { red } from '@mui/material/colors';

const Topbar = ({ onSidebarOpen, pages }) => {
  const theme = useTheme();
  const {
    landings: landingPages,
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        component="div"
        href="/"
        title="theFront"
        width={{ xs: 300, md: 400 }}
        color={red}
      >

        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'contained'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: theme.palette.primary.light,
          }}
        >
          <OtherHousesOutlinedIcon />
        </Button>
  
       <Typography variant='h6' sx={{ color:theme.palette.primary.main,ml:2}}>Lincolnshire Statics Ltd</Typography>
 
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
      
       
      
        <Box marginLeft={3}>
          <Link
            underline="none"
            component="a"
            href="/dashboard"
            color="text.primary"
          >
            Dashboard
          </Link>
        </Box>
        <Box marginLeft={3}>
          <ThemeModeToggler />
        </Box>
      
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Box marginRight={2}>
          <ThemeModeToggler />
        </Box>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
};

export default Topbar;
