import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Alert, Button} from '@mui/material';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const PdfViewer = ({ type, id }) => {

  const [pdfLoading, setPdfLoading] = React.useState(true);
  const [pdfError, setPdfError] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null);
  
  const getPdf = (method) => {
    axios.get(ApiUrl + 'pdf/' + type + '/' + method + '/' + id, { responseType: 'blob' })
      .then((response) => {
        try {
          const fileUrl = URL.createObjectURL(response.data, {type: 'application/pdf'});
          setPdfUrl(fileUrl);
        } catch(e){
          setPdfError(true);
        } finally {
          setPdfLoading(false);
        }
      }).catch((error) => {
        console.log('Error while loading pdf', error);
        setPdfError(true);
        setPdfLoading(false);
      }); 
  }

  React.useEffect(() => {
    setPdfLoading(true);
    setPdfError(false);
    getPdf('view');
    
  }, []);

  return (
    <Box sx={{width:'100%', backgroundColor:'#ffffff'}}>
      {pdfLoading && <LinearProgress /> }
      {pdfError && <Alert severity="error">Error loading pdf</Alert>}
      { pdfUrl && 
        <>
        <Box sx={{ display:'flex', justifyContent:'flex-end', p:1}}>
        {!pdfLoading && 
          <Button 
            variant={'contained'} 
            component={'a'} 
            href={pdfUrl} 
            download={'Invoice'}>Download PDF</Button>
        }
        </Box>
        <object
          data={pdfUrl} 
          width={'100%'} 
          height={'1000px'}
          frameBorder={1} 
          standby={'Loading document preview...'}
          type={'application/pdf'}
        >
          This browser does not support pdf viewing or error occurred.
        </object>
        </>
      }
    </Box>
  );
};

PdfViewer.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default PdfViewer;