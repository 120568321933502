import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { register } from 'features/users/usersSlice';
import Container from 'components/Container';
import { AssetsUrl } from 'features/api_url';
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Grid,
  TextField,
  Typography,
  LinearProgress
} from '@mui/material';


const Register = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading,setLoading ] = React.useState(false);
  const [error,setError ] = React.useState(null);
  const [success,setSuccess] = React.useState(null);
  const [repeatPassword, setRepeatPassword] = React.useState("");
  const [registerDetails, setRegisterDetails] = React.useState({ name: "", email: "", password: "", invcode: ""});

  const handleChange = ({target}) => setRegisterDetails({ ...registerDetails, [target.name]: target.value });
  const handleSubmit = () => {

    if(!passwordMatch()) return false;

    setLoading(true);
    dispatch(register(registerDetails)).then(result => {

      if(result.type === "users/register/fulfilled"){

        setLoading(false);
        // redirect to login
        navigate("/login", { state: { registerEmail: registerDetails.email }});
    }
    if(result.type === "users/register/rejected"){

        setLoading(false);
        setError(result.payload.status);
    }
      
    });    
  }
  const passwordMatch = () => registerDetails.password == repeatPassword;

  React.useEffect(() => {

    if(registerDetails.password.length > 0 && repeatPassword.length > 0)  passwordMatch() ? setError("") : setError("Passwords don't match");
  },[registerDetails.password, repeatPassword]);

  const feature_image = AssetsUrl + "register.png";
  return (
    <>
      <Helmet>
        <title>Register | Zimnet </title>
      </Helmet>
      <Container paddingY={4}>
      
      <Card sx={{maxWidth:"500px",margin:"auto"}}>
            <CardMedia
                component="img"
                alt="green iguana"
                sx={{maxHeight: "500px",paddingX:11,paddingY:3,background: 'linear-gradient(to left top, #fafafa, #ffffff)'}}
                width="100%"
                image={feature_image}
            />
           <CardContent
              sx={{background: 'linear-gradient(to right bottom, #fafafa, #ffffff)'}}
           >    
                { loading ? (<LinearProgress sx={{mb:2}}/>
                ) : (
                <Typography variant="pgreen" component="p" sx={{ mt:1,mb:2}}>Fill in the form below to register.</Typography>
                )
                }
                <Grid
                    container
                    spacing={1}
                    >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Username"
                      name="name"
                      onChange={handleChange}
                      required
                      value={registerDetails.name}
                      variant="outlined"
                      type="email"
                      inputProps={{
                        autoComplete: 'off'
                     }}
                    
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      required
                      value={registerDetails.email}
                      variant="outlined"
                      type="email"
                      inputProps={{
                        autoComplete: 'off'
                     }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Invitation code"
                      name="invcode"
                      onChange={handleChange}
                      required
                      value={registerDetails.invcode}
                      variant="outlined"
                      type="text"

                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      required
                      value={registerDetails.password}
                      variant="outlined"
                      type="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Repeat password"
                      name="repeatPassword"
                      onChange={(event) => setRepeatPassword(event.target.value)}
                      required
                      value={repeatPassword}
                      variant="outlined"
                      type="password"
                      inputProps={{
                        autoComplete: 'off'
                     }}
                    />
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      >
                      <Button 
                          variant="contained" 
                          onClick={handleSubmit}
                          fullWidth
                          sx={{paddingY:2}}
                          >
                        REGISTER
                      </Button>
                  </Grid>
                </Grid>
                { error && <Typography component="p" variant="pred" sx={{width:"100%",textAlign:"center",mt:1}}>{error}</Typography>}
            </CardContent>

            </Card>
      </Container>
    </>
  );
};

export default Register;
