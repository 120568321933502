import * as React from 'react';
import { CircularProgress, Typography, Box, Button } from '@mui/material';
import UploadPhotosList from './UploadPhotosList';
import { styled } from '@mui/material/styles';
import { useDispatch} from 'react-redux';
import { productUploadedPhotos } from 'features/products/productsSlice';

const UploadInput = styled('input')({
    display: 'none',
  });

const UploadPhotos = (props) => {

    const { product } = props;

    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadError,setUploadError] = React.useState(null);
    const [files, setFiles] = React.useState([]);

    const dispatch = useDispatch();

    const choosePhotos = (event) => {

        console.log(event);
        setFiles([...files, ...event.target.files]);

    }

    const deleteChoosenPhoto = (index) => {

        let filesArray = [...files];
        filesArray.splice(index,1);
        setFiles([...filesArray]);

    }

    const uploadPhotos = (event) => {

        let PhotosForm = new FormData();
    
        files.forEach(file => PhotosForm.append("photos[]",file));

        PhotosForm.append("product_id",parseInt(product.id));
        PhotosForm.append("title",product.make + " " + product.model);
        PhotosForm.append("reference",product.reference);

         setIsUploading(true);
         setUploadError(null);
         
         dispatch(productUploadedPhotos(PhotosForm))
            .then(function(response){
                if(response.type == "product/uploadPhotos/fulfilled"){
                    console.log(response);
                    
                } else {
                    setUploadError(response.error.message);
                }
                setIsUploading(false);
                setFiles([]);
            });
    }   
    return (
    <>
    { !isUploading && 
        
        <label htmlFor="contained-button-file">
            <UploadInput 
                accept="image/*" 
                id="contained-button-file" 
                multiple 
                type="file"
                onChange={choosePhotos}
                />
            <Button variant="contained" component="span">
                { files.length < 1 ? "Add photos" : "Add more photos" }
            </Button>
        </label>
        
    }
    { (files.length > 0 && !isUploading) &&
    <Button 
        variant="contained"
        sx={{ml:1}}
        onClick={uploadPhotos}
        >
        Upload
    </Button>
    
    }
    { isUploading && (<><CircularProgress /><Typography variant="pgreen" sx={{ml:1}}>Please wait...</Typography></>)}
    { uploadError && (<Typography variant="pred" sx={{ml:1}}>{uploadError}</Typography>)}
    
    { files.length > 0 && <Typography variant="h4" sx={{mb:1,mt:1}}>Images to upload</Typography>}
    <Box sx={{mt:2,mb:2}}>
    <UploadPhotosList 
        files={files} 
        onDelete={deleteChoosenPhoto}/>
    </Box>
    </>
    )
}

export default UploadPhotos;

