const Address = {
    id: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    postcode: [],
    county: "",
    extra_info: "",
    addressable_id: null,
    addressable_type: null,
    created_at: null,
    updated_at: null
  }
  
  export default Address;