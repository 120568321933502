import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@mui/material';

export const PaidStatus = (status) => {
    return status 
                ? <DoneIcon sx={{ color: "green" }} />
                : <CloseIcon  sx={{ color: "red" }} />
}

export const parseProducts = (products) => {

    return products.map(product => <Typography variant="pgreen" component="p" key={product.id}>{product.make} {product.model}</Typography>);
}

export const parseExtras = (extras) => {

    if(extras.length == 0) return <Typography variant="pred" component="p" sx={{ color:"#000"}}>No add-ons</Typography>;
    return extras.map(extra => <Typography variant="pgreen" component="p" key={extra.id}>{extra.description}</Typography>);
}