import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@mui/material';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';

const TrafficByOS = (props) => {
  
  const theme = useTheme();
  const [deviceData, setDeviceData] = React.useState({ windows: 0, mac:0, linux:0, android:0});

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.get(ApiUrl + 'stats/visitorsByOS',{ signal: abortCtrl.signal})
        .then(response => {

          if(response.data) setDeviceData(response.data);

        })
        .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING OS chart:");
      abortCtrl.abort();
    }

  },[]);

  const deviceTotal = deviceData.windows + deviceData.linux + deviceData.mac + deviceData.android;
  const windowsPercent = ((deviceData.windows/deviceTotal) * 100).toFixed(1);
  const linuxPercent = ((deviceData.linux/deviceTotal) * 100).toFixed(1);
  const macPercent = ((deviceData.mac/deviceTotal) * 100).toFixed(1);
  const androidPercent = ((deviceData.android/deviceTotal) * 100).toFixed(1);

  const data = {
    datasets: [
      {
        data: [deviceData.windows, deviceData.linux, deviceData.mac, deviceData.android],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600],
          colors.cyan[600],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Windows', 'Linux', 'Mac','Android']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Windows',
      value: windowsPercent,

      color: colors.indigo[500]
    },
    {
      title: 'Linux',
      value: linuxPercent,

      color: colors.red[600]
    },
    {
      title: 'Mac',
      value: macPercent,

      color: colors.orange[600]
    },
    {
      title: 'Android',
      value: androidPercent,

      color: colors.cyan[600]
    }
  ];

  return (
    <Card {...props} sx={{height:'100%'}}>
      <CardHeader title="Website traffic by OS" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap:'wrap',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h3"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByOS;
