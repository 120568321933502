/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { 
  Alert, 
  Box, 
  Grid, 
  TextField, 
  FormControlLabel, 
  Checkbox, 
  Button, 
  Typography, 
  LinearProgress, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { saleMailSent } from 'features/sales/salesSlice';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .trim(),
  isNewEmailAddress: yup
    .bool(),
  message: yup
    .string()
    .trim(),
});

const OrderMailForm = ({ model, onUpdate, submitLabel }) => {

  const dispatch = useDispatch();
  const [error,setError] = React.useState(null);
  const [status,setStatus] = React.useState(null);

  const { customers } = model;

  const initialValues = {
    email: customers[0]?.email || '',
    isNewEmailAddress: false,
    message: '',
    mailType:'invoice'
  };

  const onSubmit = async (payload) => {

    setError(null);
    setStatus(null);
    let response = await dispatch(saleMailSent({saleId: model.id, ...payload }));

    if(response.type.includes('fulfilled')){
      setStatus('Email sent successfully.');
      setTimeout(() => onUpdate(), 1000); // let the user see the message before closing the form
    }
    else {
      setError(response.error.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  if(!model) return null;

  return (
    <Box sx={{width:'250px'}}>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              size={'small'}
              InputLabelProps={{ shrink: true }}
              label="Email"
              variant="outlined"
              name={'email'}
              type={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            { formik.values.email != model?.email &&
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox 
                      size={'small'}
                      color="primary" 
                      name={'isNewEmailAddress'} 
                      checked={formik.values.isNewEmailAddress} 
                      onChange={formik.handleChange}
                    />
                  }
                  label={<Typography variant={'caption'}>Save as new email address?</Typography>}
                />
              </Box>
            }
          </Grid>
          <Grid item xs={12}>
            <FormControl 
                fullWidth
                error={formik.touched.status && Boolean(formik.errors.status)}
                size={'small'}
              >
              <InputLabel id="status-select-label">Type of mail</InputLabel>
            <Select
                labelId="mailtype-select-label"
                name={'mailType'}
                value={formik.values.mailType}
                label="Type of mail"
                onChange={formik.handleChange}
                size={'small'}
              >
                <MenuItem value={'invoice'}>Invoice</MenuItem>
                <MenuItem value={'receipt'}>Receipt</MenuItem>
                <MenuItem value={'query'}>Query</MenuItem>
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size={'small'}
              label={'Your Message (optional)'}
              variant={'outlined'}
              name={'message'}
              multiline
              rows={2}
              value={formik.values.message}
              fullWidth
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          { status &&
            <Grid item xs={12}>
              <Alert severity={'success'}>{status}</Alert>
            </Grid>
          }
          { error &&
            <Grid item xs={12}>
              <Alert severity={'error'}>{error}</Alert>
            </Grid>
          }

          <Grid item container xs={12} display={'flex'} justifyContent={'flex-start'}>
            <Button 
              size={'small'} 
              variant={'contained'} 
              type={'submit'}
              disabled={formik.isSubmitting}
            >
              { formik.isSubmitting ? 'Sending...' : submitLabel || 'Send'}

            </Button>
          </Grid>
          <Grid item xs={12}>
            { formik.isSubmitting && <LinearProgress /> }
          </Grid>
  

        </Grid>
      </form>
    </Box>
  );
};

OrderMailForm.propTypes = {
  model: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,

};

export default OrderMailForm;
