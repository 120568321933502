const Customer = {
    id: "",
    name: "",
    surname: "",
    mobile: "",
    phone: "",
    company: "",
    email: "",
    extra_info: "",
    created_at: "",
    updated_at: ""
  }

  export default Customer;