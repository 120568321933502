import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
    enquire: null,
    items: [],
    status: "idle",
    error: null,
    editing: { status: 'idle', error: null },
    creating: { status: 'idle', error: null },
    deleting: { status: 'idle', error: null },

}

export const fetchEnquires = createAsyncThunk('enquires/fetchEnquires', async() => {

    const response = await axios.get(ApiUrl + "enquires");
    return response.data

})

export const enquireMarkedAsSeen = createAsyncThunk('enquires/markedAsSeen', async(payload, thunkAPI) => {

    const response = await axios.post(ApiUrl + "enquires/markAsSeen",payload);
    return response.data

})

export const enquireAdded = createAsyncThunk('enquires/enquireAdded', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.post(ApiUrl + "enquires", payload);
    return response.data

})

export const enquireUpdated = createAsyncThunk('enquires/enquireUpdated', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.put(ApiUrl + "enquires/" + payload.id, payload.enquire);
    return response.data

})

export const enquireDeleted = createAsyncThunk('enquires/enquireDeleted', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.delete(ApiUrl + "enquires/" + payload.id, payload);
    return response.data

})

export const enquiresSlice = createSlice({
    name: 'enquires',
    initialState,
    reducers: {

        reset: state => initialState

    },
    extraReducers(builder) {
        builder

        // FETCH enquires
            .addCase(fetchEnquires.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchEnquires.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                // Add any fetched Enquires to the array
                state.items = [];
                state.items = state.items.concat(action.payload.map(item => nullToString(item)));

                // if enquire logged in, and client reload, update session
                if (state.enquire) {

                    const index = state.items.findIndex(function(o) {
                        return o.id == state.enquire.id;
                    })

                    state.enquire = state.items[index];
                    sessionStorage.setItem('enquire', JSON.stringify(state.items[index]));
                }

            })
            .addCase(fetchEnquires.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // CREATE Enquire
            .addCase(enquireAdded.pending, (state, action) => {
                state.creating.status = 'inprogress';
            })
            .addCase(enquireAdded.fulfilled, (state, action) => {
                state.creating.status = 'succeeded';
                state.creating.error = null;
                // Add any fetched Enquires to the array
                state.items.push(action.payload);
            })
            .addCase(enquireAdded.rejected, (state, action) => {
                state.creating.status = 'failed';
                state.creating.error = action.error.message;
            })
            // UPDATE Enquire
            .addCase(enquireUpdated.pending, (state, action) => {
                state.editing.status = 'pending';
            })
            .addCase(enquireUpdated.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items[index] = action.payload;
                state.editing.status = 'success';

                // state.items.push(action.payload)
            })
            .addCase(enquireUpdated.rejected, (state, action) => {
                state.editing.status = 'failed';
                state.editing.error = action.error.message;
            })
            // MARK AS SEEN
            .addCase(enquireMarkedAsSeen.pending, (state, action) => {
                state.editing.status = 'pending';
            })
            .addCase(enquireMarkedAsSeen.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items[index] = action.payload;
                state.editing.status = 'success';

                // state.items.push(action.payload)
            })
            .addCase(enquireMarkedAsSeen.rejected, (state, action) => {
                state.editing.status = 'failed';
                state.editing.error = action.error.message;
            })

        // DELETE enquire
        .addCase(enquireDeleted.pending, (state, action) => {
                state.deleting.status = 'pending';
            })
            .addCase(enquireDeleted.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items.splice(index, 1);
                state.deleting.status = 'success';

            })
            .addCase(enquireDeleted.rejected, (state, action) => {
                state.deleting.status = 'failed';
                state.deleting.error = action.error.message;
            })

    }

})

// Action creators are generated for each case reducer function
export const { reset } = enquiresSlice.actions;

export const selectAllEnquires = state => state.enquires.items;
export const selectEnquiresCount = state => state.enquires.items.length;

export const selectProductEnquiresCount = state => state.enquires.items.filter(enquire => enquire.type == "Product").length;
export const selectContactEnquiresCount = state => state.enquires.items.filter(enquire => enquire.type == "Contact").length;
export const selectSellCaravanEnquiresCount = state => state.enquires.items.filter(enquire => enquire.type == "SellCaravan").length;
export const selectNewEnquiresCount = state => state.enquires.items.filter(enquire => enquire.seen == false).length;

export default enquiresSlice.reducer;