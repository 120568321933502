import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Box, Typography, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import { uniqueId } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { eventAdded, eventDeleted, eventUpdated } from 'features/events/eventsSlice';

const eventTypes = ['Collection', 'Reminder'];
const eventColors = { Collection: 'green', Reminder: 'purple'}

const AddEventDialog = (props) => {

    const { open, handleClose, calendar, selectedDate } = props;
    const [newEvent, setNewEvent] = React.useState({event_date: selectedDate, title: '', description: '', eventType: 'Collection'});
    const [error, setError] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        setNewEvent({...newEvent, event_date: selectedDate});
    },[selectedDate]);

    const handleChange = (event) => {

        setNewEvent({...newEvent, [event.target.name]: event.target.value});
    }

    const onDialogClose = () => {
        setNewEvent({event_date: '', title: '', description: '', eventType: 'Collection'});
        setError(false);
        handleClose();
    }

    const handleSaveEvent = (calendarEvent) => {
        console.log("ADD EVENT", calendarEvent)
  
  
        const eventData = { title: calendarEvent.title, 
                            event_date: calendarEvent.date, 
                            description: calendarEvent.description,
                            type: calendarEvent.type
                           }
        
        dispatch(eventAdded({...eventData}))
        .then(result => {
  
            if(result.type === "events/eventAdded/fulfilled"){
              console.log("SUCCESS EVENT ADD", result.payload);
              calendarEvent.id = result.payload.id;
              calendarEvent.model = result.payload.model;
              calendar().addEvent(calendarEvent);
              onDialogClose();
            }
            else {console.log("FAIL EVENT ADD");}
        });
      };
  
    const saveNewEvent = () => {

            if(!_.isEmpty(newEvent.title) && 
               !_.isEmpty(newEvent.event_date) && 
               !_.isEmpty(newEvent.eventType))
            {
                let calendarEvent = {
                    id: null,
                    title: newEvent.title,
                    date: newEvent.event_date,
                    type: newEvent.eventType,
                    description: newEvent.description,
                    allDay: true,
                    color: eventColors[newEvent.eventType]
                };

                handleSaveEvent(calendarEvent);
            }
            else {
                setError(true);
            }


    }

    return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Event details"}
        </DialogTitle>
        <DialogContent>
            <Box sx={{display:'flex', flexDirection:'column',rowGap:1,p:1}}>
                <TextField
                    fullWidth
                    label="Event date"
                    type="date"
                    name="event_date"
                    value={newEvent.event_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  required
                  value={newEvent.title}
                  variant="outlined"
                />
                <TextField
                    fullWidth
                    label="Type"
                    name="eventType"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={newEvent.eventType}
                    variant="outlined"

                >
                  {eventTypes.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
                <TextField
                  aria-label="Event Description"
                  multiline
                  placeholder="Event info"
                  label="Description"
                  name="description"
                  onChange={handleChange}
                  required
                  fullWidth
                  value={newEvent.description}
                  variant="outlined"
                  maxRows={3}
                />
                <Button fullWidth variant={'outlined'} onClick={saveNewEvent}>ADD EVENT</Button>
            </Box>
            { error && <Typography variant={'p'} sx={{p:1}}>Fill required fields</Typography>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onDialogClose} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>     
    )
}

export default AddEventDialog;