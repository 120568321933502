import * as React from 'react';
import { reset as resetUsers } from 'features/users/usersSlice';
import { reset as resetProducts} from 'features/products/productsSlice';
import { reset as resetStockins} from 'features/stockins/stockinsSlice';
import { reset as resetCustomers } from 'features/customers/customersSlice';
import { reset as resetSales } from 'features/sales/salesSlice';
import { reset as resetGlobal } from 'features/global/globalSlice';
import { reset as resetAddresses } from 'features/addresses/addressesSlice'
import { reset as resetCart } from 'features/cart/cartSlice';
import { logout } from 'features/users/usersSlice';

export const Logout = (dispatch) => {

    dispatch(logout());
    dispatch(resetUsers());
    dispatch(resetProducts());
    dispatch(resetStockins());
    dispatch(resetCustomers());
    dispatch(resetSales());
    dispatch(resetGlobal());
    dispatch(resetAddresses());
    dispatch(resetCart());
    
}