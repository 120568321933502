import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  Grid,
  TextField,

} from '@mui/material';

import { isEmpty } from 'underscore';
import { ExtraModel } from 'models';

const ExtraSingleForm = ({onSave}) => {

    const [formValues, setFormValues] = useState(ExtraModel);
    const [touched, setTouched] = useState(false);

    const [formErrors, setFormErrors] = useState({
      description: "",
      quantity: "",
      price: "",

    });
    
    const onAdd = () => {
      const validate = validateForm(formValues);

      if(isEmpty(validate)) {

        setFormValues(ExtraModel);
        setFormErrors({ description: "", quantity: "", price:""});
        onSave(formValues);
      }
      else setFormErrors({ ...validate });     
    }


    const handleChange = (event) => {

        const numberFields = ['price','quantity'];
        let value = event.target.value;
        if(numberFields.includes(event.target.name)) {
          if (value != "-") value = parseFloat(event.target.value);

          // if field goes to empty, make empty string to avoid uncontrolled input
          if(value == NaN) value="";
      }
        

        setFormValues({
          ...formValues,
          [event.target.name]: value
        });
        setTouched(true);
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.description) errors.description = emptyMsg;
      if(!values.quantity || values.quantity == 0) errors.quantity= emptyMsg;
      if(!values.price || values.price == 0) errors.price = emptyMsg;

      return errors;
    }

    return (

      <form
        autoComplete="off"

      >
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            sm={4}
            xs={4}
          >
            <TextField
              fullWidth
              label="Item"
              name="description"
              onChange={handleChange}
              required
              value={formValues.description}
              variant="outlined"
              helperText={formErrors.description}
            />
          </Grid>
          <Grid
            item
            sm={2}
            xs={4}
          >
            <TextField
              fullWidth
              label="Quantity"
              name="quantity"
              onChange={handleChange}
              required
              value={formValues.quantity}
              variant="outlined"
              helperText={formErrors.quantity}
            />
          </Grid>
          <Grid
            item
            sm={3}
            xs={4}
          >
            <TextField
              fullWidth
              label="Price"
              name="price"
              onChange={handleChange}
              required
              value={formValues.price}
              variant="outlined"
              helperText={formErrors.price}
            />
          </Grid>
        
          <Grid
            item
            sm={3}
            xs={12}
            sx={{ display:"flex",justifyContent: { xs: "flex-end", sm: "flex-start"}}}
          >
          <Button
            color="primary"
            variant="outlined"
            onClick={onAdd}
            disabled={!touched}
            >
          Add
          </Button>         
          </Grid>           
        </Grid>
        

    </form>

    );

}

export default ExtraSingleForm;