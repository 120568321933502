import Sales from './Sales';
import { EditSaleForm, CreateSaleForm } from './components';
import AuthRole from 'layouts/Dashboard/AuthRole';
import ViewPdf from '../shared/ViewPdf';
const routes = [

  { path: 'sales', element: <Sales />},
  { path: 'sales/create', element: <AuthRole role="administrator" isPage><CreateSaleForm /></AuthRole>},
  { path: 'sales/edit/:id', element: <AuthRole role="moderator" isPage><EditSaleForm /></AuthRole>},
  { path: 'sales/pdf/:id', element: <AuthRole role="moderator" isPage><ViewPdf type="sale" /></AuthRole>}

]

  export default routes;