import * as React from 'react';
import { Button, Grid,  Table, TableBody, TableCell, TableRow, Box, TextField, Typography, LinearProgress } from '@mui/material'
import axios from 'axios';
import { ApiUrl } from 'features/api_url';
import { useMediaQuery, useTheme } from "@mui/material"

export const DistanceMap = (props) => {

    const { fromP, toP } = props;
    const link = "https://www.google.com/maps/embed/v1/directions?key=AIzaSyCHHSIuZca4ldowWec-qlMldvs10lhhyDA&origin=" 
                    + fromP + "&destination=" + toP;

    return (
        <iframe
        title="Route"
        width="100%"
        height="100%"
        style={{border:0}}
        loading="lazy"
        allowFullScreen
        src={link}>
    </iframe>
    )
}

export const DistanceInfo = (props) => {

    const { fromP, toP } = props;
    const [error, setError] = React.useState(null);
    const [loading,setLoading] = React.useState(false);
    const [distance, setDistance] = React.useState(null);

    React.useEffect(() => {
        
        if(fromP.length > 5 && toP.length > 5){
            const params = {
                fromPostcode: fromP.replace(/\s/g, "").toUpperCase(),
                toPostcode: toP.replace(/\s/g, "").toUpperCase()
            }

            setLoading(true);
            axios.get(ApiUrl + "getDistance", { params: params })
                .then(res => {
                    console.log(res);
                    setDistance(res.data);
                    setError(null);
                    setLoading(false);

                })
                .catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
        }

    },[fromP,toP]);

    if (fromP.length < 5 || toP.length < 5) return null;
    return(
        <>
        {loading && <LinearProgress /> }
        { error && <Typography variant="pred" component="p" sx={{mt:1,ml:1}}>{ error }</Typography>}
        { distance && !error &&
        <Table sx={{ border:1, borderColor:"#f2f2f2",mt:1,width:"100%"}}>
            <TableBody>
                <TableRow><TableCell><strong>From: </strong> </TableCell><TableCell>{ distance.from }</TableCell></TableRow>
                <TableRow><TableCell><strong>To: </strong></TableCell><TableCell>{ distance.to }</TableCell></TableRow>
                <TableRow><TableCell><strong>Distance: </strong></TableCell><TableCell>{ parseInt(parseFloat(distance.distance)/1.61) } miles</TableCell></TableRow>
                <TableRow><TableCell><strong>Duration</strong></TableCell><TableCell>{ distance.time }</TableCell></TableRow>
                <TableRow><TableCell><strong>Route: </strong></TableCell><TableCell>{ distance.summary }</TableCell></TableRow>
            </TableBody>
        </Table>
        }
        </>
    )
}

export const DistanceDetails = (props) => {

    const { fromP, toP } = props;

    if (fromP.length < 5 || toP.length < 5) return null;
    
    return(
        <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
            <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
            
                <DistanceInfo fromP={fromP} toP={toP} />

            </Box>
            <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>

                <DistanceMap fromP={fromP} toP={toP} />

            </Box>
        </Box>
    )
}