import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { 
    selectSubscriptionsCount,
    selectHomeSubscriptionsCount,
    selectStockSubscriptionsCount,
    selectContactSubscriptionsCount,


    } from "features/subscriptions/subscriptionsSlice";
import FigureBox from "../../shared/FigureBox";

import currencyFraction from "utils/currencyFraction";

const ShowStats = (props) => {

    const allSubscriptionsCount = useSelector(selectSubscriptionsCount);
    const subscriptionsHome = useSelector(selectHomeSubscriptionsCount);
    const subscriptionsContact = useSelector(selectContactSubscriptionsCount);
    const subscriptionsStock = useSelector(selectStockSubscriptionsCount);


    return(
        <>
        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
               
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Home Page"
                        figure={subscriptionsHome}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Stock Page"
                        figure={subscriptionsStock}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Contact page"
                        figure={subscriptionsContact}
                        avatarColor="black"
                        />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Subscriptions"
                        figure={allSubscriptionsCount}
                        avatarColor="black"
                        />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;