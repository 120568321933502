
import React from 'react';
import { SalesMonthlyQuantityChart } from '../../charts';
import { useSelector } from 'react-redux';
import { selectSalesYear } from 'features/statistics/statisticsSlice';
import { Card, CardContent, CardHeader, LinearProgress, Divider, Typography } from '@mui/material';
const YearChart = (props) => {

  const { year } = props;
  const selectedYear = useSelector((state) => selectSalesYear(state, year));

  return (
    <Card>
      <CardHeader
        title={ year + " sales"}
      />
      <Divider />
      <CardContent>
        { selectedYear && selectedYear? (
        <SalesMonthlyQuantityChart year={selectedYear} />
        ) :(
          <>
          <LinearProgress />
          <Typography variant="body2">Nothing to show</Typography>
          </>
        )
        }
      </CardContent>
    </Card>
  );

};

export default YearChart;
