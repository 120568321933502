import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null}
};


export const fetchFacebookPosts = createAsyncThunk('facebookposts/fetchFacebookPosts', async () => {

  const response = await axios.get(ApiUrl + "facebookposts");
  return response.data

});

export const facebookpostAdded = createAsyncThunk('facebookposts/facebookpostAdded', async (payload, thunkAPI) => {

  const response = await axios.post(ApiUrl + "facebookposts", payload);
  return response.data

});

export const facebookpostUpdated = createAsyncThunk('facebookposts/facebookpostUpdated', async (payload, thunkAPI) => {

  const response = await axios.put(ApiUrl + "facebookposts/" + payload.id, payload);
  return response.data

});

export const facebookpostDeleted = createAsyncThunk('facebookposts/facebookpostDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "facebookposts/" + payload.id, payload);
  return response.data

});

export const facebookpostsSlice = createSlice({
  name: 'facebookposts',
  initialState,
  reducers: {
    facebookpostsReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH facebookposts
        .addCase(fetchFacebookPosts.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchFacebookPosts.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched FacebookPosts to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchFacebookPosts.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE FacebookPost
        .addCase(facebookpostAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(facebookpostAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched FacebookPosts to the array
          state.items.push(action.payload);
        })
        .addCase(facebookpostAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE FacebookPost
        .addCase(facebookpostUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(facebookpostUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(facebookpostUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })

        // DELETE PRODUCT
        .addCase(facebookpostDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(facebookpostDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(facebookpostDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })

  }
})

export const { facebookpostsReload, reset } = facebookpostsSlice.actions;

export const selectFacebookPostById = (state, facebookpostId) => {

  let foundItem = null;
  if(state.facebookposts.items.length > 0) {
    foundItem = state.facebookposts.items.find(facebookpost => facebookpost.id == facebookpostId);
    foundItem = foundItem ? nullToString(foundItem) : undefined;
  }
  return foundItem;
}

export const selectAllFacebookPosts = state => state.facebookposts.items;
export const selectFacebookPostsCount = state => state.facebookposts.items.length;

export default facebookpostsSlice.reducer;