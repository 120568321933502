const Extra = {
    id: "",
    description: "",
    quantity: 1,
    price: "",
    sale_id: "",
    created_at: "",
    updated_at: ""
  }

  export default Extra;