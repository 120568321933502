import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@mui/material';
import parseDate from 'utils/parseDate';
import currency from 'utils/currency';
import { Box } from '@mui/system';
import { selectAllStockins } from 'features/stockins/stockinsSlice';

const PrintStockinsList = (props) => {


    const productList = useSelector(selectAllStockins);

    console.log(productList);

    return (
        <Box sx={{ p:2 }}>
        { productList ? (
        <>
        <Box>
            <Typography variant="body2" sx={{ padding:1, border: "1px solid ddd"}}>
                STOCK IN - TOTAL PRODUCTS : { productList.length }
            </Typography>
        </Box>
        <Table sx={{border:"1px solid #ddd"}}>
            <TableHead>
                <TableRow sx={{backgroundColor:"#f2f2f2"}}>
                    <TableCell>Ref</TableCell>
                    <TableCell>Make & Model</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Beds</TableCell>
                    <TableCell>DG/CH</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            { productList && productList.map(product =>
                <TableRow key={product.id}>
                    <TableCell>{product.reference}</TableCell>
                    <TableCell>{product.make}{' '}{product.model}</TableCell>
                    <TableCell>{product.length}{'x'}{product.width}</TableCell>
                    <TableCell>{product.no_of_beds}</TableCell>
                    <TableCell>{product.dg ? "Y" : "N"}/{product.ch ? "Y" : "N"}</TableCell>

                    <TableCell>{currency.format(product.price)}</TableCell>
                    <TableCell>{parseDate(product.created_at)}</TableCell>
                </TableRow>
            )}
            </TableBody>
        </Table>
        </>
        ) : (
            <Typography variant="body2">NOTHING TO SHOW</Typography>
        )
        }

        </Box>
    )
}

export default PrintStockinsList;