import * as React from 'react';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import parseDate from 'utils/parseDate';
import { getRoles } from 'utils/checkRole';
import { useDispatch } from 'react-redux';
import { userUploadedPhoto, setUser} from 'features/users/usersSlice';
import { makeProfileImgUrl } from 'features/api_url';


const UploadInput = styled('input')({
  display: 'none',
});

const AccountProfile = (props) => {

  const { user, uploadPhotoButton, avatarHeight, avatarWidth } = props;

  const user_roles = getRoles(user);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const choosePhotos = (event) => {

    let PhotoForm = new FormData();

    PhotoForm.append("photo",event.target.files[0]);
    PhotoForm.append("user_id", user.id);
    console.log("FD:", PhotoForm);
    dispatch(userUploadedPhoto(PhotoForm)).then(result => {
      if(result.type === "users/uploadedPhoto/fulfilled"){
          
          dispatch(setUser(result.payload.user));
          console.log("setting user:", result.payload.user);
          setError(null);
      }
      if(result.type === "users/uploadedPhoto/rejected"){
         console.log("ERR RESUT:", result);
          setError(result.payload.status || "Something gone wrong");
      }
  });    
    
    console.log("FILES ",event.target.files[0]);



  }

  const profileImgUrl = user?.photo ? makeProfileImgUrl(user) : null;
  const uploadPhotoText = user?.photo ? "CHANGE PROFILE PHOTO" : "UPLOAD PROFILE PHOTO"
  return (
  <>
  <Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        
        <Avatar
          src={profileImgUrl}
          sx={{
            height: avatarHeight,
            width: avatarWidth
          }}
        />
        
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h4"
          sx={{mt:1}}
        >
          {user.name}
        </Typography>
        
        <Typography
          color="textSecondary"
          variant="p"
          
        >
          Member since { parseDate(user.created_at) }
        </Typography>
        
        <Divider />

          { user_roles && user_roles.map(role => <Typography variant="psmall" component="p" sx={{textAlign:"center"}} key={role}>{role}</Typography>)}

        
        
      </Box>
     
    </CardContent>
   
    { uploadPhotoButton && 
      <>
      <Divider />
       { error && <Typography component="p" variant="pred" sx={{width:"100%",textAlign:"center",mt:1}}>{error}</Typography>}
      
      <CardActions sx={{display:'flex', justifyContent:"center",marginY:2}}>

      <label htmlFor="contained-button-file">
              <UploadInput 
                  accept="image/*" 
                  id="contained-button-file"
                  type="file"
                  onChange={choosePhotos}
                  name="photo"
                  />
              <Button variant="outlined" component="span" fullWidth>
                  { uploadPhotoText }
              </Button>
      </label>
      
      </CardActions>
    
      </>
    }
    </Card>
   
  </>
)
}
export default AccountProfile;
