import * as React from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import parseDate from 'utils/parseDate';

import {
    Table,
    Typography,
    Button,
 
  } from '@mui/material';

const LoginHistoryDetailsTable = (props) => {

    const { item } = props;

    return (
        <Table size="small" aria-label="stockins" sx={{ minWidth:"200px",border:1,borderColor:"#f2f2f2"}}>
            <TableBody>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">USERNAME</Typography></TableCell>
                    <TableCell>{item.user.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">DATE</Typography></TableCell>
                    <TableCell>{parseDate(item.login_date)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">IP</Typography></TableCell>
                    <TableCell>{item.ip}</TableCell>
                </TableRow>
            
            </TableBody>
        </Table>

    )

}

export default LoginHistoryDetailsTable;