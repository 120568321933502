import React from 'react';
import { Box,Button, Alert, Typography, Table, TableRow, TableCell, TableBody, LinearProgress, TableHead } from '@mui/material';
import FBContext from 'components/facebook/FBContext';
import parseDate from 'utils/parseDate';
import parseDateAndTime from 'utils/parseDateAndTime';

const ProductFacebookPostsList = (props) => {

    const { product } = props;



    return (
        <Box sx={{mt:2}}>
            
            <Typography variant="subtitle2">FB Posts related to this product</Typography>
            {(product.facebook_posts && product.facebook_posts.length > 0) ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Post Date</TableCell>
                            <TableCell>Posted by</TableCell>
                            <TableCell>Photos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product.facebook_posts.map((post) => (
                        <TableRow key={post.id}>
                            <TableCell>
                                {parseDateAndTime(post.created_at)}
                            </TableCell>
                            <TableCell>
                                {post.user.name}
                            </TableCell>
                            <TableCell>
                                {post.photos_info ? 'Yes' : 'No'}
                            </TableCell>
                        </TableRow>
                        )
                        )
                        }
                    </TableBody>
                </Table>
            ) : (
                <Alert severity={'warning'}>No Facebook posts found for this product</Alert>
            )}
        </Box>
        
    )
  
}

export default ProductFacebookPostsList;