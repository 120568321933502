import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import GlobalStyles from '../layouts/Dashboard/GlobalStyles';
import theme from '../theme-dashboard';

const PageDashboard = ({children}) => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default PageDashboard;
