import { Bar } from 'react-chartjs-2';
import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Popover,
  Typography,
  MenuItem
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const StockinSalesBalanceChart = (props) => {
  const theme = useTheme();
  const { weeks } = props;
  const [noOfWeeks,setNoOfWeeks] = React.useState(weeks);
  const [perfData, setPerfData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangePeriod = (value) => {
    setNoOfWeeks(value);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'change-period' : undefined;

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.get(ApiUrl + 'stats/getPerformanceData/' + noOfWeeks, { signal: abortCtrl.signal})
        .then(response => {
          if(response.data) setPerfData(response.data);
        })
        .catch((err) => console.log('ABORTING: ', err));
      
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING CHART:");
      abortCtrl.abort();
    }

  },[noOfWeeks])

  const data = {
    datasets: [
      {
        label: "Sales",
        type: 'line',
        data: perfData.map(p => p.sales),
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        borderColor: "rgb(75, 192, 192)",
    },
      {
        label: "Stockins",
        type:'line',
        data: perfData.map(p => p.stockins),
        fill: false,
        backgroundColor: "rgba(54, 162, 235, 0.4)",
        borderColor: "rgb(54, 162, 235)",
        maxBarThickness:50
    },
    {
      label: "Weekly balance",
      type:'bar',
      data: perfData.map(p => p.profit),
      fill: true,
      backgroundColor: "rgba(153, 102, 255, 0.2)",
      borderColor: "rgb(153, 102, 255)",
  },
  {
    label: "Expenses",
    type:'line',
    data: perfData.map(p => p.expenses),
    fill: true,
    backgroundColor: "rgba(153, 102, 255, 0.2)",
    borderColor: "rgb(153, 102, 255)",
},
    ],
    labels: perfData.map(p => p.startWeek),
  };
console.log(data);
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...props}>
      <CardHeader
        action={(
          <>
          <Button
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
            aria-describedby={id}
            onClick={handleClick}
          >
            Last {noOfWeeks} weeks
          </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >

            <MenuItem onClick={ () => handleChangePeriod(4)}>
              <Typography sx={{color:'primary.main'}}>1 month</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={ () => handleChangePeriod(12)}>
              <Typography sx={{color:'primary.main'}}>3 months</Typography>
            </MenuItem>
            <Divider />       
            <MenuItem onClick={ () => handleChangePeriod(24)}>
              <Typography sx={{color:'primary.main'}}>6 months</Typography>
            </MenuItem>
            <Divider />
            <MenuItem  onClick={ () => handleChangePeriod(48)}>
              <Typography>12 months</Typography>
            </MenuItem>

        </Popover>
        </>
        )}
        title="Weekly Performance"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          component={'a'}
          to={'/dashboard/statistics'}
        >
          Full overview
        </Button>
      </Box>
    </Card>
  );
};

export default StockinSalesBalanceChart;
