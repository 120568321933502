import * as React from 'react';
import {Alert, Box,Card,CardContent, LinearProgress, Typography} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MobileDial from 'views/Dashboard/shared/MobileDial';
import withLink from 'utils/withLink';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable'
import SubscriptionsFilters from './SubscriptionsFilters';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate} from 'react-router';
import parseDate from 'utils/parseDate';
import currencyFraction from 'utils/currencyFraction';
import { hidden_md, hidden_xs, hidden_lg } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import { SubscriptionsActions } from './actions';
import { Seen } from '../utils';

const actions = [
  { icon: withLink("create",<FileCopyIcon />), name: 'Add new user' },

  { icon: withLink("create",<PrintIcon />), name: 'Print List' },

];

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'ID',
    hidden : hidden_md,
    
  },
  {
    id: 'email',
    disablePadding: false,
    label: 'Email',

  },

  {
    id: 'type',
    disablePadding: false,
    label: 'Type',
    hidden : hidden_md,
  },

  {
    id: 'created_at',

    disablePadding: false,
    label: 'Date',
    parseFunction: parseDate
  }
];

const SubscriptionDrawer = (props) => {

  const { row } = props;
  return (
    <>
    <Box sx={{ mt: 1,mb:1,display:'flex',columnGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
      <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>
      


      </Box>
      <Box sx={{ m:1,flexGrow:1,flexBasis:0, minWidth:"250px",width:{ "xs": "100%", "sm":"50%"}}}>



      </Box>
    </Box>
  </>

  )

}
const SubscriptionsList = (props) => {

  const { items, withFilters, withDrawer, withActions, onPick } = props;
  const [filteredItems, setFilteredItems] = React.useState(items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    
    console.log("FILTER LIST");

  });
  console.log("NEW F ITEMS :", filteredItems);
  if(items.length === 0) return <LinearProgress />

  return (
    <>
      { withFilters && 
      <Box sx={{ width: '100%',mt: 3 }}>
        <Card>
          <CardContent>
             <SubscriptionsFilters

                items={items}
                onChange={setFilteredItems}
              />
          </CardContent>
        </Card>
      </Box>
      }
      { filteredItems.length === 0 ?
        (
          <Alert severity="warning">No new subscriptions.</Alert>
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="users"
                  items={filteredItems}
                  columns={columns}
                  rowActions={withActions ? SubscriptionsActions(dispatch, navigate, enqueueSnackbar) : false}
                  onPick={onPick}
                  withDrawer
                  renderDrawer={withDrawer ? <SubscriptionDrawer /> : <></>}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      <MobileDial actions={actions} />
    </>
  );
}

export default SubscriptionsList;