import { saleDeleted, saleUpdated } from 'features/sales/salesSlice';
import { productUpdated } from 'features/products/productsSlice';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DeleteIcon from '@mui/icons-material/Delete';
import PaidIcon from '@mui/icons-material/Paid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    const confirm = window.confirm("Are you sure ?");
    
    if(confirm) dispatch(saleDeleted(item)).then(result => {

      if(result.type === "sales/saleDeleted/fulfilled"){
        enqueueSnackbar("Sale deleted successfully");

        item.products.forEach(product => {

          let p = Object.assign({}, product);
          p.sale_id = null;
          
          dispatch(productUpdated(p)).then(result => {
            result.type == "products/productUpdated/fulfilled"
                        ? enqueueSnackbar("Product back in stock:" + product.make)
                        : enqueueSnackbar("Product failed to clear:" + product.make)
          })
        })
      }
      else {
        enqueueSnackbar("SALE - " + result.error.message);
      }
    }); 
  }

export const handleEditItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
    navigate("/dashboard/sales/edit/" + item.id)
  }

export const handleOnPaidItem = (dispatch, navigate, enqueueSnackbar) => (event, item, type) => {

    const payload = { action: type, id: item.id }

    dispatch(saleUpdated(payload)).then(result => {

      result.type === "sales/saleUpdated/fulfilled"
                            ? enqueueSnackbar("Sale updated successfully")
                            : enqueueSnackbar("Sale - " + result.error.message);
    });
  }

  export const handleOnDeliveryItem = (dispatch, navigate, enqueueSnackbar) => (event, item, delDate) => {

    const payload = { action: "deliveryBooked", id: item.id, bookedDate: delDate }

    dispatch(saleUpdated(payload)).then(result => {

      result.type === "sales/saleUpdated/fulfilled"
                            ? enqueueSnackbar("Delivery date updated successfully")
                            : enqueueSnackbar("Sale delivery - " + result.error.message);
    });
  }

  export const handleOnDeliveredItem = (dispatch, navigate, enqueueSnackbar) => (event, item, delDate) => {

    const payload = { action: "deliveryDelivered", id: item.id, deliveredDate: delDate }

    dispatch(saleUpdated(payload)).then(result => {

      result.type === "sales/saleUpdated/fulfilled"
                            ? enqueueSnackbar("Sale marked as delivered")
                            : enqueueSnackbar("Sale delivered - " + result.error.message);
    });
  }

  export const handleOnNotifiedItem = (dispatch, navigate, enqueueSnackbar) => (event, item) => {

    const payload = { action: "deliveryNotified", id: item.id }

    dispatch(saleUpdated(payload)).then(result => {

      result.type === "sales/saleUpdated/fulfilled"
                            ? enqueueSnackbar("Sale marked as notified")
                            : enqueueSnackbar("Sale notified - " + result.error.message);
    });
  }

export const handlePrintItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  event.stopPropagation();

  navigate("/print/saleInvoice/" + item.id);
}

export const handlePDFItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {
  event.stopPropagation();

  navigate("/dashboard/sales/pdf/" + item.id);
}

export const SaleActions = (dispatch, navigate, enqueueSnackbar) => [
    {
    id:1,
    name: "Edit",
    icon: <ModeEditIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleEditItem(dispatch, navigate, enqueueSnackbar),
    show: true
  
    },
    {
    id:2,
    name: "Delete",
    icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
    id:3,
    name: "Print",
    icon: <LocalPrintshopIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handlePrintItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
    id:4,
    name: "PDF",
    icon: <PictureAsPdfIcon sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handlePDFItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
    {
    id:5,
    name: "Paid",
    icon: <PaidIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleOnPaidItem(dispatch, navigate, enqueueSnackbar),
    show: false
    },
    {
      id:6,
      name: "Delivery",
      icon: <PaidIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleOnDeliveryItem(dispatch, navigate, enqueueSnackbar),
      show: false
    },
    {
      id:7,
      name: "Delivered",
      icon: <PaidIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleOnDeliveredItem(dispatch, navigate, enqueueSnackbar),
      show: false
    },
    {
      id:8,
      name: "Notified",
      icon: <PaidIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
      callback: handleOnNotifiedItem(dispatch, navigate, enqueueSnackbar),
      show: false
      }
    ];