import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import DeleteIcon from '@mui/icons-material/Delete';
import { subscriptionUnsubscribed, subscriptionDeleted } from 'features/subscriptions/subscriptionsSlice';

export const handleUnsubscribeItem  = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

      dispatch(subscriptionUnsubscribed(item)).then(result => {

        result.type === "subscriptions/subscriptionUnsubscribed/fulfilled"
                          ? enqueueSnackbar("Subscription marked as unsubscribed")
                          : enqueueSnackbar("Subscription - " + result.error.message);
      });
}
  // DELETE Stockin
export const handleDeleteItem = (dispatch, navigate, enqueueSnackbar) => (event,item) => {

    event.stopPropagation();

    const confirm = window.confirm("Are you sure ?");

    if(confirm) dispatch(subscriptionDeleted(item)).then(result => {

      result.type === "subscriptions/subscriptionDeleted/fulfilled"
                            ? enqueueSnackbar("Subscription deleted successfully")
                            : enqueueSnackbar("Subscription - " + result.error.message);
                });
}

export const SubscriptionsActions = (dispatch, navigate, enqueueSnackbar) => [
  {
  id:1,
  name: "Mark as seen",
  icon: <UnsubscribeIcon sx={{color: (theme) => theme.palette.primary.main }}/>,
  callback: subscriptionUnsubscribed(dispatch, navigate, enqueueSnackbar),
  show: true
  },
  {
    id:2,
    name: "Delete",
    icon: <DeleteIcon  sx={{color: (theme) => theme.palette.primary.main }}/>,
    callback: handleDeleteItem(dispatch, navigate, enqueueSnackbar),
    show: true
    },
  ];