import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Box, Typography, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { uniqueId } from 'lodash';


const PrintExpensesDialog = (props) => {

    const { open, handleClose } = props;
    const [rangeDate, setRangeDate] = React.useState({ startDate: '', endDate : ''});

    const [error, setError] = React.useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {

        setRangeDate({...rangeDate, [event.target.name]: event.target.value});
    }

    const onDialogClose = () => {

        setError(false);
        handleClose();
    }

    const handlePrint = () => {

        if(!_.isEmpty(rangeDate.startDate) && !_.isEmpty(rangeDate.startDate)){
            navigate('/print/expensesRangeList',{state: rangeDate});
        }
        else setError(true);
    }

    return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Expenses Date range"}
        </DialogTitle>
        <DialogContent>
            <Box sx={{display:'flex', flexDirection:'column',rowGap:1,p:1}}>
                <TextField
                    fullWidth
                    label="Start date"
                    type="date"
                    name="startDate"
                    value={rangeDate.startDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    label="End date"
                    type="date"
                    name="endDate"
                    value={rangeDate.endDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    variant="outlined"
                />

                <Button fullWidth variant={'outlined'} onClick={handlePrint}>PRINT LIST</Button>
            </Box>
            { error && <Typography variant={'p'} sx={{p:1}}>Fill required fields</Typography>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onDialogClose} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>     
    )
}

export default PrintExpensesDialog;