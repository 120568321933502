import axios from 'axios';
import * as React from 'react'
import { Grid } from '@mui/material';
import { LatestProducts } from 'views/Dashboard/Statistics/components/ProductsStats/components';
import { LatestSales } from 'views/Dashboard/Statistics/components/SalesStats/components';
import StockinSalesBalanceChart from './components/StockinSalesBalanceChart';

const GeneralStats = (props) => {
  
    return (
        <>
            <Grid 
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    <StockinSalesBalanceChart weeks={24} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <LatestProducts />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                   <LatestSales />     
                </Grid>
            </Grid>
            
        </>
    );
}

export default GeneralStats;