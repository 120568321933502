import * as React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';



const StockinDetailsTable = (props) => {

    const { stockin } = props;

    return (
        <>
            <Table size="small" aria-label="stockins" sx={{}}>
            <TableBody>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">MAKE & MODEL</Typography></TableCell>
                    <TableCell>{stockin.make} {stockin.model}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">SIZE</Typography></TableCell>
                    <TableCell>{stockin.length} x {stockin.width}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">REFERENCE</Typography></TableCell>
                    <TableCell>{stockin.reference}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">CONDITION</Typography></TableCell>
                    <TableCell>{stockin.year} {stockin.condition}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th"><Typography variant="h6" gutterBottom component="div">COLOR</Typography></TableCell>
                    <TableCell>{stockin.color}</TableCell>
                </TableRow>   
                <TableRow>
                    <TableCell component="th" sx={{ borderBottom:0}}><Typography variant="h6" gutterBottom component="div">BEDROOMS</Typography></TableCell>
                    <TableCell sx={{ borderBottom:0}}>{stockin.no_of_beds}</TableCell>
                </TableRow>               
            </TableBody>
            </Table>
        </>
    )
}

export default StockinDetailsTable;