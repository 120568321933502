import React from 'react';
import FBContext from './FBContext';
import useFB from './hooks/useFB';

const FBProvider = (props) => {

  const { pageId, children } = props;
  const FB = useFB(pageId);

  return (
    <FBContext.Provider value ={FB}>
      {children}
    </FBContext.Provider>

  )
}

export default FBProvider;