import React from 'react';
import {
  NavLink as RouterLink,

} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import parseDate from 'utils/parseDate';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const UpcomingEventsTable = (props) => {

  const [eventsData, setEventsData] = React.useState([]);

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.get(ApiUrl + 'events/upcoming',{ signal: abortCtrl.signal})
        .then(response => {

          if(response.data) setEventsData(response.data);

        })
        .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING browser chart:");
      abortCtrl.abort();
    }

  },[]);

  return (
  <Card {...props}>
    <CardHeader title="Upcoming events" />
    <Divider />

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Event
              </TableCell>
              <TableCell>
                Type
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            { eventsData && eventsData.slice(0,7).map((event) => (
              <TableRow
                hover
                key={event.id}
              >
                <TableCell>
                  {event.title}
                </TableCell>
                <TableCell>
                  {parseDate(event.date)}
                </TableCell>
                <TableCell>
                  {event.type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
        component={RouterLink}
        to={'/dashboard/events'}
      >
        View calendar
      </Button>
    </Box>
  </Card>
  );
}
export default UpcomingEventsTable;
