import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography
} from '@mui/material';

import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { selectAllRoles } from 'features/roles/rolesSlice';
import { checkRole } from 'utils/checkRole';
import UserRoleButton from './UserRoleButton';

const UserRolesForm = ({ user }) => {

    console.log("User Roles Form Render");
    const [roles, setRoles] = useState([]);
    const [touched, setTouched] = useState(false);
    const systemRoles = useSelector(selectAllRoles);

    React.useEffect(() => {

        if(user?.roles) setRoles(user.roles)
        else setRoles([]);

    },[user]);


    return(
        <>
        <Grid
            container
            spacing={1}
        >

                { systemRoles.map(role => <Grid item key={role.id}><UserRoleButton role={role} user={user} /></Grid>)}

        </Grid>

        </>
    )
}

export default UserRolesForm;