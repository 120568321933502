import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null}
}


export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async () => {

  const response = await axios.get(ApiUrl + "customers");
  return response.data

})

export const customerAdded = createAsyncThunk('customers/customerAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "customers", payload);
  return response.data

})

export const customerUpdated = createAsyncThunk('customers/customerUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "customers/" + payload.id, payload);
  return response.data

})

export const customerDeleted = createAsyncThunk('customers/customerDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "customers/" + payload.id, payload);
  return response.data

})

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {

    customersReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH CUSTOMERS
        .addCase(fetchCustomers.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchCustomers.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Customers to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchCustomers.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE Customer
        .addCase(customerAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(customerAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Customers to the array
          state.items.push(action.payload);
        })
        .addCase(customerAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE Customer
        .addCase(customerUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(customerUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(customerUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })

        // DELETE customer
        .addCase(customerDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(customerDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(customerDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })
       
  }
})

export const { customersReload, reset } = customersSlice.actions

export const selectCustomerById = (state, customerId) => {

  let foundItem = null;
  if(state.customers.items.length > 0) {
    console.log("CUSTOMERS PRESENT");
    foundItem = state.customers.items.find(customer => customer.id == customerId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}
export const selectCustomersEmailCount = state => state.customers.items.filter(item => item.email != "").length;
export const selectCustomersMobileCount = state => state.customers.items.filter(item => item.mobile != "").length;
export const selectCustomersPhoneCount = state => state.customers.items.filter(item => item.phone != "").length; 
export const selectAllCustomers = state => state.customers.items;
export const selectCustomersCount = state => state.customers.items.length;
export const selectCustomersById = (state, customerId) =>
                      state.customers.items.find(customer => customer.id === customerId)

export default customersSlice.reducer;