import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  sales: [],
  status: 'idle',
  error: null,

}


export const fetchSalesYears = createAsyncThunk('statistics/fetchSalesYears', async () => {

  const payload = { from: '2010-01-01', to: '2030-12-31' }
  const response = await axios.post(ApiUrl + "stats/getSalesYears", payload);
  return response.data

})


export const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    StatisticsReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH CUSTOMERS
        .addCase(fetchSalesYears.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchSalesYears.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Statistics to the array
          state.sales = action.payload;
         
        })
        .addCase(fetchSalesYears.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
      

  }
})

export const { StatisticsReload, reset } = StatisticsSlice.actions;

export const selectAllSalesYears = state => state.statistics.sales;

export const selectSalesYear = (state, year) => state.statistics.sales.find(y => y.id == year);

export const selectSalesMonthYear = (state, month, year) => {

  const y = state.statistics.sales.find(y => y.id == year);

  return y ? y.months.find(m => m.id == month) : null;

}

export default StatisticsSlice.reducer;