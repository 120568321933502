import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { login, loadingStatus, setUser } from 'features/users/usersSlice';
import Container from 'components/Container';
import { AssetsUrl } from 'features/api_url';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
  LinearProgress
} from '@mui/material';


const Login = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading,setLoading ]= React.useState(false);
  const [error,setError ]= React.useState(null);
  // if redirected from register page, set email to new email 
  const [loginDetails, setLoginDetails] = React.useState({email: state?.registerEmail || "", password:""});

  const handleChange = ({target}) => setLoginDetails({ ...loginDetails, [target.name]: target.value });
  const handleSubmit = () => {
    setLoading(true);
    dispatch(login(loginDetails)).then(result => {

      if(result.type === "users/login/fulfilled"){

        const user_data = result.payload.user;
        //save user in store and session
        dispatch(setUser(user_data));
        sessionStorage.setItem('user', JSON.stringify(user_data));

        setLoading(false);
        // redirect to requested page or dashboard
        navigate(state?.path || "/dashboard");
    }
    if(result.type === "users/login/rejected"){
        setError(result.payload.status);
        setLoading(false);
        console.log(result); 
    }
      
    });    
  }

  const feature_image = AssetsUrl + "login5.png";
  return (
    <>
      <Helmet>
        <title>Login | Zimnet </title>
      </Helmet>
      <Container paddingY={4}>
      
      <Card sx={{maxWidth:"500px",margin:"auto"}}>
            <CardMedia
                component="img"
                alt="green iguana"
                sx={{maxHeight: "500px",paddingX:11,paddingY:3,background: 'linear-gradient(to left top, #fafafa, #ffffff)'}}
                width="100%"
                image={feature_image}
            />
           <CardContent
              sx={{background: 'linear-gradient(to right bottom, #fafafa, #ffffff)'}}
           >    
                { loading ? (<LinearProgress sx={{mb:2}}/>
                ) : (
                <Typography variant="pgreen" component="p" sx={{ mt:1,mb:2}}>Please enter you login details.</Typography>
                )
                }
                <Grid
                    container
                    spacing={1}
                    >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      required
                      value={loginDetails.email}
                      variant="outlined"
                      type="email"

                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      required
                      value={loginDetails.password}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      >
                      <Button 
                          variant="contained" 
                          onClick={handleSubmit}
                          fullWidth
                          sx={{paddingY:2}}
                          >
                        LOG IN
                      </Button>
                  </Grid>
                </Grid>
                { error && <Typography component="p" variant="pred" sx={{width:"100%",textAlign:"center",mt:1}}>{error}</Typography>}
            </CardContent>

            </Card>
      </Container>
    </>
  );
};

export default Login;
