import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card, CardContent, Typography, Button, Divider} from '@mui/material'
import { DashboardContainer } from 'views/Dashboard/components';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorageIcon from '@mui/icons-material/Storage';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useMediaQuery, useTheme } from "@mui/material"
import { LoginHistoryList } from './components';
import { useSelector } from 'react-redux';
import { selectAllLoginHistory } from 'features/login_history/loginHistorySlice'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sale-tabpanel-${index}`}
      aria-labelledby={`sale-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `sale-tab-${index}`,
    'aria-controls': `sale-tabpanel-${index}`,
   
  };
}


const Settings = () => {

  const [value, setValue] = React.useState(0);
  const loginHistoryItems = useSelector(selectAllLoginHistory);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mobileTabStyle = {pl:1,pr:1,minWidth:"70px"};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DashboardContainer>

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                
                {isMobile ? (
                  <Tabs value={value} aria-label="Sattings Tabs" centered  onChange={handleChange}>
                    <Tab icon={<PointOfSaleIcon />} aria-label="General" sx={mobileTabStyle}/>
                    <Tab icon={<ContactsIcon />} aria-label="Stock" sx={mobileTabStyle} />
                    <Tab icon={<LocalShippingIcon />} aria-label="Stats" sx={mobileTabStyle} />
                    <Tab icon={<StorageIcon />} aria-label="Messages" sx={mobileTabStyle} />
                    <Tab icon={<FactCheckIcon />} aria-label="Login History" sx={mobileTabStyle} />
                  </Tabs>
                  
                  ) : (
                
                  <Tabs value={value} aria-label="Settings Tabs"  onChange={handleChange}>
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Stock" {...a11yProps(1)} />
                    <Tab label="Stats" {...a11yProps(2)} />
                    <Tab label="Messages" {...a11yProps(3)} />
                    <Tab label="Login History" {...a11yProps(4)} />
                  </Tabs>
                )
                }
                
            </Box>

            <TabPanel value={value} index={0}>
                <Card sx={{mt:2}}>
                  <CardContent>
                   
                  </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card sx={{mt:2}}>
                  <CardContent>
                

                  </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card sx={{mt:2}}>
                  <CardContent>
                  
                  </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Card sx={{mt:2}}>
                    <CardContent>


                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Card sx={{mt:2}}>
                    <CardContent>
                    { loginHistoryItems &&

                        <LoginHistoryList
                            items={loginHistoryItems}
                            withDrawer
                        />
                    }
                    </CardContent>
                </Card>
            </TabPanel>
        </Box>            
                    
    </DashboardContainer>
  );
}

export default Settings;