import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { hidden_md } from "./cell_visibility"
function EnhancedEntitiesTableHead(props) {

    const { 
      order, 
      orderBy, 
      onRequestSort, 
      columns, 
      rowActions, 
      withDrawer,
      onPick, 
      onToggleAllSelected, 
      maxSelected, 
      numSelected,
      selectable,
      rowCount
    } = props;
    
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    console.log("HEAD: ", maxSelected, numSelected, rowCount);
    const disableToggleAll = () => {
      if(maxSelected) {
        if(rowCount > maxSelected) return true;
        else return false;
      } 
      return true;
    }
    const areAllSelected = rowCount === numSelected;
    
    
    return (
      <TableHead>
        <TableRow>
          {selectable &&

            <TableCell>
              
              <Checkbox
                color="primary"
                size={'small'}
                checked={areAllSelected}
                onChange={onToggleAllSelected}
                disabled={disableToggleAll()}
                inputProps={{
                  'aria-label': 'select all items',
                }}
              />

            </TableCell>
          }
          {withDrawer && 
            <TableCell></TableCell>
          }

          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
              sx={headCell.hidden}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                align="center"
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          { (rowActions || onPick) &&
            <TableCell sx={hidden_md}>Actions</TableCell>
          }
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedEntitiesTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  export default EnhancedEntitiesTableHead;