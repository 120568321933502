import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

const initialState = {
  allLoaded: false,
  user: null,
  status: "idle",
  error:null
}


export const login = createAsyncThunk('global/login', async (payload, thunkAPI) => {

  const response = await axios.post(ApiUrl + "users/auth", payload);
  return response.data;

})

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    allLoaded: (state, action) => {
      state.allLoaded = action.payload;
    },
    reset: state => initialState

  }

})

// Action creators are generated for each case reducer function
export const { allLoaded, saveContentRef, reset } = globalSlice.actions;

export const selectAllLoaded = state => state.global.allLoaded;

export default globalSlice.reducer;