import {NavLink as RouterLink,} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { selectLatestSales } from 'features/sales/salesSlice';
import parseDate from 'utils/parseDate';
import { useSelector } from 'react-redux';

const LatestSalesTable = (props) => {

  const latestSales = useSelector(selectLatestSales);

  return (
  <Card {...props}>
    <CardHeader title="Latest Sales" />
    <Divider />
    <PerfectScrollbar>
      <Box sx={{ minWidth: 650 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Order Ref
              </TableCell>
              <TableCell>
                Customer
              </TableCell>
              <TableCell sortDirection="desc">
                <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                    Date
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {latestSales.map((sale) => (
              <TableRow
                hover
                key={sale.id}
              >
                <TableCell>
                  INV{sale.invoice_no}
                </TableCell>
                <TableCell>
                  {sale.customers[0].name} {sale.customers[0].surname}
                </TableCell>
                <TableCell>
                  { parseDate(sale.created_at) }
                </TableCell>
                <TableCell>
                  <Chip
                    color="primary"
                    label={sale.balance_paid ? 'Paid' : 'Pending'}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
        component={RouterLink}
        to={'/dashboard/sales'}
      >
        View all
      </Button>
    </Box>
  </Card>
  );
}
export default LatestSalesTable;
