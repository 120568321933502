import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null}
}


export const fetchAddresses = createAsyncThunk('addresses/fetchAddresses', async () => {

  const response = await axios.get(ApiUrl + "addresses");
  return response.data

})

export const addressAdded = createAsyncThunk('addresses/addressAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "addresses/" + payload.id, payload);
  return response.data

})

export const addressUpdated = createAsyncThunk('addresses/addressUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "addresses/" + payload.id, payload);
  return response.data

})

export const addressDeleted = createAsyncThunk('addresses/addressDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "addresses/" + payload.id, payload);
  return response.data

})

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    addressesReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH CUSTOMERS
        .addCase(fetchAddresses.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchAddresses.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Addresses to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
         
        })
        .addCase(fetchAddresses.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE Address
        .addCase(addressAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(addressAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Addresses to the array
          state.items.push(action.payload);
        })
        .addCase(addressAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE Address
        .addCase(addressUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(addressUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(addressUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })

        // DELETE PRODUCT
        .addCase(addressDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(addressDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(addressDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })

  }
})

export const { addressesReload, reset } = addressesSlice.actions;

export const selectAddressById = (state, addressId) => {

  let foundItem = null;
  if(state.addresses.items.length > 0) {

    foundItem = state.addresses.items.find(address => address.id == addressId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}

export const selectAllAddresses = state => state.addresses.items;
export const selectAddressesCount = state => state.addresses.items.length;
export const selectAddressesById = (state, addressId) =>
                      state.addresses.items.find(address => address.id === addressId)

export default addressesSlice.reducer;