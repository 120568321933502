import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, Box } from '@mui/material';
import currencyFraction from 'utils/currencyFraction';
import ExpensesMonthItems from './ExpensesMonthItems';

export default function ExpensesMonthsAccordion(props) {

  const { months } = props;
  const [showDays, setShowDays] = React.useState(false);

  let sortedMonths = months.slice().sort((a,b) => a.id - b.id);
  return (
    <>
    { sortedMonths && sortedMonths.map(month => (
      <Accordion key={month.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box>
            {month.name}
          </Box>
          <Box marginRight={1}>
            TOTAL : {currencyFraction.format(month.total)}
          </Box>
          <Box marginRight={1}>
            VAT : {currencyFraction.format(month.vat)}
          </Box>
        </Box>
        </AccordionSummary>
        <AccordionDetails>

          <ExpensesMonthItems items={month.items} />
        </AccordionDetails>
      </Accordion>
      ))
    }
    { !months && <LinearProgress />}

    </>
  );
}
