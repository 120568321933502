import { useState, useRef, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import PageDashboard from '../../components/PageDashboard';
import LoadData from '../../views/Dashboard/LoadData'
import ScrollToTop from 'components/ScrollToTop'
import ContentContextProvider from 'layouts/ContentContextProvider'

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const contentRef = useRef(null);
    
  return (
    <PageDashboard>
     
        <DashboardLayoutRoot>
          <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <DashboardSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <DashboardLayoutWrapper>
            
            <DashboardLayoutContainer>
              <ContentContextProvider value={contentRef}>
                <DashboardLayoutContent>
                  <div ref={contentRef}></div>
                  <ScrollToTop />
                  <LoadData />
                  
                  <Outlet />
                </DashboardLayoutContent>
              </ContentContextProvider>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      
    </PageDashboard>
  );
};

export default DashboardLayout;
