import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';
import { parse } from 'date-fns';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null}
}


export const fetchStockins = createAsyncThunk('stockins/fetchStockins', async () => {

  const response = await axios.get(ApiUrl + "stockins");
  return response.data

})

export const stockinAdded = createAsyncThunk('stockins/stockinAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "stockins", payload);
  return response.data

})

export const stockinUpdated = createAsyncThunk('stockins/stockinUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "stockins/" + payload.id, payload);
  return response.data

})

export const stockinDeleted = createAsyncThunk('stockins/stockinDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "stockins/" + payload.id, payload);
  return response.data

})

export const stockinsSlice = createSlice({
  name: 'stockins',
  initialState,
  reducers: {
    stockinsReload: (state) => {
      state.status = "idle"
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH STOCKINS
        .addCase(fetchStockins.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchStockins.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Stockins to the array
          state.items = [];
          state.items = state.items.concat(action.payload.map(item => nullToString(item)));
        })
        .addCase(fetchStockins.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE STOCKIN
        .addCase(stockinAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(stockinAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Stockins to the array
          state.items.push(action.payload);
        })
        .addCase(stockinAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE STOCKIN
        .addCase(stockinUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(stockinUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(stockinUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })
        // DELETE STOCKIN
 
        .addCase(stockinDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(stockinDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(stockinDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })
  }
})

export const { stockinsReload, reset } = stockinsSlice.actions;

export const selectAllStockins = state => state.stockins.items;

export const selectNextStockinId = state => {
    
    if(state.stockins.items.length > 0) {
      const stockin = state.stockins.items.reduce((p, c) => parseInt(p.id) < parseInt(c.id) ? c : p);

      return stockin.id + 1;
    }
    else return 0;
}

export const selectNextStockinRef = state => {
    
  if(state.stockins.items.length > 0) {
    const stockin = state.stockins.items.reduce((p, c) => parseInt(p.id) < parseInt(c.id) ? c : p);

    if(stockin){
      
      let refNo = stockin.reference.replace(/\D/g, "");
      let newRef = parseInt(refNo) + 1;
      console.log(newRef);
      return "SN" + newRef;
    }
    return null;
  }
  else return null;
}
export const selectStockinById = (state, stockinId) => {

  let foundItem = null;
  if(state.stockins.items.length > 0) {

    foundItem = state.stockins.items.find(stockin => stockin.id == stockinId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}

export default stockinsSlice.reducer;