import * as React from 'react';
import {Box,Card,CardContent, LinearProgress, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import EntitiesTable from 'views/Dashboard/shared/EntitiesTable/EntitiesTable';
import currency from 'utils/currency';
import { hidden_xs } from "views/Dashboard/shared/EntitiesTable/cell_visibility"
import SalesMonthsAccordion from './SalesMonthsAccordion';
import { SalesMonthlyQuantityChart, SalesMonthlyTotalChart } from '../../charts';

const columns = [
  {
    id: 'id',

    disablePadding: true,
    label: 'Year',
    
  },
  {
    id: 'no_of_sales',

    disablePadding: false,
    label: 'Sales',

  },
  {
    id: 'no_of_caravans',
    hidden : hidden_xs,
    disablePadding: false,
    label: 'Caravans',
  },
  {
    id: 'total',

    disablePadding: false,
    label: 'Total',
    hidden : hidden_xs,
    parseFunction: currency.format
  },
 
];



const YearDrawer = (props) => {

  const { row } = props;


  return (
    <>
      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={12} md={6} order={{ xs:2, md:1 }}>
          <SalesMonthsAccordion months={row.months} />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs:1, md:2 }}>
          <SalesMonthlyQuantityChart year={row} />
          <SalesMonthlyTotalChart year={row}/>
        </Grid>
      </Grid>

    </>
  )

}

const SalesYearsList = (props) => {

  const { items } = props;
  const { enqueueSnackbar } = useSnackbar();
  
  return (
    <>
      { !items ?
        (
          <LinearProgress />
        ) : (

          <Box sx={{ width: '100%', mt:3,mb:5 }}>

            <Card>
            <CardContent>
                <EntitiesTable
                  entityName="years"
                  items={items}
                  columns={columns}
                  withDrawer={true}
                  renderDrawer={<YearDrawer />}

                />
              </CardContent>
            </Card>
          </Box>
        )}

    </>
  );
}

export default SalesYearsList;