import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
    event: null,
    items: [],
    status: "idle",
    error: null,
    editing: { status: 'idle', error: null },
    creating: { status: 'idle', error: null },
    deleting: { status: 'idle', error: null },

}

export const fetchEvents = createAsyncThunk('events/fetchEvents', async() => {

    const response = await axios.get(ApiUrl + "events");
    return response.data

})

export const eventAdded = createAsyncThunk('events/eventAdded', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.post(ApiUrl + "events", payload);
    return response.data

})

export const eventUpdated = createAsyncThunk('events/eventUpdated', async(payload, thunkAPI) => {

    console.log("UPDATE ACTION", payload);
    const response = await axios.put(ApiUrl + "events/" + payload.id, payload);
    return response.data

})

export const eventDeleted = createAsyncThunk('events/eventDeleted', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.delete(ApiUrl + "events/" + payload.id, payload);
    return response.data

})

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {

        reset: state => initialState

    },
    extraReducers(builder) {
        builder

        // FETCH events
            .addCase(fetchEvents.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                // Add any fetched Events to the array
                state.items = [];
                state.items = state.items.concat(action.payload.map(item => nullToString(item)));

                // if event logged in, and client reload, update session
                if (state.event) {

                    const index = state.items.findIndex(function(o) {
                        return o.id == state.event.id;
                    })

                    state.event = state.items[index];
                    sessionStorage.setItem('event', JSON.stringify(state.items[index]));
                }

            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // CREATE Event
            .addCase(eventAdded.pending, (state, action) => {
                state.creating.status = 'inprogress';
            })
            .addCase(eventAdded.fulfilled, (state, action) => {

            })
            .addCase(eventAdded.rejected, (state, action) => {
                state.creating.status = 'failed';
                state.creating.error = action.error.message;
            })
            // UPDATE Event
            .addCase(eventUpdated.pending, (state, action) => {
                state.editing.status = 'pending';
            })
            .addCase(eventUpdated.fulfilled, (state, action) => {

            })
            .addCase(eventUpdated.rejected, (state, action) => {
                state.editing.status = 'failed';
                state.editing.error = action.error.message;
            })
          
        // DELETE event
        .addCase(eventDeleted.pending, (state, action) => {
                state.deleting.status = 'pending';
            })
            .addCase(eventDeleted.fulfilled, (state, action) => {


            })
            .addCase(eventDeleted.rejected, (state, action) => {
                state.deleting.status = 'failed';
                state.deleting.error = action.error.message;
            })

    }

})

// Action creators are generated for each case reducer function
export const { reset } = eventsSlice.actions;

export default eventsSlice.reducer;