import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography

} from '@mui/material';

import { isEmpty } from 'underscore';


const AddressForm = ({editMode, model, onAction, onBack, onSave, submitLabel, formDisabled }) => {

    console.log("Address Form Render");
    const [formValues, setFormValues] = useState(model);
    const [touched, setTouched] = useState(false);
    const [formErrors, setFormErrors] = useState({
      address_line_1: "",
      town: "",
      postcode: "",

    });

    // update form fields if model changes
    React.useEffect(() => {
          setFormValues(model);
    
    }, [model])
    
    const handleChange = (event) => {
        
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value
        });
        setTouched(true);
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.address_line_1) errors.address_line_1 = emptyMsg;
      if(!values.town) errors.town = emptyMsg;
      if(!values.postcode) errors.postcode = emptyMsg;

      return errors;
    }

    const onFormSubmit = (event) => {
        
        const validate = validateForm(formValues);

        if(isEmpty(validate)) onAction(formValues);
        else setFormErrors({ ...validate });

    }

    const onSaveForm = () => {
      const validate = validateForm(formValues);

      if(isEmpty(validate)) {
        onSave(formValues);
        setTouched(false);
      }
      else setFormErrors({ ...validate });
    }

    return (
        <>
        <form
        autoComplete="off"

        >
          <fieldset disabled={ formDisabled ? "disabled" : false} style={{border:0}}>
          <Grid
            container
            spacing={3}
          >
         { formDisabled &&
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="pred">Editing is disabled.</Typography>
            </Grid>
            }
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Address line 1"
                name="address_line_1"
                onChange={handleChange}
                required
                value={formValues.address_line_1}
                variant="outlined"
                helperText={formErrors.address_line_1}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Address line 2"
                name="address_line_2"
                onChange={handleChange}
                required
                value={formValues.address_line_2}
                variant="outlined"
                helperText={formErrors.address_line_2}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Town"
                name="town"
                onChange={handleChange}
                required
                value={formValues.town}
                variant="outlined"
                helperText={formErrors.town}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Post Code"
                name="postcode"
                onChange={handleChange}
                required
                value={formValues.postcode}
                variant="outlined"
                helperText={formErrors.postcode}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="County"
                name="county"
                onChange={handleChange}
                required
                value={formValues.county}
                variant="outlined"
                helperText={formErrors.county}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Notes"
                name="extra_info"
                onChange={handleChange}
                required
                value={formValues.extra_info}
                variant="outlined"
                
              />
            </Grid>            
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="DB ID"
                name="id"
                disabled
                value={formValues.id}
                variant="outlined"
                helperText="Database reference"
              />
            </Grid>                        
          </Grid>
        </fieldset>
        <Box
          sx={{
            display: 'flex',
            justifyContent:'flex-end',
            flexDirection: "row",
            columnGap:'10px',
            p: 2
          }}
        >
        { onBack && !touched &&  
          <Button

            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          }
          { touched && 
          <Button

            color="primary"
            variant="outlined"
            onClick={onSaveForm}
          >
          Save
          </Button>
          }
          { !touched && 
          <Button

            color="primary"
            variant="outlined"
            onClick={onFormSubmit}
          >
            {submitLabel}
          </Button>
          }
        </Box>

      </form>
    </>
    );

}

export default AddressForm;