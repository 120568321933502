import * as React from 'react';

import {
    IconButton,
    Stack
  } from '@mui/material';


const TableRowActions = (props) => {
    const { row, actions } = props;

    return (
      <Stack direction="row" spacing={1} sx={{m:1}}>
        
        { actions.map(action => {
          return (
                <IconButton 
                    color="inherit" 
                    size="small" 
                    onClick={(event) => action.callback(event,row)} 
                    title={action.name}
                    key={action.id}
                    >
                  {action.icon}
                </IconButton>
                )
        })}

      </Stack>
    )
  }
  
  export default TableRowActions;