const Sale = {
  id : "",
  invoice_no: "",
  total: "",
  deposit: 0,
  extra_info: "",
  balance_paid: false,
  deposit_paid: false

}

export default Sale;