import * as React from 'react';
import { SubscriptionsNav, SubscriptionsList } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllSubscriptions} from 'features/subscriptions/subscriptionsSlice'
import ShowStats from './components/ShowStats';
import { Typography, Alert } from '@mui/material';

const Subscriptions = (props) => {

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllSubscriptions);

  return (

      <DashboardContainer>
        <SubscriptionsNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>

        { statsOpen && <ShowStats />}
        { items && 
        <>
          { items.length != 0 ? (

            <SubscriptionsList
              items={items}
              withFilters
              withActions
              withDrawer
            />

          ) : (
            <>
            <Alert severity="warning" sx={{mt:1}}>Nothing to show</Alert>
            </>
          )
          }
        </>
        }
      </DashboardContainer>

  );


}

export default Subscriptions;