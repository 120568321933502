import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BallotIcon from '@mui/icons-material/Ballot';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import NavItem from './NavItem';
import AccountProfile from 'views/Dashboard/Account/components/AccountProfile';
import { selectUser } from 'features/users/usersSlice';
import { useSelector } from 'react-redux';
import { Facebook } from '@mui/icons-material';
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/dashboard/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/dashboard/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/dashboard/stockins',
    icon: BallotIcon,
    title: 'Stock History'
  },
  {
    href: '/dashboard/sales',
    icon: PointOfSaleIcon,
    title: 'Sales'
  },
  {
    href: '/dashboard/facebook-stats',
    icon: Facebook,
    title: 'Facebook Stats'
  },
  {
    href: '/dashboard/events',
    icon: EventAvailableOutlinedIcon,
    title: 'Events Calendar'
  },
  {
    href: '/dashboard/expenses',
    icon: CreditCardIcon,
    title: 'Expenses'
  },
  {
    href: '/dashboard/enquires',
    icon: EmailIcon,
    title: 'Enquires'
  },
  {
    href: '/dashboard/subscriptions',
    icon: SubscriptionsIcon,
    title: 'Subscriptions'
  },
  {
    href: '/dashboard/users',
    icon: PeopleAltIcon,
    title: 'Users'
  },
  {
    href: '/dashboard/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/dashboard/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/dashboard/statistics',
    icon: AutoGraphIcon,
    title: 'Statistics'
  },
  {
    href: '/dashboard/distance',
    icon: AltRouteIcon,
    title: 'Distance'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Add User'
  },


];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
          <AccountProfile 
              user={user} 
              avatarHeight="50px"
              avatarWidth="50px"
          />

      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 2,
          p: 2
        }}
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          @ 2021
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Lincolnshire Statics
        </Typography>
       
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
