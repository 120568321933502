import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, Box, TableHead, IconButton, Collapse, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductDetailsTable, ProductFeaturedPhoto } from '.';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
const ProductsDetailsTableRow = (props) => {

    const { product, onDelete, onPick, withDrawer, withActions, rowColor} = props;
    const [open,setOpen] = React.useState(false);

    let columnsNo = 2;
    columnsNo = withDrawer ? ++columnsNo : columnsNo;
    columnsNo = withActions ? ++columnsNo : columnsNo;

    return (
        <>
        <TableRow hover onClick={() => setOpen(!open)} sx={{backgroundColor: rowColor}}>
                { withDrawer &&
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                }
                <TableCell>{product.make} {product.model}</TableCell>
                <TableCell>{ product.reference}</TableCell>
                { withActions &&
                    <>
                    { onDelete &&
                    <TableCell>
                        <IconButton color="inherit" size="small" onClick={(event) => onDelete(event, product)}>
                            <DeleteIcon sx={{color: (theme) => theme.palette.primary.main }}/>
                        </IconButton>
                    </TableCell>
                    }

                    { onPick &&
                    <TableCell>
                        <IconButton color="inherit" size="small" onClick={(event) => onPick(event, product)}>
                            <AddCircleIcon sx={{color: (theme) => theme.palette.primary.main, fontSize:17}}/>
                        </IconButton>
                    </TableCell>
                    }
                    </>
                }
        </TableRow>
        { withDrawer &&
        <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnsNo}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ mt:1,display:'flex',columnGap:0,border:"1px solid #ddd", flexWrap:"wrap"}}>
                        <Box sx={{ flexGrow:1,flexBasis:0,minWidth:"200px",width:{ xs: "100%", sm:"50%"}}}>
                            <ProductDetailsTable product={product} />
                        </Box>
                        <Box sx={{ flexGrow:1,flexBasis:0,minWidth:"200px",width:{ xs: "100%", sm:"50%"}}}>
                        { open && 
                            <ProductFeaturedPhoto
                                product={product}
                                size={{height:"300px", width:"100%"}}
                                resolution="640"
                                />
                        }
                        </Box>
                    </Box>
                    </Collapse>
                </TableCell>
        </TableRow>
        }
        </>
    )

}

const ProductsDetailsTable = (props) => {

    const { products, onDelete, onPick, withDrawer, withActions, withHeader, rowColor } = props;

    return (
            <>
            { products && products.length > 0 ?
            (
            <Table size="small" aria-label="Sold products details table" sx={{width:"100%"}} >
            { withHeader &&
            <TableHead>
                <TableRow>
                    { withDrawer && <TableCell></TableCell> }
                    <TableCell>Name</TableCell>
                    <TableCell>Ref</TableCell>
                    { withActions && <TableCell></TableCell> }
                </TableRow>
            </TableHead>
            }
            <TableBody>
                { products.map(product => <ProductsDetailsTableRow 
                                                product={product} 
                                                onDelete={onDelete}
                                                onPick={onPick}
                                                withDrawer={withDrawer}
                                                withActions={withActions} 
                                                key={product.id}
                                                rowColor={rowColor}
                                                 />)
                }

            </TableBody>
            </Table>
            ) : <Box sx={{p:1,border:1, borderColor:"#f2f2f2", mt:1}}><Typography variant="pred">Nothing to display.</Typography></Box>
        }
        </>       
    )
}

export default ProductsDetailsTable;