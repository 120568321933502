import React from 'react';
import {
  NavLink as RouterLink,

} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import parseDate from 'utils/parseDate';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';

const LatestVisitorsTable = (props) => {

  const [visitorsData, setVisitorsData] = React.useState([]);

  const abortCtrl = new AbortController();
  const today = new Date();

  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth()+1;


  React.useEffect(() => {

    const getData = async () => {
      await axios.post(ApiUrl + 'stats/getVisitorsForMonth',{ month: thisMonth, year: thisYear},{ signal: abortCtrl.signal})
        .then(response => {

          if(response.data) setVisitorsData(response.data);

        })
        .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING browser chart:");
      abortCtrl.abort();
    }

  },[]);

  return (
  <Card {...props}>
    <CardHeader title="Last 7 days visits" />
    <Divider />

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Visitors
              </TableCell>
              <TableCell sortDirection="desc">
                <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                    Visits
                  </TableSortLabel>
                </Tooltip>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            { visitorsData && visitorsData.slice(0,7).map((day) => (
              <TableRow
                hover
                key={day.id}
              >
                <TableCell>
                  {day.id}/{thisMonth}
                </TableCell>
                <TableCell>
                  {day.total}
                </TableCell>
                <TableCell>
                  ---
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
        component={RouterLink}
        to={'/dashboard/statistics'}
      >
        View all
      </Button>
    </Box>
  </Card>
  );
}
export default LatestVisitorsTable;
