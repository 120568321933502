import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIcon from '@mui/icons-material/Phone';
import TabletIcon from '@mui/icons-material/Tablet';
import { ApiUrl } from 'features/api_url';
import axios from 'axios';

const TrafficByDevice = (props) => {
  
  const theme = useTheme();
  const [deviceData, setDeviceData] = React.useState({ mobile: 0, desktop:0, tablet:0});

  const abortCtrl = new AbortController();

  React.useEffect(() => {

    const getData = async () => {
      await axios.get(ApiUrl + 'stats/visitorsByDevice',{ signal: abortCtrl.signal})
        .then(response => {

          if(response.data) setDeviceData(response.data);

        })
        .catch((err) => console.log('ABORTING: ', err));
      
    }

    getData();

    return () => {
      console.log("UNMOUNTING device chart:");
      abortCtrl.abort();
    }

  },[]);

  const deviceTotal = deviceData.mobile + deviceData.desktop + deviceData.tablet;
  const mobilePercent = ((deviceData.mobile/deviceTotal) * 100).toFixed(1);
  const desktopPercent = ((deviceData.desktop/deviceTotal) * 100).toFixed(1);
  const tabletPercent = ((deviceData.tablet/deviceTotal) * 100).toFixed(1);

  const data = {
    datasets: [
      {
        data: [deviceData.desktop, deviceData.tablet, deviceData.mobile],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Desktop', 'Tablet', 'Mobile']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Desktop',
      value: desktopPercent,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Tablet',
      value: tabletPercent,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Mobile',
      value: mobilePercent,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ];

  return (
    <Card {...props} sx={{height:'100%'}}>
      <CardHeader title="Website traffic by device" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
