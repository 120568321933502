import Stockins from './Stockins';
import {CreateStockinForm, EditStockinForm} from './components';
import AuthRole from 'layouts/Dashboard/AuthRole';
import ViewPdf from '../shared/ViewPdf';
const routes = [

  { path: 'stockins', element: <Stockins />},
  { path: 'stockins/create', element: <AuthRole role="administrator" isPage><CreateStockinForm /></AuthRole>},
  { path: 'stockins/edit/:id', element: <AuthRole role="moderator" isPage><EditStockinForm /></AuthRole>},
  { path: 'stockins/pdf/:id', element: <AuthRole role="moderator" isPage><ViewPdf type="stockin" /></AuthRole>}
]

  export default routes;