import * as React from 'react';
import { Box,Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { 
    selectUsersCount, 


    } from "features/users/usersSlice"
import FigureBox from "../../shared/FigureBox"
const ShowStats = (props) => {

    const allUsersCount = useSelector(selectUsersCount);


    return(
        <>
        <Box sx={{ width: '100%',mt: 3 }}>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <FigureBox 
                        label="Total Users"
                        figure={allUsersCount}
                        avatarColor="green"
                        />
                </Grid>
               
            </Grid>
        </Box>
        
        </>
    )

}

export default ShowStats;