import * as React from 'react';
import { useParams } from 'react-router-dom';
import { StockinsNav, StockinsList, ShowStats } from './components'
import { DashboardContainer } from '../components';
import { useSelector } from 'react-redux'
import { selectAllStockins } from 'features/stockins/stockinsSlice';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';
const Stockins = function(props){

  const [statsOpen,setStatsOpen] = React.useState(true);
  const items = useSelector(selectAllStockins);

  return (

    <DashboardContainer>
      <StockinsNav onStats={setStatsOpen} isStatsOpen={statsOpen}/>
      { statsOpen && <ShowStats />}
      { items.length != 0 ? (

        <StockinsList
          items={items}
        />

      ) : (

        <CenteredCircularProgress />

      )
    }
    </DashboardContainer>

);


}

export default Stockins;