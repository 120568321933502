import axios from 'axios';
import * as React from 'react'
import { Typography, Box, Card, CardContent } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { ApiUrl } from 'features/api_url';
import { SingleMonthSalesTable, SalesYearsAccordion } from './components';
import { Grid } from '@mui/material';
import { selectAllSalesYears } from 'features/statistics/statisticsSlice';
import { useSelector } from 'react-redux';
import SalesYearsList from './components/SalesYearList';
import { SalesYearlyQuantityChart, SalesYearlyTotalChart } from '../charts';
const SalesStats = (props) => {

    const [period, setPeriod] = React.useState({});
    const allYears = useSelector(selectAllSalesYears);

    const today = new Date();

    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;
    const lastMonth = thisMonth == 1 ? 12 : thisMonth-1;
    const lastYear = thisMonth == 1 ? thisYear-1 : thisYear;

    console.log(allYears);
  
    return (
        <>
        { allYears.length > 0 ? (
            <Grid 
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{ mt: 1,mb:1,display:'flex',flexDirection:{ xs: 'column-reverse', md: 'row'},columnGap:2,rowGap:2, flexWrap:"wrap"}}>
                        <Box sx={{ flexGrow:1,flexBasis:0, minwidth:"200px",width:{ "xs": "100%", "md":"50%"}}}>
                        
                        <Box>
                            <Card>
                                <CardContent>
                                    <SalesYearlyTotalChart years={allYears} />
                                </CardContent>
                            </Card>
                        </Box>
                        </Box>

                        <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"200px",width:{ "xs": "100%", "md":"50%"}}}>

                        <Box>
                            <Card>
                                <CardContent>
                                    <SalesYearlyQuantityChart years={allYears} />
                                </CardContent>
                            </Card>
                        </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <SalesYearsList items={allYears} />
                        
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <SingleMonthSalesTable month={thisMonth} year={thisYear} title={"This month sales"} />
                        
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <SingleMonthSalesTable month={lastMonth} year={lastYear} title={"Last month sales"} />
                        
                </Grid>
            </Grid>
            
        ) : (
            <LinearProgress />
        )
        }
        </>
    );
}

export default SalesStats;