import * as React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material';
import currency from 'utils/currency';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parseTime from 'utils/parseTime';


const DayVisitorsTable = (props) => {

    const { days, handleDialogOpen } = props;
    return (
        <>
        {days.map(day=> (
            <Accordion key={day.id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box>
                        {day.id}
                    </Box>
                    <Box marginRight={1}>
                        TOTAL : {day.total}
                    </Box>
                </Box>
                </AccordionSummary>
              
            </Accordion>
            
        ))}
 
        </>
    );
}

export default DayVisitorsTable;