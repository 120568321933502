import React from 'react';
import { Box, Card, CardContent, Grid, Alert, Typography, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FBContext from 'components/facebook/FBContext';
import { selectProductById } from 'features/products/productsSlice';
import ProductPhotosList from './ProductPhotosList';
import { ProductDetailsTable, ProductFeaturedPhoto } from 'views/Dashboard/shared';
import PostProductOnFacebook from './PostProductOnFacebook';
import { isEqual } from 'lodash';
import { DashboardContainer } from 'views/Dashboard/components';
import { display } from '@mui/system';
import { makeImgUrl } from 'features/api_url';
import { ProductActions } from './actions';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TableRowActions from 'views/Dashboard/shared/TableRowActions';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import ProductFacebookPostsList from './ProductFacebookPostsList';
import Image from 'components/Image';
export default function ViewProduct(props){

    const FB = React.useContext(FBContext);

    const { id } = useParams();
    const dispatch = useDispatch();
    const enqueueSnackbar = useSnackbar();
    const navigate = useNavigate();

    const product = useSelector((state) => selectProductById(state, id), isEqual);
    
    return (
      <DashboardContainer>
        { product === undefined && <Alert severity="error">Product with given ID does not exist.</Alert> }
        { product &&
        <>
          <Card sx={{mt:2}}>
            <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                  <Image 
                    src={ product.images?.length > 0 ? makeImgUrl(product.id,1024,product.images[0].url) : "/static/images/image_placeholder.jpeg"} 
                    alt={ product.images?.length > 0 ? (product.images[0].title) : "No Image" } 
                    style={{width:"100%"}} 
                  />

                  <TableRowActions row={product} actions={ProductActions(dispatch, navigate, enqueueSnackbar)} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ProductDetailsTable product={product} />
                    <Box sx={{p:2}}>
                      Published: <FiberManualRecordIcon sx={{ color: product.published ? "green" : "red",fontSize:"12px",mr:1 }}  />
                      Featured: <FiberManualRecordIcon sx={{ color: product.featured ? "green" : "red",fontSize:"12px",mr:1 }}  />
                      Special: <FiberManualRecordIcon sx={{ color: product.sticky ? "green" : "red",fontSize:"12px",mr:1 }}  />

                    </Box>
                    
                  </Grid>
                 
                </Grid>
              
            </CardContent>
          </Card>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{mt:2,mb:2}}>
                  <CardContent>
                    <Typography variant={'h4'}>Description</Typography>
                    <Typography variant={'body2'}>{product.extra_info || 'This product has no description yet' }</Typography>
                    <Button 
                    sx={{mt:1}}
                      variant="outlined" 
                      color="primary" 
                      onClick={() => navigate('/dashboard/products/edit/'+product.id)}>
                        Edit
                    </Button>
                  </CardContent>
              </Card>
            
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{mt:2,mb:2}}>
                <CardContent>
                  <PostProductOnFacebook product={product} />
                  <ProductFacebookPostsList product={product} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Card sx={{mt:2}}>
            <CardContent>
              <ProductPhotosList product={product} />
            </CardContent>
          </Card>

      </>
      }
      </DashboardContainer>
    )
   
}