import * as React from 'react';
import { Grid, Card, CircularProgress, Typography, Box } from '@mui/material';
import { uniqueId } from 'lodash';
import { makeImgUrl } from 'features/api_url';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { productRemovedPhoto } from 'features/products/productsSlice';
import { useDispatch } from 'react-redux';

const ProductPhotosList = (props) => {

    const { product } = props;
    const dispatch = useDispatch();

    const onDelete = (photoInfo) => {

        console.log(photoInfo);
        dispatch(productRemovedPhoto(photoInfo));

    }
    return(
        <>
        { !product && <CircularProgress />}
        { product &&
        <>
        <Typography variant="h4" sx={{mb:2}}>Product images</Typography>
        <Grid
            container
            spacing={3}
        >
            { product.images.map((photo,index) => {
                return(
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        key={uniqueId()}
                    >
                        <Card sx={{ backgroundColor:"#f2f2f2",height:"100px;", position:"relative"}}>
                            <img src={makeImgUrl(product.id,"320",photo.url)} alt={photo.title} width="100%"/>
                            <IconButton
                                aria-label="delete" 
                                sx={{position:"absolute",top:"5px",right:"5px",color:"white"}}
                                onClick={() => onDelete({ photoId: photo.id, productId: product.id})}>
                                    <DeleteIcon />
                            </IconButton>
                        </Card>
                    </Grid>
                    )
                })}
        </Grid>
        <Box sx={{mt:4}}>
            { product.images.length == 0 && <Typography variant="pgreen">Uploaded images will be shown here</Typography>}
        </Box>
        </>
        }
        </>
    )
}

export default ProductPhotosList;