import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  user: null,
  items:[],
  status: "idle",
  loginStatus: "idle",
  loginError: null,
  registerStatus: "idle",
  registerError: null,
  error:null,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null},
  addingRole: { status:'idle', error: null},
  removingRole: { status:'idle', error: null},
}


export const login = createAsyncThunk('users/login', async (payload, { rejectWithValue }) => {

  try{

    const response = await axios.post(ApiUrl + "users/auth", payload);
    return response.data;

   } catch(err){

     return rejectWithValue(err.response.data);
     
   }

})

export const register = createAsyncThunk('users/register', async (payload, { rejectWithValue }) => {

  try{

    const response = await axios.post(ApiUrl + "users/register", payload);
    return response.data;

   } catch(err){

     return rejectWithValue(err.response.data);
     
   }

})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {

  const response = await axios.get(ApiUrl + "users");
  return response.data

})

export const userAdded = createAsyncThunk('users/userAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "users", payload);
  return response.data

})

export const userUploadedPhoto = createAsyncThunk('users/uploadedPhoto', async (payload, {rejectWithValue}) => {

  console.log(payload);

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try{

    const response = await axios.post( ApiUrl + "users/uploadPhoto", payload, config);
    return response.data

   } catch(err){

     return rejectWithValue(err.response.data);
     
   }


})

export const userUpdated = createAsyncThunk('users/userUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "users/" + payload.id, payload.user);
  return response.data

})

export const userDeleted = createAsyncThunk('users/userDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "users/" + payload.id, payload);
  return response.data

})

export const userRoleAdded = createAsyncThunk('users/userRoleAdded', async (payload, {rejectWithValue}) => {

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try{

    const response = await axios.post(ApiUrl + "users/addRoleToUser", payload);
    return response.data

   } catch(err){

     return rejectWithValue(err.response.data);
     
   }
 

})

export const userRoleRemoved = createAsyncThunk('users/userRoleRemoved', async (payload, {rejectWithValue}) => {

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try{

    const response = await axios.post(ApiUrl + "users/removeRoleFromUser", payload);
    return response.data

   } catch(err){

     return rejectWithValue(err.response.data);
     
   }

})

export const globalSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("ACTION PALOAD ", action.payload);
      state.user = { ...action.payload, password:"" };
    },
    logout: (state, action) => {
      state.user = null;
      sessionStorage.removeItem("user");
    },
    reset: state => initialState

  },
  extraReducers(builder) {
    builder
      // LOG IN USER
      .addCase(login.pending, (state, action) => {
        state.loginStatus = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = 'succeeded';
        state.loginError = null;

        //console.log("REDUCER: ", action.payload);
        // 
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatus = 'failed';
        state.loginError = action.error.message;
        //console.log("REDUCER: ", action.payload);
      })
      // REGISTER USER
      .addCase(register.pending, (state, action) => {
        state.registerStatus = 'loading';
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registerStatus = 'succeeded';
        state.registerError = null;
        //console.log("REDUCER: ", action.payload);
        // 
      })
      .addCase(register.rejected, (state, action) => {
        state.registerStatus = 'failed';
        state.registerError = action.payload.status;
      })
      // FETCH USERS
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        // Add any fetched Users to the array
        state.items = [];
        state.items = state.items.concat(action.payload.map(item => nullToString(item)));

        // if user logged in, and client reload, update session
        if(state.user) {

          const index = state.items.findIndex(function(o){
            return o.id == state.user.id;
          })
          
          state.user = state.items[index];
          sessionStorage.setItem('user', JSON.stringify(state.items[index]));
        }
      
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // CREATE User
      .addCase(userAdded.pending, (state, action) => {
        state.creating.status = 'inprogress';
      })
      .addCase(userAdded.fulfilled, (state, action) => {
        state.creating.status = 'succeeded';
        state.creating.error = null;
        // Add any fetched Users to the array
        state.items.push(action.payload);
      })
      .addCase(userAdded.rejected, (state, action) => {
        state.creating.status = 'failed';
        state.creating.error = action.error.message;
      })
      // UPDATE User
      .addCase(userUpdated.pending, (state, action) => {
        state.editing.status = 'pending';
      })
      .addCase(userUpdated.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items[index] = action.payload;
        state.editing.status = 'success';

        if(action.payload.id == state.user.id) {
          state.user = action.payload;
          sessionStorage.setItem('user', JSON.stringify(action.payload));
        }

        // state.items.push(action.payload)
      })
      .addCase(userUpdated.rejected, (state, action) => {
        state.editing.status = 'failed';
        state.editing.error = action.error.message;
      })

      // DELETE user
      .addCase(userDeleted.pending, (state, action) => {
        state.deleting.status = 'pending';
      })
      .addCase(userDeleted.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items.splice(index,1);
        state.deleting.status = 'success';
        
      })
      .addCase(userDeleted.rejected, (state, action) => {
        state.deleting.status = 'failed';
        state.deleting.error = action.error.message;
      })
      // UPLOAD PROFILE PHOTO
      .addCase(userUploadedPhoto.pending, (state, action) => {
        state.uploading.status = 'pending';
      })
      .addCase(userUploadedPhoto.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })
        console.log("Photo upload index ", index);
        console.log(action.payload);
        state.items[index] = action.payload;
        state.uploading.status = 'success';

        if(action.payload.id == state.user.id) {
          state.user = action.payload;
          sessionStorage.setItem('user', JSON.stringify(action.payload));
        }
        
      })
      .addCase(userUploadedPhoto.rejected, (state, action) => {
        state.uploading.status = 'failed';
        state.uploading.error = action.error.message;
      })
      // ADD ROLE
      .addCase(userRoleAdded.pending, (state, action) => {
        state.addingRole.status = 'inprogress';
      })
      .addCase(userRoleAdded.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items[index] = action.payload;
        state.addingRole.status = 'success';

        if(action.payload.id == state.user.id) {
          state.user = action.payload;
          sessionStorage.setItem('user', JSON.stringify(action.payload));
        }
      })
      .addCase(userRoleAdded.rejected, (state, action) => {
        state.addingRole.status = 'failed';
        state.addingRole.error = action.error.message;
      })
      // REMOVE ROLE
      .addCase(userRoleRemoved.pending, (state, action) => {
        state.removingRole.status = 'inprogress';
      })
      .addCase(userRoleRemoved.fulfilled, (state, action) => {
        const index = state.items.findIndex(function(o){
          return o.id == action.payload.id;
        })

        state.items[index] = action.payload;
        state.removingRole.status = 'success';

        if(action.payload.id == state.user.id) {
          state.user = action.payload;
          sessionStorage.setItem('user', JSON.stringify(action.payload));
        }
      })
      .addCase(userRoleRemoved.rejected, (state, action) => {
        state.removingRole.status = 'failed';
        state.removingRole.error = action.error.message;
      })
  }

})

// Action creators are generated for each case reducer function
export const { logout, setUser, reset } = globalSlice.actions;

export const selectAllUsers = state => state.users.items;
export const selectUsersCount = state => state.users.items.length;
export const selectUserById = (state, userId) => {

  let foundItem = null;
  if(state.users.items.length > 0) {
    console.log("USER PRESENT");
    foundItem = state.users.items.find(user => user.id == userId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}
export const selectRegisterError = state => state.users.registerError;
export const selectUser = state => state.users.user;
export const loadingStatus = state => state.users.status
export default globalSlice.reducer;