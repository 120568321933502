import * as React from 'react';
import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { userRoleAdded, userRoleRemoved } from 'features/users/usersSlice';
import { checkRole } from 'utils/checkRole';
import { set } from 'date-fns';

const UserRoleButton = ({ role, user }) => {

    console.log("User role button Form Render");
    const [roleState, setRoleState] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {

      const ns = checkRole(user,role.name);
      setRoleState(ns);

    },[user]);

      const handleChange = () => {
      
      let newState = !roleState;

      if(role.name == "administrator" && !newState){
        if(!window.confirm("This person will loose admin priviliges. Are you sure ?")) return false;
      }  
      const data = { user_id: user.id, role_id: role.id};
      setLoading(true);
      if(newState) dispatch(userRoleAdded(data)).then(result => {
        if(result.type === "users/userRoleAdded/fulfilled"){
            setLoading(false);
            setError(null);
        }
        if(result.type === "users/userRoleAdded/rejected"){
           console.log("ERR RESUT:", result);
            setError(result.payload.status || "Something gone wrong");
            setLoading(false);
        }
      });

      else dispatch(userRoleRemoved(data)).then(result => {
        if(result.type === "users/userRoleRemoved/fulfilled"){
            setLoading(false);
            setError(null);
        }
        if(result.type === "users/userRoleRemoved/rejected"){
           console.log("ERR RESUT:", result);
            setError(result.payload.status || "Something gone wrong");
            setLoading(false);
        }
      });
    }

    return (
      <>
      <Button 
        fullWidth 
        variant={roleState ? "contained" : "outlined"} 
        size="small" 
        onClick={handleChange} >
          {loading ? "Setting..." : role.name}
      </Button>
      {error && <Typography variant="psmallred" component="p">{error}</Typography>}
      </>
      )
}

export default UserRoleButton;