import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function EnquiresNav(props) {

    const { onStats, isStatsOpen } = props;

    return ( <
        Box sx = {
            {
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
            }
        } >
        <
        Box >
        <
        Link to = ".." >
        <
        Button sx = {
            { mr: "auto" } }
        color = "success"
        variant = "outlined"
        size = "small" >
        Back <
        /Button> <
        /Link> <
        /Box>

        <
        Box sx = {
            { display: "flex", columnGap: 1 } } >
        <
        Button size = "small"
        variant = "text"
        onClick = {
            () => onStats(!isStatsOpen) } > { isStatsOpen ? "Hide stats" : "Show stats" } < /Button>

        <
        Link to = "create" >
        <
        Button variant = "outlined"
        size = "small"
        startIcon = { < AddCircleOutlineIcon / > } >
        Add <
        /Button> <
        /Link>

        <
        /Box>

        <
        /Box>
    )

}