const Product = {
  id : "",
  make : "",
  model : "",
  color : "Beige",
  length : "",
  width : "",
  price : "",
  year: "",
  condition : "Excellent",
  reference : "",
  extra_info: "",
  description: "",
  dg : false,
  ch : false,
  beds: "",
  sale_id : "",
  featured: false,
  sticky: false,
  published: false,
  location: ""
}

export default Product;