const Expense = {
    id: "",
    description: "",
    ex_type: "",
    ex_date: "",
    supplier: "",
    amount: 0,
    creator: "",
    vat: 0,
    created_at: "",
    updated_at: ""
  }

  export default Expense;