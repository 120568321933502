import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router';

export default function ProductsNav(props){

    const { onStats, isStatsOpen } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handlePrint = (name) => {
      setAnchorEl(null);
      navigate("/print/productsList/" + name);
    };
    return(
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 1
            }}
      >
        <Box>
          <Link to="../">
            <Button
              sx={{ mr: "auto" }}
              color="success"
              variant="outlined"
              size="small" 
            >
              Back
            </Button>
          </Link>
        </Box>

        <Box sx={{ display:"flex",columnGap: 1 }}>
          <Button size="small" variant="text" onClick={() => onStats(!isStatsOpen)}>{ isStatsOpen ? "Hide stats" : "Show stats" }</Button>
          <Button
            variant="outlined" 
            size="small"
            id="print-button"
            aria-controls={open ? 'print-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<PrintIcon />}
          >
          Print
        </Button>
        <Menu
          id="print-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'print-button',
          }}
        >
          <MenuItem onClick={() => handlePrint('all')}>All</MenuItem>
          <MenuItem onClick={() => handlePrint('available')}>Available</MenuItem>
          <MenuItem onClick={() => handlePrint('sold')}>Sold</MenuItem>
        </Menu>
        <Link to="create">
            <Button variant="outlined" size="small" startIcon={<AddCircleOutlineIcon />}>
              Add
            </Button>
          </Link>
      </Box>

    </Box>
    )

}