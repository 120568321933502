import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, Box } from '@mui/material';
import currency from 'utils/currency';
import DayVisitorsTable from './DayVisitorsTable';
import { VisitorsDailyTotalChart } from '../../charts';


export default function VisitorsMonthsAccordion(props) {

  const { months, handleDialogOpen } = props;
  const [showDays, setShowDays] = React.useState(false);

  let sortedMonths = months.slice().sort((a,b) => a.id - b.id);
  return (
    <>
    { sortedMonths && sortedMonths.map(month => (
      <Accordion key={month.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box>
            {month.name}
          </Box>
          <Box marginRight={1}>
            TOTAL : {month.total}
          </Box>
        </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width={"95%"}>
            <VisitorsDailyTotalChart days={month.days} />
          </Box>
          <Box sx={{marginY:1}}>
            {showDays ? (
            <DayVisitorsTable days={month.days} handleDialogOpen={handleDialogOpen} />
            ) : ( <Button variant="outlined" onClick={() => setShowDays(true)} size={'small'} fullWidth >Show per day</Button>)
            }
          </Box>
          
        </AccordionDetails>
      </Accordion>
      ))
    }
    { !months && <LinearProgress />}

    </>
  );
}
