import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextareaAutosize
} from '@mui/material';

import { makeOptions, condition, color } from './data'
import { isEmpty } from 'underscore';


const ProductForm = ({editMode, model, onAction }) => {


    console.log("Product Form Render");
    const [formValues, setFormValues] = useState(model);
    const [formErrors, setFormErrors] = useState({
      make: "",
      model: "",
      reference: "",
      width: "",
      length: "",
      beds:"",
      price:""

    });
    
    const handleChange = (event, newValue) => {

        const numberFields = ['length','width','beds', 'price','year'];

        let value = event.target.name == "dg" || event.target.name == "ch" || event.target.name == "featured" || event.target.name == "published" || event.target.name == "sticky" ? event.target.checked : event.target.value;
      
        if(numberFields.includes(event.target.name)) {
            value = parseFloat(event.target.value);

            // if field goes to empty, make empty string to avoid uncontrolled input
            if(!value && value != 0) value="";
        }
        

        setFormValues({
          ...formValues,
          [event.target.name]: value
        });
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.make) errors.make = emptyMsg;
      if(!values.model) errors.model = emptyMsg;
      if(!values.beds) errors.beds = emptyMsg;
      if(!values.price) errors.price = emptyMsg;
      if(!values.reference) errors.reference = emptyMsg;
      if(!values.length) errors.length= emptyMsg;
      if(!values.width) errors.width = emptyMsg;

      return errors;
    }

    const onFormSubmit = (event) => {
        
        const validate = validateForm(formValues);

        if(isEmpty(validate)) onAction(formValues);
        else setFormErrors({ ...validate });

    }

    return (
        <>
        <form
        autoComplete="off"

        >
        <Card>
        
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                <Autocomplete
                  disablePortal
                  freeSolo
                  name="make"
                  label="Make"
                  id="combo-box-demo"
                  options={makeOptions}
                  value={formValues.make}
                  //inputValue={formValues.make}
                  
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Make" helperText={formErrors.make}/>}
                  onChange={(event, value) => setFormValues({...formValues, make: value})}
                  onInputChange={(event, value) => setFormValues({...formValues, make: value})}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Model of the caravan"
                  name="model"
                  onChange={handleChange}
                  required
                  value={formValues.model}
                  variant="outlined"
                  helperText={formErrors.model}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Length"
                  name="length"
                  onChange={handleChange}
                  required
                  value={formValues.length}
                  variant="outlined"
                  helperText={formErrors.length}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Width"
                  name="width"
                  onChange={handleChange}
                  required
                  value={formValues.width}
                  variant="outlined"
                  helperText={formErrors.width}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Beds"
                  name="beds"
                  onChange={handleChange}
                  required
                  value={formValues.beds}
                  variant="outlined"
                  helperText={formErrors.beds}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Year"
                  name="year"
                  onChange={handleChange}
                  required
                  value={formValues.year}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Ref."
                  name="reference"
                  onChange={handleChange}
                  required
                  value={formValues.reference}
                  variant="outlined"
                  helperText={formErrors.reference}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
               <TextField
                  fullWidth
                  label="Color"
                  name="color"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.color}
                  variant="outlined"
                >
                  {color.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  onChange={handleChange}
                  required
                  value={formValues.price}
                  variant="outlined"
                  helperText={formErrors.price}
                />
              </Grid>
             
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Condition"
                  name="condition"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.condition}
                  variant="outlined"
                >
                  {condition.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  onChange={handleChange}
                  required
                  value={formValues.location}
                  variant="outlined"
                  helperText={formErrors.location}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  aria-label="Product Description"
                  multiline
                  minRows={4} 
                  placeholder="Product information"
                  label="Description"
                  name="extra_info"
                  onChange={handleChange}
                  required
                  fullWidth
                  value={formValues.extra_info}
                  variant="outlined"
                  maxRows={5}
                />
              </Grid>

              <Grid 
                item
                xs={6}
                md={2}
                        
              >
                <FormControlLabel
                  control={<Switch checked={formValues.dg} onChange={handleChange} name="dg" />}
                  label="DG"
                />
              </Grid>
              <Grid 
                item
                xs={6}
                md={2}
              >
                <FormControlLabel
                  control={<Switch checked={formValues.ch} onChange={handleChange} name="ch" />}
                  label="CH"
                />
              </Grid>
              <Grid 
                item
                xs={6}
                md={2}
                        
              >
                <FormControlLabel
                  control={<Switch checked={formValues.published} onChange={handleChange} name="published" />}
                  label="Published"
                />
              </Grid>
              <Grid 
                item
                xs={6}
                md={2}
              >
                <FormControlLabel
                  control={<Switch checked={formValues.featured} onChange={handleChange} name="featured" />}
                  label="Featured"
                />
              </Grid>
              <Grid 
                item
                xs={6}
                md={2}
                        
              >
                <FormControlLabel
                  control={<Switch checked={formValues.sticky} onChange={handleChange} name="sticky" />}
                  label="Sticky"
                />
              </Grid>
            
             
                
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent:'flex-end',
              flexDirection: "row",
              columnGap:'10px',
              p: 2
            }}
          >

            <Button
  
              color="primary"
              variant="contained"
              onClick={onFormSubmit}
            >
              Save
            </Button>
            <Button
  
              color="primary"
              variant="contained"
 
            >
              Cancel
            </Button>

          </Box>
        </Card>
      </form>
    </>
    );

}

export default ProductForm;