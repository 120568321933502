import { configureStore } from '@reduxjs/toolkit';
import stockinsReducer from 'features/stockins/stockinsSlice';
import productsReducer from 'features/products/productsSlice';
import customersReducer from 'features/customers/customersSlice';
import addressesReducer from 'features/addresses/addressesSlice';
import salesReducer from 'features/sales/salesSlice';
import globalReducer from 'features/global/globalSlice';
import cartReducer from 'features/cart/cartSlice';
import usersReducer from 'features/users/usersSlice';
import rolesReducer from 'features/roles/rolesSlice';
import loginHistoryReducer from 'features/login_history/loginHistorySlice';
import statisticsReducer from 'features/statistics/statisticsSlice';
import expensesReducer from 'features/expenses/expensesSlice';
import enquiresReducer from 'features/enquires/enquiresSlice';
import subscriptionsReducer from 'features/subscriptions/subscriptionsSlice';
import eventsReducer from 'features/events/eventsSlice';
import facebookPostsReducer from 'features/facebookposts/facebookposts';
export const store = configureStore({
    reducer: {
        stockins: stockinsReducer,
        products: productsReducer,
        sales: salesReducer,
        expenses: expensesReducer,
        enquires: enquiresReducer,
        subscriptions: subscriptionsReducer,
        events: eventsReducer,
        global: globalReducer,
        cart: cartReducer,
        customers: customersReducer,
        addresses: addressesReducer,
        users: usersReducer,
        roles: rolesReducer,
        loginHistory: loginHistoryReducer,
        statistics: statisticsReducer,
        facebookposts: facebookPostsReducer
    },
})
