import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
    subscription: null,
    items: [],
    status: "idle",
    error: null,
    editing: { status: 'idle', error: null },
    creating: { status: 'idle', error: null },
    deleting: { status: 'idle', error: null },

}

export const fetchSubscriptions = createAsyncThunk('subscriptions/fetchSubscriptions', async() => {

    const response = await axios.get(ApiUrl + "subscriptions");
    return response.data

})

export const subscriptionUnsubscribed = createAsyncThunk('subscriptions/subscriptionUnsubscribed', async(payload, thunkAPI) => {

    const response = await axios.post(ApiUrl + "subscriptions/markAsSeen",payload);
    return response.data

})

export const subscriptionAdded = createAsyncThunk('subscriptions/subscriptionAdded', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.post(ApiUrl + "subscriptions", payload);
    return response.data

})

export const subscriptionUpdated = createAsyncThunk('subscriptions/subscriptionUpdated', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.put(ApiUrl + "subscriptions/" + payload.id, payload.subscription);
    return response.data

})

export const subscriptionDeleted = createAsyncThunk('subscriptions/subscriptionDeleted', async(payload, thunkAPI) => {

    console.log(payload);
    const response = await axios.delete(ApiUrl + "subscriptions/" + payload.id, payload);
    return response.data

})

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {

        reset: state => initialState

    },
    extraReducers(builder) {
        builder

        // FETCH subscriptions
            .addCase(fetchSubscriptions.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                // Add any fetched Subscriptions to the array
                state.items = [];
                state.items = state.items.concat(action.payload.map(item => nullToString(item)));

                // if subscription logged in, and client reload, update session
                if (state.subscription) {

                    const index = state.items.findIndex(function(o) {
                        return o.id == state.subscription.id;
                    })

                    state.subscription = state.items[index];
                    sessionStorage.setItem('subscription', JSON.stringify(state.items[index]));
                }

            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // CREATE Subscription
            .addCase(subscriptionAdded.pending, (state, action) => {
                state.creating.status = 'inprogress';
            })
            .addCase(subscriptionAdded.fulfilled, (state, action) => {
                state.creating.status = 'succeeded';
                state.creating.error = null;
                // Add any fetched Subscriptions to the array
                state.items.push(action.payload);
            })
            .addCase(subscriptionAdded.rejected, (state, action) => {
                state.creating.status = 'failed';
                state.creating.error = action.error.message;
            })
            // UPDATE Subscription
            .addCase(subscriptionUpdated.pending, (state, action) => {
                state.editing.status = 'pending';
            })
            .addCase(subscriptionUpdated.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items[index] = action.payload;
                state.editing.status = 'success';

                // state.items.push(action.payload)
            })
            .addCase(subscriptionUpdated.rejected, (state, action) => {
                state.editing.status = 'failed';
                state.editing.error = action.error.message;
            })
            // MARK AS SEEN
            .addCase(subscriptionUnsubscribed.pending, (state, action) => {
                state.editing.status = 'pending';
            })
            .addCase(subscriptionUnsubscribed.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items[index] = action.payload;
                state.editing.status = 'success';

                // state.items.push(action.payload)
            })
            .addCase(subscriptionUnsubscribed.rejected, (state, action) => {
                state.editing.status = 'failed';
                state.editing.error = action.error.message;
            })

        // DELETE subscription
        .addCase(subscriptionDeleted.pending, (state, action) => {
                state.deleting.status = 'pending';
            })
            .addCase(subscriptionDeleted.fulfilled, (state, action) => {
                const index = state.items.findIndex(function(o) {
                    return o.id == action.payload.id;
                })

                state.items.splice(index, 1);
                state.deleting.status = 'success';

            })
            .addCase(subscriptionDeleted.rejected, (state, action) => {
                state.deleting.status = 'failed';
                state.deleting.error = action.error.message;
            })

    }

})

// Action creators are generated for each case reducer function
export const { reset } = subscriptionsSlice.actions;

export const selectAllSubscriptions = state => state.subscriptions.items;
export const selectSubscriptionsCount = state => state.subscriptions.items.length;

export const selectHomeSubscriptionsCount = state => state.subscriptions.items.filter(subscription => subscription.type == "Home").length;
export const selectStockSubscriptionsCount = state => state.subscriptions.items.filter(subscription => subscription.type == "Stock").length;
export const selectContactSubscriptionsCount = state => state.subscriptions.items.filter(subscription => subscription.type == "Contact").length;

export default subscriptionsSlice.reducer;