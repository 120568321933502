import React from 'react';

const usePageVisibility = () => {
  const [pageVisibile, setPageVisible] = React.useState(true);
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageVisible(false);
        console.log('PAGE HIDDEN');
      } else {
        setPageVisible(true);
        console.log('PAGE RESTORED');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  },[]);

  return pageVisibile;

};

export default usePageVisibility;