import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/users/usersSlice';
import { checkRole } from 'utils/checkRole';

const HasPermission = (props) => {

    const { children, role } = props;

    const user = useSelector(selectUser);

    const isAuthorized = checkRole(user,role);

    return isAuthorized ? children : null;

}

export default HasPermission;