import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNextStockinRef } from 'features/stockins/stockinsSlice';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete
} from '@mui/material';

import { makeOptions, condition, color, payment_method } from './data'
import { isEmpty } from 'underscore';

const StockinForm = ({editMode, model, onAction }) => {

    const nextRef = useSelector(selectNextStockinRef);
    console.log("Stockin Form Render");
    const [formValues, setFormValues] = useState(model);
    const [formErrors, setFormErrors] = useState({
      make: "",
      model: "",
      reference: nextRef,
      width: "",
      length: "",
      no_of_beds:"",
      price:"",
      deposit: "",
      diesel_total: "",
      repairs_total: "",
      seller_name: ""

    });

    React.useEffect(() => {
      setFormValues({...formValues, reference: nextRef});
    },[nextRef]);
    
    const handleChange = (event, newValue) => {

        const numberFields = ['length','width','no_of_beds', 'price','deposit', 'diesel_total','repairs_total','year']

        let value = event.target.name === "collected" ? event.target.checked : event.target.value;
        if(numberFields.includes(event.target.name)) {
          value = parseFloat(event.target.value);

          // if field goes to empty, make empty string to avoid uncontrolled input
          if(!value && value != 0 ) value=""; 

        }
        

        setFormValues({
          ...formValues,
          [event.target.name]: value
        });
    };

    const validateForm = (values) => {

      const emptyMsg = "Can't be empty"
      let errors = {};
      if(!values.make) errors.make = emptyMsg;
      if(!values.model) errors.model = emptyMsg;
      if(!values.no_of_beds) errors.no_of_beds = emptyMsg;
      if(!values.price) errors.price = emptyMsg;
      if(!values.reference) errors.reference = emptyMsg;
      if(!values.length) errors.length= emptyMsg;
      if(!values.width) errors.width = emptyMsg;
      if(!values.deposit && values.deposit != 0) errors.deposit = emptyMsg;
      if(!values.diesel_total) errors.diesel_total = emptyMsg;
      if(!values.repairs_total) errors.repairs_total = emptyMsg;
      if(!values.seller_name) errors.seller_name = emptyMsg;
      return errors;
    }

    const onFormSubmit = (event) => {

      const validate = validateForm(formValues);

      if(isEmpty(validate)) onAction(formValues);
      else setFormErrors({ ...validate });

    }

    return (
        <>
        <form
        autoComplete="off"

      >
        <Card>
        
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                <Autocomplete
                  disablePortal
                  freeSolo
                  name="make"
                  label="Make"
                  id="combo-box-demo"
                  options={makeOptions}
                  value={formValues.make}
                  //inputValue={formValues.make}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Make" helperText={formErrors.make}/>}
                  onChange={(event, value) => setFormValues({...formValues, make: value})}
                  onInputChange={(event, value) => setFormValues({...formValues, make: value})}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Model of the caravan"
                  name="model"
                  onChange={handleChange}
                  required
                  value={formValues.model}
                  variant="outlined"
                  helperText={formErrors.model}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Length"
                  name="length"
                  onChange={handleChange}
                  required
                  value={formValues.length}
                  variant="outlined"
                  helperText={formErrors.length}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Width"
                  name="width"
                  onChange={handleChange}
                  required
                  value={formValues.width}
                  variant="outlined"
                  helperText={formErrors.width}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Beds"
                  name="no_of_beds"
                  onChange={handleChange}
                  required
                  value={formValues.no_of_beds}
                  variant="outlined"
                  helperText={formErrors.no_of_beds}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Year"
                  name="year"
                  onChange={handleChange}
                  required
                  value={formValues.year}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
                <TextField
                  fullWidth
                  label="Ref."
                  name="reference"
                  onChange={handleChange}
                  required
                  value={formValues.reference}
                  variant="outlined"
                  helperText={formErrors.reference}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
              >
               <TextField
                  fullWidth
                  label="Color"
                  name="color"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.color}
                  variant="outlined"
                >
                  {color.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Cost"
                  name="price"
                  onChange={handleChange}
                  required
                  value={formValues.price}
                  variant="outlined"
                  helperText={formErrors.price}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Deposit"
                  name="deposit"
                  type="number"
                  onChange={handleChange}
                  required
                  value={formValues.deposit}
                  variant="outlined"
                  helperText={formErrors.deposit}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Diesel Cost"
                  name="diesel_total"
                  onChange={handleChange}
                  required
                  value={formValues.diesel_total}
                  variant="outlined"
                  helperText={formErrors.diesel_total}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Repair cost"
                  name="repairs_total"
                  onChange={handleChange}
                  required
                  value={formValues.repairs_total}
                  variant="outlined"
                  helperText={formErrors.repairs_total}
                />
              </Grid>

              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                    fullWidth
                    label="Deposit Date"
                    type="date"
                    name="deposit_paid_on"
                    InputLabelProps={{ shrink: true }}
                    value={formValues.deposit_paid_on}
                    onChange={handleChange}
                    variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                    fullWidth
                    label="Balance Date"
                    type="date"
                    name="balance_paid_on"
                    InputLabelProps={{ shrink: true }}
                    value={formValues.balance_paid_on}
                    onChange={handleChange}
                    variant="outlined"
                />
              
              </Grid>
             
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Deposit type"
                  name="deposit_payment_method"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.deposit_payment_method}
                  variant="outlined"
                >
                  {payment_method.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Balance Type"
                  name="balance_payment_method"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.balance_payment_method}
                  variant="outlined"
                >
                  {payment_method.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Seller Name"
                  name="seller_name"
                  onChange={handleChange}
                  required
                  value={formValues.seller_name}
                  variant="outlined"
                  helperText={formErrors.seller_name}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="telephone"
                  onChange={handleChange}
                  type="Number"
                  value={formValues.telephone}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  onChange={handleChange}
                  required
                  value={formValues.address}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Town"
                  name="town"
                  onChange={handleChange}
                  required
                  value={formValues.town}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Post Code"
                  name="postcode"
                  onChange={handleChange}
                  required
                  value={formValues.postcode}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Condition"
                  name="condition"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formValues.condition}
                  variant="outlined"
                >
                  {condition.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

                <Grid
                  item
                  xs={12}
                >

                <FormControlLabel

                  control={<Switch checked={formValues.collected} onChange={handleChange} name="collected" />}
                  label="Collected"
                  />
                </Grid>
                
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent:'flex-end',
              flexDirection: "row",
              columnGap:'10px',
              p: 2
            }}
          >

            <Button
  
              color="primary"
              variant="contained"
              onClick={onFormSubmit}
            >
              Save
            </Button>
            <Button
  
              color="primary"
              variant="contained"
 
            >
              Cancel
            </Button>

          </Box>
        </Card>
      </form>
    </>
    );

}

export default StockinForm;